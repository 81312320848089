// Table Session Record Profile
export const SESSION_ALL_PAGE_RECORD_PROFILE = [
     { value: 'originPathName', label: 'Path' },
     { value: 'pageViewCount', label: 'Views' },
     { value: 'timeOnPage', label: 'Time on Page' },
     { value: 'conversions', label: 'Conversions' },
];

export const SESSION_CONVERSION_RECORD_PROFILE = [
     { value: 'name', label: 'Name' },
     { value: 'conversionEvents', label: 'Conversion Events' },
];

//Table User Record Profile
export const USER_CONVERSION_RECORD_PROFILE = [
     { value: 'name', label: 'Name' },
     { value: 'conversionEvents', label: 'Conversion Events' },
     { value: 'sessions', label: 'Sessions' },
];

export const USER_SOURCE_RECORD_PROFILE = [
     { value: 'name', label: 'Name' },
     { value: 'sessions', label: 'Sessions' },
     { value: 'conversionEvents', label: 'Conversion Events' },
];

export const USER_ALL_PAGE_RECORD_PROFILE = [
     { value: 'path', label: 'Path' },
     { value: 'pageViewCount', label: 'Views' },
     { value: 'sessions', label: 'Sessions' },
];

// Table People Record Profile
export const PEOPLE_CONVERSION_RECORD_PROFILE = [
     { value: 'name', label: 'Name' },
     { value: 'conversionEvents', label: 'Conversion Events' },
     { value: 'sessions', label: 'Sessions' },
     { value: 'users', label: 'Users' },
];

export const PEOPLE_SOURCE_RECORD_PROFILE = [
     { value: 'name', label: 'Name' },
     { value: 'sessions', label: 'Sessions' },
     { value: 'users', label: 'Users' },
     { value: 'conversionEvents', label: 'Conversion Events' },
];

export const PEOPLE_ALL_PAGE_RECORD_PROFILE = [
     { value: 'path', label: 'Path' },
     { value: 'pageViewCount', label: 'Views' },
     { value: 'sessions', label: 'Sessions' },
     { value: 'users', label: 'Users' },
];

// Table Company Conversion
export const COMPANY_CONVERSION_RECORD_PROFILE = [
     { value: 'name', label: 'Name' },
     { value: 'conversionEvents', label: 'Conversion Events' },
     { value: 'sessions', label: 'Sessions' },
     { value: 'users', label: 'Users' },
];

export const COMPANY_SOURCE_RECORD_PROFILE = [
     { value: 'name', label: 'Name' },
     { value: 'sessions', label: 'Sessions' },
     { value: 'users', label: 'Users' },
     { value: 'conversionEvents', label: 'Conversion Events' },
];

export const COMPANY_ALL_PAGE_RECORD_PROFILE = [
     { value: 'path', label: 'Path' },
     { value: 'pageViewCount', label: 'Views' },
     { value: 'sessions', label: 'Sessions' },
     { value: 'users', label: 'Users' },
];

export const COMPANY_RECORD_PROFILE = [
     { value: 'companyName', label: 'Name' },
     { value: 'domain', label: 'Domain' },
     { value: 'isTargetAccount', label: 'is Target Account' },
     { value: 'companyId', label: 'View' },
];

//Table Form Category
export const FORM_CATEGORY_SOURCE_RECORD_PROFILE = [
     { value: 'source', label: 'Name' },
     { value: 'submissions', label: 'Submission' },
     { value: 'impressionsLoad', label: 'Impression (Loads)' },
     { value: 'validationErrors', label: 'Validation Errors' },
     { value: 'formVisible', label: 'Form Visible' },
     { value: 'formStart', label: 'Form Start' },
];

export const FORM_CATEGORY_REVEAL_PEOPLE_RECORD_PROFILE = [
     { value: 'email', label: 'Email ( or Phone)', minWidth: 500 },
     { value: 'submissions', label: 'Submission' },
     { value: 'impressionsLoad', label: 'Impression (Loads)' },
     { value: 'validationErrors', label: 'Validation Errors' },
     { value: 'formVisible', label: 'Form Visible' },
     { value: 'formStart', label: 'Form Start' },
];

export const FORM_CATEGORY_FORM_LOCATION_RECORD_PROFILE = [
     { value: 'formLocation', label: 'Path', minWidth: 500 },
     { value: 'impressionsLoad', label: 'Impression (Loads)', sort: 'desc' },
     { value: 'submissions', label: 'Submission' },
     { value: 'validationErrors', label: 'Validation Errors' },
     { value: 'formVisible', label: 'Form Visible' },
     { value: 'formStart', label: 'Form Start' },
];

export const FORM_CATEGORY_RECORD_FORM_STEP = [
     { value: 'orderNumber', label: 'Field Order', minWidth: 112 },
     { value: 'fieldName', label: 'Field Name', minWidth: 650 },
     { value: 'countStart', label: 'Starts', minWidth: 81 },
     { value: 'countCompleted', label: 'Completes', minWidth: 113 },
     { value: 'fallouts', label: 'Fallouts', minWidth: 94 },
     { value: 'falloutRate', label: 'FalloutRate', minWidth: 118 },
];
