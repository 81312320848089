import CIcon from '@coreui/icons-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callTokenApi } from '../../../../../apiCaller';
import countries from '../../../../../assets/countries.json';
import {
     ACCORDION_TYPES,
     API_RECORD_PROFILE_COMPANY,
     DATE_SCOPE,
     DEFAULT_DATE_FORMAT,
     REPORT_NAMES,
     SUBSCRIBER_PATH,
     VARIABLE_OPERATORS_DIMENSION_REPORT,
} from '../../../../../constants';
import { convertReportDuration, encodeObj, formatDate, toastError } from '../../../../../utils';
import BaseTags from '../../../../general/BaseTags';
import Accordion from '../../../../general/accordion';
import EventExplorer from '../EventExplorer';
import TableRecordProfile from './TableRecordProfile';
import { COMPANY_ALL_PAGE_RECORD_PROFILE, COMPANY_CONVERSION_RECORD_PROFILE, COMPANY_SOURCE_RECORD_PROFILE } from './utilsCompany';
import { UserSourceDetails, UserSourcePathDetails } from './TootleDetail';
import { formatNumber } from '../../../../../utils/UtilReport';

const SocialNetworks = ({ socialNetworks }) => {
     const { linkedin, linkedinSalesNavigator, facebook, instagram, twitter, youtube, pinterest } = socialNetworks;

     const getIcon = (iconName, url) => {
          return (
               <a href={url} className="d-flex base-social-icon" target="_blank" rel="noopener noreferrer">
                    <span className="base-icon">
                         <span className="d-flex align-items-center justify-content-center h-100 w-100">
                              <CIcon className={`d-flex ${iconName}`} icon={iconName} />
                         </span>
                    </span>
               </a>
          );
     };

     return (
          <div className="the-social-bar d-flex align-items-center ml-2">
               {linkedin && getIcon('icon-linkedin', linkedin)}
               {linkedinSalesNavigator && getIcon('icon-linkedin-sales-navigator', linkedinSalesNavigator)}
               {facebook && getIcon('icon-facebook', facebook)}
               {instagram && getIcon('icon-instagram', instagram)}
               {twitter && getIcon('icon-twitter', twitter)}
               {youtube && getIcon('icon-youtube', youtube)}
               {pinterest && getIcon('icon-pinterest', pinterest)}
          </div>
     );
};

export const RowDetails = ({ icon, label, value, countryCode, isScrollbar, isIndented, children }) => {
     return (
          <div className="row-details">
               <div className="row-label d-flex align-items-center">
                    {icon && <CIcon icon={icon} />}
                    <span className={isIndented ? 'indented' : ''}>{label}</span>
               </div>
               {children
                    ? children
                    : value && (
                           <div className="row-value d-flex align-items-center">
                                <span className={`${isScrollbar ? 'scrollbar' : ''}`}>{value}</span>
                                {countryCode && (
                                     <img
                                          alt={countryCode.toUpperCase()}
                                          src={`https://www.thecompaniesapi.com/images/bases/BaseFlag/${countryCode.toUpperCase()}.svg`}
                                          className="ml-2 gb-flag"
                                     />
                                )}
                           </div>
                      )}
          </div>
     );
};

const RecordProfileCompany = ({ id, linkedData }) => {
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const scoped = 'Company';
     const newDimensions = [{ key: 'companyId', label: 'Revealed Company ID' }];
     const filterUser = [
          {
               type: 'companyId',
               value: `${id}`,
               operator: 'eq',
               optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
               scoped: 'User',
               reportName: 'userDetails',
               clickable: true,
          },
     ];
     const filterPeople = [
          {
               type: 'companyId',
               value: `${id}`,
               operator: 'eq',
               optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
               scoped: 'People',
               reportName: 'personDetails',
               clickable: true,
          },
     ];
     const filterSession = [
          {
               type: 'companyId',
               value: `${id}`,
               operator: 'eq',
               optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
               scoped: 'Session',
               reportName: 'sessionDetails',
               clickable: true,
          },
     ];
     const keyMap = 'AIzaSyAkPIwou6sSj13cfF35p8bX2BBgfUJaaQk';

     const [isLoading, setIsLoading] = useState(true);
     const [initialValue, setInitialValue] = useState(null);
     const [accordionShow, setAccordionShow] = useState(ACCORDION_TYPES.COMPANY_DESCRIPTION);
     const [accordionChildShow, setAccordionChildShow] = useState(null);
     const [isFecthTableEvent, setIsFecthTableEvent] = useState(false);
     const [accordionFirstLoad, setAccordionFirstLoad] = useState([]);
     
     useEffect(() => {
          if (
               accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION ||
               accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER ||
               accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_PEOPLE
          ) {
               setIsFecthTableEvent(true);
          }
     }, [accordionShow]);

     const fetchCompanyDetails = () => {
          const data = {
               id,
               accountId: activeAccount.id,
               // startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
               // endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
          };
          callTokenApi(API_RECORD_PROFILE_COMPANY, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         setInitialValue(response.data.dataCompany);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };
     useEffect(fetchCompanyDetails, [id]); // eslint-disable-line react-hooks/exhaustive-deps

     const companyInfo = initialValue && initialValue.newCompanyInfo;
     const socialNetworks = companyInfo && companyInfo.socialNetworks;
     const showSocialBox = socialNetworks && Object.values(socialNetworks).filter((value) => value).length > 0;
     const countryJson = companyInfo && countries && countries.find((item) => item.name === companyInfo.country);

     const handleLinkToOtherReport = (fieldKey, linkedData) => {
          let path = '';
          let linkedReportData;
          let filters = [];
          const reportName = REPORT_NAMES.COMPANY_DETAILS;

          switch (fieldKey) {
               case 'sessionCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_SESSIONS;
                    if (reportName === REPORT_NAMES.COMPANY_DETAILS) {
                         filters.push({
                              type: 'companyId',
                              value: `${id}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                         break;
                    }

                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: `${id}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;

               case 'userCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_USERS;
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: `${id}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;

               case 'peopleCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_PEOPLE;
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: `${id}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;

               default:
          }

          linkedReportData = {
               filters,
               dateRange: {
                    startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
                    endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
               },
               dateScope: DATE_SCOPE.EVENT_ACTIVITY,
               segment: []
          };

          path = path.replace(':secondId', activeAccount.secondId) + '?data=' + encodeObj(linkedReportData);
          window.open(path, '_blank');
     };

     const handleSetAccordion = (value) => {
          setAccordionShow(value)
          const data = [...accordionFirstLoad]
          if (!data.includes(value)) {
               data.push(value)
          }
          setAccordionFirstLoad(data)
     };

     return (
          <div className="record-profile">
               {isLoading ? (
                    <div className="record-profile loading-profile">
                         <h1>Revealed Company Profile</h1>
                         <div className="record-profile-info">
                              <div className="d-flex align-items-center justify-content-between mb-4">
                                   <div className="left-box d-flex">
                                        <div className="company-name-wrapper d-flex flex-column event-profile-session">
                                             <h4>Company name</h4>
                                             <span>Domain Social Networks Loading...</span>
                                        </div>
                                   </div>
                                   <div className="right-box d-flex flex-column">
                                        <p className="event-profile-session">Revealed Company ID: Revealed Company ID</p>
                                        <div className="d-flex justify-content-between">
                                             <p className="event-profile-session">Identified Date: Identified Date</p>
                                             <p className="event-profile-session">Latest Session: Latest Session</p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="list-count-data">
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Sessions</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Engaged Sessions</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Users</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime People</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Conversions</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Page Views</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Session Duration</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Avg Session Duration</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Engagement Score</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>is Target Account</h4>
                                   <p className="item-name"></p>
                              </div>
                         </div>
                         <div className="page">
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Source (first):</p>
                                   <p className="description"></p>
                              </div>
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Source (last):</p>
                                   <p className="description"></p>
                              </div>
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Source (path):</p>
                                   <p className="description source-path"></p>
                              </div>
                         </div>
                         <div className="accordion accordion-profile">
                              {/* Company Description */}
                              <Accordion
                                   accordionType={ACCORDION_TYPES.COMPANY_DESCRIPTION}
                                   title="Company Description"
                                   classNameBody="event-profile-session"
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              >
                                   <RowDetails />
                                   <RowDetails />
                              </Accordion>

                              {/* Company Size */}
                              <Accordion
                                   accordionType={ACCORDION_TYPES.COMPANY_SIZE}
                                   title="Company Size"
                                   classNameBody="event-profile-session"
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              >
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                              </Accordion>

                              {/* Company Industry Details */}
                              <Accordion
                                   accordionType={ACCORDION_TYPES.COMPANY_INDUSTRY_DETAILS}
                                   title="Industry Details"
                                   classNameBody="event-profile-session"
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              >
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                              </Accordion>

                              {/* Company Location */}
                              <Accordion
                                   accordionType={ACCORDION_TYPES.COMPANY_LOCATION}
                                   title="Company Location"
                                   classNameBody="event-profile-session"
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              >
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                              </Accordion>

                              {/* Company Other Data */}
                              <Accordion
                                   accordionType={ACCORDION_TYPES.COMPANY_OTHER_DATA}
                                   title="Other Data"
                                   classNameBody="event-profile-session"
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              >
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                              </Accordion>
                         </div>
                    </div>
               ) : (
                    <div className="record-profile company-profile">
                         <h1>Revealed Company Profile</h1>
                         {companyInfo && (
                              <>
                                   <div className="record-profile-info">
                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                             <div className="left-box d-flex">
                                                  {companyInfo.logo && (
                                                       <span className="base-avatar-wrapper mr-3">
                                                            <div className="base-avatar d-flex align-items-center justify-content-center">
                                                                 <img alt="Base avatar" src={companyInfo.logo} loading="lazy" className="h-100 w-100" />
                                                            </div>
                                                       </span>
                                                  )}
                                                  <div className="company-name-wrapper d-flex flex-column">
                                                       <h4>{companyInfo.companyName}</h4>
                                                       <div className="d-flex align-items-center">
                                                            <a href={`http://${companyInfo.domain}`} target="_blank" rel="noopener noreferrer">
                                                                 <span>{companyInfo.domain}</span>
                                                            </a>
                                                            {showSocialBox && <SocialNetworks socialNetworks={socialNetworks} />}
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="right-box d-flex flex-column">
                                                  <p>Revealed Company ID: {companyInfo.companyId}</p>
                                                  <div className="d-flex justify-content-between">
                                                       <p>Identified Date: {companyInfo.identifiedDate}</p>
                                                       <p>Latest Session: {companyInfo.latestSession}</p>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="list-count-data">
                                        <div className="card box-count-data">
                                             <h4>Lifetime Sessions</h4>
                                             {companyInfo.countSession > 0 ? (
                                                  <p
                                                       className="item-name link-data"
                                                       onClick={() => handleLinkToOtherReport('sessionCount', linkedData)}
                                                  >
                                                       {formatNumber(companyInfo.countSession)}
                                                  </p>
                                             ) : (
                                                  <p className="item-name">{companyInfo.countSession}</p>
                                             )}
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Engaged Sessions</h4>
                                             <p className="item-name">{formatNumber(companyInfo.countEngaged)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Users</h4>
                                             {companyInfo.countUser > 0 ? (
                                                  <p className="item-name link-data" onClick={() => handleLinkToOtherReport('userCount', linkedData)}>
                                                       {formatNumber(companyInfo.countUser)}
                                                  </p>
                                             ) : (
                                                  <p className="item-name">{companyInfo.countUser}</p>
                                             )}
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime People</h4>
                                             {companyInfo.countPeople > 0 ? (
                                                  <p
                                                       className="item-name link-data"
                                                       onClick={() => handleLinkToOtherReport('peopleCount', linkedData)}
                                                  >
                                                       {formatNumber(companyInfo.countPeople)}
                                                  </p>
                                             ) : (
                                                  <p className="item-name">{companyInfo.countPeople}</p>
                                             )}
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Conversions</h4>
                                             <p className="item-name">{formatNumber(companyInfo.countConversions)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Page Views</h4>
                                             <p className="item-name">{formatNumber(companyInfo.countPageView)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Session Duration</h4>
                                             <p className="item-name">{convertReportDuration(companyInfo.sessionDuration)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Avg Session Duration</h4>
                                             <p className="item-name">{convertReportDuration(companyInfo.avgSessionDuration)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Engagement Score</h4>
                                             <p className="item-name">{formatNumber(Number(companyInfo.engagementScore))}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>is Target Account</h4>
                                             <p className="item-name">{companyInfo.isTargetAccount}</p>
                                        </div>
                                   </div>
                                   <div className="page">
                                        <div className="page-path-name">
                                             <p className="title">Source (first):</p>
                                             <div className="description">
                                                  <UserSourceDetails data={companyInfo.sourceFirst} />
                                             </div>
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Source (last):</p>
                                             <div className="description">
                                                  <UserSourceDetails data={companyInfo.sourceLast} />
                                             </div>
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Source (path):</p>
                                             <UserSourcePathDetails data={companyInfo.sourcePath} />
                                        </div>
                                   </div>
                                   <div className="accordion accordion-profile">
                                        {/* Company Description */}
                                        <Accordion
                                             accordionType={ACCORDION_TYPES.COMPANY_DESCRIPTION}
                                             title="Company Description"
                                             accordionShow={accordionShow}
                                             setAccordionShow={setAccordionShow}
                                        >
                                             <RowDetails label="Short Description" value={companyInfo.descriptionShort} />
                                             <RowDetails label="Description" value={companyInfo.description} />
                                        </Accordion>

                                        {/* Company Size */}
                                        <Accordion
                                             accordionType={ACCORDION_TYPES.COMPANY_SIZE}
                                             title="Company Size"
                                             accordionShow={accordionShow}
                                             setAccordionShow={setAccordionShow}
                                        >
                                             <RowDetails label="Monthly Visitors" value={companyInfo.monthlyVisitors} icon="icon-analytics-v2" />
                                             <RowDetails label="Revenue" value={companyInfo.revenue} icon="icon-sack-dollar" />
                                             <RowDetails label="Stock Exchange" value={companyInfo.stockExchange} icon="icon-landmark" />
                                             <RowDetails label="Stock Symbol" value={companyInfo.stockSymbol} icon="icon-landmark-alt" />
                                             <RowDetails label="Phone Number" value={companyInfo.phone} icon="icon-phone" />
                                             <RowDetails label="Total Employees" value={`${companyInfo.employees}`} icon="icon-users" />
                                             <RowDetails label="Year Founded" value={companyInfo.yearFounded} icon="icon-glass-cheers" />
                                             <RowDetails label="Alexa Rank" value={companyInfo.alexaRank} icon="icon-alexa" />
                                        </Accordion>

                                        {/* Company Industry Details */}
                                        <Accordion
                                             accordionType={ACCORDION_TYPES.COMPANY_INDUSTRY_DETAILS}
                                             title="Industry Details"
                                             accordionShow={accordionShow}
                                             setAccordionShow={setAccordionShow}
                                        >
                                             <RowDetails label="Organization Type" value={companyInfo.businessType} />
                                             <RowDetails label="Primary Industry" value={companyInfo.primaryIndustry} />
                                             <RowDetails label="Products & Services Tags">
                                                  <BaseTags items={companyInfo.industries} />
                                             </RowDetails>

                                             <Accordion
                                                  accordionType={ACCORDION_TYPES.COMPANY_NAICS_DETAILS}
                                                  title="NAICS Details"
                                                  className="mt-3 mb-0"
                                                  accordionShow={accordionChildShow}
                                                  setAccordionShow={setAccordionChildShow}
                                             >
                                                  <RowDetails label="NAICS Sector" value={companyInfo.naicsSector} />
                                                  <RowDetails label="NAICS Subsector" value={companyInfo.naicsSubsector} />
                                                  <RowDetails label="NAICS Industry Group" value={companyInfo.naicsGroup} />
                                                  <RowDetails label="NAICS Industry Description" value={companyInfo.naicsDesc} />
                                                  <RowDetails label="NAICS Code" value={companyInfo.naicsCode} />
                                             </Accordion>
                                        </Accordion>

                                        {/* Company Location */}
                                        <Accordion
                                             accordionType={ACCORDION_TYPES.COMPANY_LOCATION}
                                             title="Company Location"
                                             accordionShow={accordionShow}
                                             setAccordionShow={setAccordionShow}
                                        >
                                             <RowDetails label="Address" value={companyInfo.address} icon="icon-location" />
                                             <RowDetails label="City" value={companyInfo.city} icon="icon-hotel" />
                                             <RowDetails label="County" value={companyInfo.county && companyInfo.county.name} icon="icon-map-signs" />
                                             <RowDetails
                                                  label="Country"
                                                  value={companyInfo.country}
                                                  icon="icon-globe-stand"
                                                  countryCode={
                                                       (companyInfo.countryData && companyInfo.countryData.code) ||
                                                       companyInfo.countryShort ||
                                                       (countryJson && countryJson.country_code)
                                                  }
                                             />
                                             <RowDetails
                                                  label="Country Short"
                                                  value={companyInfo.countryShort || (countryJson && countryJson.country_code)}
                                                  icon="icon-globe-stand"
                                             />
                                             <RowDetails
                                                  label="State"
                                                  value={companyInfo.state && companyInfo.state.name}
                                                  icon="icon-map-marked-alt"
                                             />
                                             <RowDetails
                                                  label="Continent"
                                                  value={companyInfo.continent && companyInfo.continent.name}
                                                  icon="icon-globe"
                                             />
                                             <RowDetails label="Region" value={companyInfo.region} icon="icon-region" />
                                             <RowDetails label="Region Short" value={companyInfo.regionShort} icon="icon-region" />
                                             <RowDetails label="Postal" value={companyInfo.postal} icon="icon-postal" />
                                             <RowDetails label="Latitude" value={companyInfo.latitude} icon="icon-latitude" />
                                             <RowDetails label="Longitude" value={companyInfo.longitude} icon="icon-longitude" />
                                             <RowDetails label="TimeZone Id" value={companyInfo.timeZoneId} icon="icon-timezone" />
                                             <RowDetails label="TimeZone Name" value={companyInfo.timeZoneName} icon="icon-timezone" />
                                             <RowDetails label="Utc Offset" value={companyInfo.utcOffset} icon="icon-timezone-utc" />
                                             <RowDetails label="Dst Offset" value={companyInfo.dstOffset} icon="icon-dst" />
                                             {companyInfo.latitude && companyInfo.longitude && (
                                                  <div className="small-google-map mt-4">
                                                       <iframe
                                                            title="small google map"
                                                            src={`https://www.google.com/maps/embed/v1/place?key=${keyMap}&q=${Number(
                                                                 companyInfo.latitude
                                                            )},${Number(companyInfo.longitude)}&language=en&region=US`}
                                                            height="450px"
                                                            width="100%"
                                                       ></iframe>
                                                  </div>
                                             )}
                                        </Accordion>

                                        {/* Company Other Data */}
                                        <Accordion
                                             accordionType={ACCORDION_TYPES.COMPANY_OTHER_DATA}
                                             title="Other Data"
                                             accordionShow={accordionShow}
                                             setAccordionShow={setAccordionShow}
                                        >
                                             <RowDetails label="Confidence" value={companyInfo.confidence} />
                                             <RowDetails label="Trade Name" value={companyInfo.tradeName} />
                                             <RowDetails label="Is ISP" value={companyInfo.isISP} />
                                             <RowDetails label="Is Wifi" value={companyInfo.isWifi} />
                                             <RowDetails label="Is Mobile" value={companyInfo.isMobile} />
                                             <RowDetails label="CreatedAt" value={formatDate(companyInfo.createdAt, DEFAULT_DATE_FORMAT)} />
                                             <RowDetails label="UpdatedAt" value={companyInfo.updatedAt} />
                                        </Accordion>
                                   </div>
                              </>
                         )}
                    </div>
               )}
               <div className="accordion">
                    {isLoading ? (
                         <>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_ALL_PAGE}
                                   title={`All Pages (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SOURCE}
                                   title={`Sources (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_CONVERSION}
                                   title={`Conversions (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_PEOPLE}
                                   title={`People Details (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER}
                                   title={`User Details (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION}
                                   title={`Session Details (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                         </>
                    ) : (
                         <>
                              <TableRecordProfile
                                   id={id}
                                   header={COMPANY_ALL_PAGE_RECORD_PROFILE}
                                   reportType={'companyAllPage'}
                                   tableType={'table-all-page'}
                                   title={'All Pages'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_ALL_PAGE}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <TableRecordProfile
                                   id={id}
                                   header={COMPANY_SOURCE_RECORD_PROFILE}
                                   reportType={'companySource'}
                                   tableType={'table-source'}
                                   title={'Sources'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SOURCE}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <TableRecordProfile
                                   id={id}
                                   header={COMPANY_CONVERSION_RECORD_PROFILE}
                                   reportType={'companyConversion'}
                                   tableType={'table-conversion'}
                                   title={'Conversions'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_CONVERSION}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              {companyInfo && companyInfo.countPeople > 0 && (
                                   <Accordion
                                        accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_PEOPLE}
                                        title={`People Details (${companyInfo.countPeople})`}
                                        accordionShow={accordionShow}
                                        setAccordionShow={handleSetAccordion}
                                   >
                                        {(accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_PEOPLE || accordionFirstLoad.includes(ACCORDION_TYPES.RECORD_PROFILE_TABLE_PEOPLE)) && (
                                             <EventExplorer
                                             title={''}
                                             reportName={REPORT_NAMES.PERSON_DETAILS_PROFILE}
                                             recordProfile={true}
                                             filterRecord={filterPeople}
                                             isFecthTableEvent={isFecthTableEvent}
                                        />
                                        )}
                                   </Accordion>
                              )}
                              {companyInfo && companyInfo.countUser > 0 && (
                                   <Accordion
                                        accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER}
                                        title={`User Details (${companyInfo.countUser})`}
                                        accordionShow={accordionShow}
                                        setAccordionShow={handleSetAccordion}
                                   >
                                        {(accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER || accordionFirstLoad.includes(ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER)) && (
                                             <EventExplorer title={''} reportName={REPORT_NAMES.USER_DETAILS} recordProfile={true} filterRecord={filterUser} isFecthTableEvent={isFecthTableEvent} />
                                        )}
                                   </Accordion>
                              )}
                              {companyInfo && companyInfo.countSession > 0 && (
                                   <Accordion
                                        accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION}
                                        title={`Session Details (${companyInfo.countSession})`}
                                        accordionShow={accordionShow}
                                        setAccordionShow={handleSetAccordion}
                                   >
                                        {(accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION || accordionFirstLoad.includes(ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION)) && (
                                             <EventExplorer title={''} reportName={REPORT_NAMES.SESSION_DETAILS} recordProfile={true} filterRecord={filterSession} isFecthTableEvent={isFecthTableEvent} />
                                        )}
                                   </Accordion>
                              )}
                         </>
                    )}
               </div>
          </div>
     );
};

export default RecordProfileCompany;
