// ** React Imports
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux';

// ** CoreUI Imports
import CIcon from '@coreui/icons-react';

// ** Component Imports
import { EventExplorerContext } from '../../EventExplorer';
import CreateNewView from '../dialog/CreateNewView';

// ** Constants Imports
import { VIEW_TYPE } from '../../../../../../constants/reportView';
import { CLIENT_REPORT_VIEW } from '../../../../../../constants';
import { callTokenApi } from '../../../../../../apiCaller';
import CLabel from '../../../../../migration/CLabel.js';
import { setSavedReport } from '../../../../../../actions/subscriber.js';

const SavedView = () => {
     const dispatch = useDispatch();
     const location = useLocation();
     const user = useSelector((state) => state.theme.user);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const {
          scoped,
          filterEvent,
          filterMetric,
          filterDimensions,
          filterProperties,
          reportName,
          weight,
          showData,
          listViewByScoped,
     } = useContext(EventExplorerContext);


     // ** State
     const [show, setShow] = useState(false);
     const [data, setData] = useState({});
     const type = VIEW_TYPE.REPORT;

     // ** Methods

     const handleCreateNewView = () => {
          if (!showData) {
               return;
          }
          setShow(true);
     };

     const onCancel = () => {
          setShow(false);
          setData({});
     };

     
     const onAccept = async (values, { setSubmitting, resetForm }) => {
          if (!data.type) {
               if (values.name.trim() === '') {
                    toast.warn('Name not empty!');
               } else if (listViewByScoped.find((view) => view.name === values.name.trim())) {
                    toast.error('Name already exits!');
                    setSubmitting(false);
               } else {
                    const content = {
                         filter: filterEvent,
                         metrics: filterMetric.length ? filterMetric.map((_item) => _item.value) : [],
                         dimensions: filterDimensions.length ? filterDimensions.map((_item) => _item.value) : [],
                         properties: filterProperties.length ? filterProperties.map((_item) => _item.value) : [],
                         weight: weight && Object.keys(weight).length > 0 ? weight : undefined,
                    };

                    const payload = {
                         ...values,
                         reportName,
                         scoped,
                         type,
                         content: JSON.stringify(content),
                         accountId: activeAccount.accountId,
                         userId: user.id,
                         shareLink: `${location.pathname}`,
                    };
                    await callTokenApi(CLIENT_REPORT_VIEW, 'POST', payload)
                         .then((response) => {
                              if (response.status === 200) {
                                   dispatch(setSavedReport(response.data?.reportViews));
                                   toast.success(response.data.message);
                              } else {
                                   toast.error(response.data.message);
                              }
                         })
                         .finally(() => {
                              setSubmitting(false);
                              setShow(false);
                              resetForm();
                         });
               }
          }
     };

     return (
          <>
               <div className='d-flex menu-report-list mr-3' onClick={() => handleCreateNewView()}>
                    <CIcon icon="cilSave" width={18} height={18} />
                    <CLabel className="mb-0">{'Saved Report'}</CLabel>
               </div>
               {!data.type && <CreateNewView show={show} type={type} onAccept={onAccept} onCancel={() => onCancel()} />}
          </>
     );
};

export default SavedView;