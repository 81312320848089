import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
     ACCORDION_TYPES,
     API_RECORD_PROFILE_USER,
     COMPONENT_NAME,
     DATE_SCOPE,
     DEFAULT_DATE_FORMAT,
     REPORT_NAMES,
     SUBSCRIBER_PATH,
     VARIABLE_OPERATORS_DIMENSION_REPORT,
} from '../../../../../constants';
import { callTokenApi } from '../../../../../apiCaller';
import { convertReportDuration, encodeObj, toastError } from '../../../../../utils';
import { setFlexibleMultiModal } from '../../../../../actions/common';
import TableRecordProfile from './TableRecordProfile';
import { COMPANY_RECORD_PROFILE, USER_ALL_PAGE_RECORD_PROFILE, USER_CONVERSION_RECORD_PROFILE, USER_SOURCE_RECORD_PROFILE } from './utilsCompany';
import EventExplorer from '../EventExplorer';
import Accordion from '../../../../general/accordion';
import { UserSourceDetails, UserSourcePathDetails } from './TootleDetail';
import { formatNumber } from '../../../../../utils/UtilReport';

const RecordProfileUser = ({ id, linkedData }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     let scoped = 'User';
     let newDimensions = [{ key: 'userId', label: 'User ID' }];
     const filterSession = [
          {
               type: 'userId',
               value: `${id}`,
               operator: 'eq',
               optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
               scoped: 'Session',
               reportName: 'sessionDetails',
               clickable: true,
          },
     ];
     const [initialValue, setInitialValue] = useState(null);
     const [accordionShow, setAccordionShow] = useState(ACCORDION_TYPES.RECORD_PROFILE_TABLE_COMPANY);
     const [isLoading, setIsLoading] = useState(true);
     const [isFecthTableEvent, setIsFecthTableEvent] = useState(false);
     const [accordionFirstLoad, setAccordionFirstLoad] = useState([]);
     const data = { id, accountId: activeAccount.id };

     useEffect(() => {
          if (accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION) {
               setIsFecthTableEvent(true);
          }
     }, [accordionShow])

     // const pageTop = listPageTop && listPageTop.length > 0 && listPageTop.filter((item) => item.views > 0);
     // const showPageVisit = pageTop && pageTop.some((page) => page.views > 0);

     useEffect(() => {
          callTokenApi(API_RECORD_PROFILE_USER, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         setInitialValue(response.data.dataUser);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

     const userInfo = initialValue && initialValue.newUserInfo;

     const handleNavigatePerson = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_PERSON,
               })
          );
     };

     const handleNavigateCompany = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_COMPANY,
               })
          );
     };

     const handleLinkToOtherReport = (fieldKey, linkedData) => {
          let path = '';
          let linkedReportData;
          let filters = [];
          const reportName = REPORT_NAMES.USER_DETAILS;

          switch (fieldKey) {
               case 'sessionCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_SESSIONS;
                    if (reportName === REPORT_NAMES.USER_DETAILS) {
                         filters.push({
                              type: 'userId',
                              value: `${id}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                         break;
                    }

                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: `${linkedData[item.key]}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;

               case 'peopleCount':
                    // newDimensions = [{ key: 'personId', label: 'Person ID' }];
                    path = SUBSCRIBER_PATH.INSIGHTS_PEOPLE;
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: 'userId',
                              value: `${id}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;

               case 'revealedCompany':
                    path = SUBSCRIBER_PATH.INSIGHTS_REVEALED_COMPANIES;
                    // newDimensions = [{ key: 'companyId', label: 'Revealed Company ID' }];
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: 'userId',
                              value: `${id}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;
               default:
          }

          linkedReportData = {
               filters,
               dateRange: {
                    startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
                    endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
               },
               dateScope: DATE_SCOPE.EVENT_ACTIVITY,
               segment: []
          };

          path = path.replace(':secondId', activeAccount.secondId) + '?data=' + encodeObj(linkedReportData);
          window.open(path, '_blank');
     };

     const handleSetAccordion = (value) => {
          setAccordionShow(value)
          const data = [...accordionFirstLoad]
          if (!data.includes(value)) {
               data.push(value)
          }
          setAccordionFirstLoad(data)
     };

     return (
          <div className="record-profile">
               {isLoading ? (
                    <div className="record-profile loading-profile">
                         <h1>User Profile</h1>
                         <div className="record-profile-info">
                              <p className="event-profile-session">User ID: </p>
                              <div className="date">
                                   <p className="event-profile-session">First Session Date: </p>
                                   <p className="event-profile-session">Last Session Date: </p>
                              </div>

                              <p className="event-profile-session">
                                   Person: <span className="link-data"></span>
                              </p>

                              <p className="event-profile-session">
                                   Revealed Companies: <span></span>
                              </p>
                         </div>
                         <div className="list-count-data">
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Sessions</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Engaged Sessions</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Avg Session Duration</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Pageviews</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Conversions</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Person</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Revealed Companies</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Engagement Score</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>is Target Account</h4>
                                   <p className="item-name"></p>
                              </div>
                         </div>
                         <div className="page">
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Source (first):</p>
                                   <p className="description"></p>
                              </div>
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Source (last):</p>
                                   <p className="description"></p>
                              </div>
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Source (path):</p>
                                   <p className="description source-path"></p>
                              </div>
                         </div>
                    </div>
               ) : (
                    <div className="record-profile">
                         <h1>User Profile</h1>
                         {userInfo && (
                              <>
                                   <div className="record-profile-info">
                                        <p>User ID: {userInfo.userId}</p>
                                        <div className="date">
                                             <p>First Session Date: {userInfo.firstSessionDate}</p>
                                             <p>Last Session Date: {userInfo.lastSessionDate}</p>
                                        </div>
                                        {userInfo.personId ? (
                                             <>
                                                  {userInfo.email && userInfo.email !== '' ? (
                                                       <p>
                                                            Person:{' '}
                                                            <span className="link-data" onClick={() => handleNavigatePerson(userInfo.personId)}>
                                                                 {userInfo.email}
                                                            </span>
                                                       </p>
                                                  ) : (
                                                       <p>
                                                            Person:{' '}
                                                            <span className="link-data" onClick={() => handleNavigatePerson(userInfo.personId)}>
                                                                 {userInfo.externalId && userInfo.externalId !== '' ? userInfo.externalId: userInfo.personId}
                                                            </span>
                                                       </p>
                                                  )}
                                             </>
                                        ) : (
                                             <p>Person: </p>
                                        )}
                                        <p>
                                             Revealed Company:{' '}
                                             {userInfo.companyList.map((item, index) => {
                                                  return (
                                                       <span key={index} className="link-data" onClick={() => handleNavigateCompany(item.companyId)}>
                                                            {index < userInfo.companyList.length - 1 ? (
                                                                 <>{item.companyName} ,&nbsp;</>
                                                            ) : (
                                                                 <>{item.companyName}</>
                                                            )}
                                                       </span>
                                                  );
                                             })}
                                        </p>
                                   </div>
                                   <div className="list-count-data">
                                        <div className="card box-count-data">
                                             <h4>Lifetime Sessions</h4>
                                             {userInfo.countSession > 0 ? (
                                                  <p
                                                       className="item-name link-data"
                                                       onClick={() => handleLinkToOtherReport('sessionCount', linkedData)}
                                                  >
                                                       {formatNumber(userInfo.countSession)}
                                                  </p>
                                             ) : (
                                                  <p className="item-name">{userInfo.countSession}</p>
                                             )}
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Engaged Sessions</h4>
                                             <p className="item-name">{formatNumber(userInfo.countEngaged)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Avg Session Duration</h4>
                                             <p className="item-name">{convertReportDuration(userInfo.avgSessionDuration)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Pageviews</h4>
                                             <p className="item-name">{formatNumber(userInfo.countPageView)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Conversions</h4>
                                             <p className="item-name">{formatNumber(userInfo.countConversion)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Person</h4>
                                             {userInfo.countPerson > 0 ? (
                                                  <p
                                                       className="item-name link-data"
                                                       onClick={() => handleLinkToOtherReport('peopleCount', linkedData)}
                                                  >
                                                       {formatNumber(userInfo.countPerson)}
                                                  </p>
                                             ) : (
                                                  <p className="item-name">{userInfo.countPerson}</p>
                                             )}
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Revealed Companies</h4>
                                             {userInfo.companyList.length > 0 ? (
                                                  <p
                                                       className="item-name link-data"
                                                       onClick={() => handleLinkToOtherReport('revealedCompany', linkedData)}
                                                  >
                                                       {userInfo.companyList.length}
                                                  </p>
                                             ) : (
                                                  <p className="item-name">{userInfo.companyList.length}</p>
                                             )}
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Lifetime Engagement Score</h4>
                                             <p className="item-name">{formatNumber(Number(userInfo.engagementScore))}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>is Target Account</h4>
                                             <p className="item-name">{userInfo.isTargetAccount}</p>
                                        </div>
                                   </div>
                                   <div className="page">
                                        <div className="page-path-name">
                                             <p className="title">Source (first):</p>
                                             <div className="description">
                                                  <UserSourceDetails data={userInfo.sourceFirst} />
                                             </div>
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Source (last):</p>
                                             <div className="description">
                                                  <UserSourceDetails data={userInfo.sourceLast} />
                                             </div>
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Source (path):</p>
                                             <UserSourcePathDetails data={userInfo.sourcePath} />
                                        </div>
                                   </div>
                              </>
                         )}
                    </div>
               )}
               <div className="accordion accordion-profile">
                    <TableRecordProfile
                         id={id}
                         header={COMPANY_RECORD_PROFILE}
                         reportType={'userCompany'}
                         tableType={'table-company'}
                         title={'Companies'}
                         accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_COMPANY}
                         accordionShow={accordionShow}
                         setAccordionShow={setAccordionShow}
                    />
                    {isLoading ? (
                         <>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_ALL_PAGE}
                                   title={`All Pages (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SOURCE}
                                   title={`Sources (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_CONVERSION}
                                   title={`Conversions (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION}
                                   title={`Session Details (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                         </>
                    ) : (
                         <>
                              <TableRecordProfile
                                   id={id}
                                   header={USER_ALL_PAGE_RECORD_PROFILE}
                                   reportType={'userAllPage'}
                                   tableType={'table-all-page'}
                                   title={'All Pages'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_ALL_PAGE}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <TableRecordProfile
                                   id={id}
                                   header={USER_SOURCE_RECORD_PROFILE}
                                   reportType={'userSource'}
                                   tableType={'table-source'}
                                   title={'Sources'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SOURCE}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <TableRecordProfile
                                   id={id}
                                   header={USER_CONVERSION_RECORD_PROFILE}
                                   reportType={'userConversion'}
                                   tableType={'table-conversion'}
                                   title={'Conversions'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_CONVERSION}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              {userInfo && userInfo.countSession > 0 && (
                                   <Accordion
                                        accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION}
                                        title={`Session Details (${userInfo.countSession})`}
                                        accordionShow={accordionShow}
                                        setAccordionShow={handleSetAccordion}
                                   >
                                        {(accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION || accordionFirstLoad.includes(ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION)) && (
                                             <EventExplorer title={''} reportName={REPORT_NAMES.SESSION_DETAILS} recordProfile={true} filterRecord={filterSession} isFecthTableEvent={isFecthTableEvent} />
                                        )}
                                   </Accordion>
                              )}
                         </>
                    )}
               </div>
          </div>
     );
};

export default RecordProfileUser;
