import CIcon from '@coreui/icons-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callTokenApi } from '../../../../../apiCaller';
// import countries from '../../../../../assets/countries.json';
import {
     ACCORDION_TYPES,
     API_RECORD_PROFILE_TARGET_ACCOUNT,
     // COMPONENT_NAME,
     DATE_SCOPE,
     DEFAULT_DATE_FORMAT,
     REPORT_NAMES,
     SUBSCRIBER_PATH,
     VARIABLE_OPERATORS_DIMENSION_REPORT,
} from '../../../../../constants';
import { convertReportDuration, encodeObj, formatDate, toastError } from '../../../../../utils';
import BaseTags from '../../../../general/BaseTags';
import Accordion from '../../../../general/accordion';
import EventExplorer from '../EventExplorer';

import { UserSourceDetails, UserSourcePathDetails } from './TootleDetail';
import { formatNumber } from '../../../../../utils/UtilReport';


export const RowDetails = ({ icon, label, value, countryCode, isScrollbar, isIndented, children }) => {
     return (
          <div className="row-details">
               <div className="row-label d-flex align-items-center">
                    {icon && <CIcon name={icon} />}
                    <span className={isIndented ? 'indented' : ''}>{label}</span>
               </div>
               {children
                    ? children
                    : (value && value !== 'null')  && (
                         <div className="row-value d-flex align-items-center">
                              <span className={`${isScrollbar ? 'scrollbar' : ''}`}>{value}</span>
                              {countryCode && (
                                   <img
                                        alt={countryCode.toUpperCase()}
                                        src={`https://www.thecompaniesapi.com/images/bases/BaseFlag/${countryCode.toUpperCase()}.svg`}
                                        className="ml-2 gb-flag"
                                   />
                              )}
                         </div>
                    )}
          </div>
     );
};

const RecordProfileTargetAccount = ({ id, linkedData, targetAccount }) => {
     // const dispatch = useDispatch()
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     // const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const companyId = targetAccount && targetAccount.companyId;
     const companyData = targetAccount && targetAccount.companyData;
     const [personId, setPersonId] = useState(null);
     const [isShowCompany, setIsShowCompany] = useState(false);
     const showCompanyData = companyData && Object.keys(companyData).length > 0
     const scoped = 'Company';
     const newDimensions = [{ key: 'targetAccountId', label: 'Target AccountID' }];

     const filterUser = [
          {
               type: 'targetAccountId',
               value: id,
               operator: 'eq',
               optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
               scoped: 'User',
               reportName: 'userDetails',
               clickable: true,
          },
     ];
     const filterPeople = [
          {
               type: 'targetAccountId',
               value: id,
               operator: 'eq',
               optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
               scoped: 'People',
               reportName: 'personDetails',
               clickable: true,
          },
     ];
     const filterSession = [
          {
               type: 'targetAccountId',
               value: id,
               operator: 'eq',
               optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
               scoped: 'Session',
               reportName: 'sessionDetails',
               clickable: true,
          },
     ];

     const filterCompany = [
          {
               type: 'targetAccountId',
               value: id,
               operator: 'eq',
               optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
               scoped: 'Company',
               reportName: 'companyDetails',
               clickable: true,
          },
     ];
     const keyMap = 'AIzaSyAkPIwou6sSj13cfF35p8bX2BBgfUJaaQk';

     const [isLoading, setIsLoading] = useState(true);
     const [initialValue, setInitialValue] = useState(null);
     const [accordionShow, setAccordionShow] = useState(ACCORDION_TYPES.COMPANY_DESCRIPTION);
     const [accordionChildShow, setAccordionChildShow] = useState(null);
     const [accordionFirstLoad, setAccordionFirstLoad] = useState([]);

     const fetchTargetAccountDetails = () => {
          const data = {
               id,
               accountId: activeAccount.id,
          };
          callTokenApi(API_RECORD_PROFILE_TARGET_ACCOUNT, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         const data = response.data.dataTargetAccount
                         setInitialValue(data);
                         if (data && data.newTargetAccountInfo) {
                              setPersonId(data.newTargetAccountInfo.personId)
                              setIsShowCompany(Boolean(companyId || data.newTargetAccountInfo.companyId))
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };
     useEffect(fetchTargetAccountDetails, [id]); // eslint-disable-line react-hooks/exhaustive-deps

     const targetAccountInfo = initialValue && initialValue.newTargetAccountInfo;
     // const countryJson = targetAccountInfo && countries && countries.find((item) => item.name === targetAccountInfo.country);

     const handleLinkToOtherReport = (fieldKey, linkedData) => {
          let path = '';
          let linkedReportData;
          let filters = [];
          const reportName = REPORT_NAMES.COMPANY_DETAILS;

          switch (fieldKey) {
               case 'sessionCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_SESSIONS;
                    if (reportName === REPORT_NAMES.COMPANY_DETAILS) {
                         filters.push({
                              type: 'targetAccountId',
                              value: id,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                         break;
                    }

                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: id,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;

               case 'userCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_USERS;
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: id,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;

               case 'peopleCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_PEOPLE;
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: id,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;
               case 'revealedCompany':
                    path = SUBSCRIBER_PATH.INSIGHTS_REVEALED_COMPANIES;
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: id,
                              operator: 'eq',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;

               default:
          }

          linkedReportData = {
               filters,
               dateScope: DATE_SCOPE.EVENT_ACTIVITY,
               segment: []
          };

          path = path.replace(':secondId', activeAccount.secondId) + '?data=' + encodeObj(linkedReportData);
          window.open(path, '_blank');
     };

     const handleSetAccordion = (value) => {
          setAccordionShow(value)
          const data = [...accordionFirstLoad]
          if (!data.includes(value)) {
               data.push(value)
          }
          setAccordionFirstLoad(data)
     };

     return (
          <div className="record-profile">
               {isLoading ? (
                    <div className="record-profile loading-profile">
                         <h1>Target Account Profile</h1>
                         <div className="record-profile-info">
                              <div className="d-flex align-items-center justify-content-between mb-4">
                                   <div className="right-box d-flex flex-column">
                                        <p className="event-profile-session">Target Account ID: Target Account ID</p>
                                        <p className="event-profile-session">Domain: Domain</p>
                                        <div className="d-flex justify-content-between">
                                             <p className="event-profile-session">Identified Date: Identified Date</p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="list-count-data">
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Sessions</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Engaged Sessions</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Users</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime People</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime CompanyId</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Conversions</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Page Views</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Session Duration</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Avg Session Duration</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Lifetime Engagement Score</h4>
                                   <p className="item-name"></p>
                              </div>
                         </div>
                         <div className="page">
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Source (first):</p>
                                   <p className="description"></p>
                              </div>
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Source (last):</p>
                                   <p className="description"></p>
                              </div>
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Source (path):</p>
                                   <p className="description source-path"></p>
                              </div>
                         </div>
                         <div className="accordion accordion-profile">
                              {/* Company Description */}
                              <Accordion
                                   accordionType={ACCORDION_TYPES.COMPANY_DESCRIPTION}
                                   title="Company Description"
                                   classNameBody="event-profile-session"
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              >
                                   <RowDetails />
                                   <RowDetails />
                              </Accordion>

                              {/* Company Size */}
                              <Accordion
                                   accordionType={ACCORDION_TYPES.COMPANY_SIZE}
                                   title="Company Size"
                                   classNameBody="event-profile-session"
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              >
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                              </Accordion>

                              {/* Company Industry Details */}
                              <Accordion
                                   accordionType={ACCORDION_TYPES.COMPANY_INDUSTRY_DETAILS}
                                   title="Industry Details"
                                   classNameBody="event-profile-session"
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              >
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                              </Accordion>

                              {/* Company Location */}
                              <Accordion
                                   accordionType={ACCORDION_TYPES.COMPANY_LOCATION}
                                   title="Company Location"
                                   classNameBody="event-profile-session"
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              >
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                              </Accordion>

                              {/* Company Other Data */}
                              <Accordion
                                   accordionType={ACCORDION_TYPES.COMPANY_OTHER_DATA}
                                   title="Other Data"
                                   classNameBody="event-profile-session"
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              >
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                                   <RowDetails />
                              </Accordion>
                         </div>
                    </div>
               ) : (
                    <div className="record-profile company-profile">
                         <h1>Target Account Profile</h1>
                         {targetAccountInfo && (
                              <>
                                   <div className="record-profile-info">
                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                             <div className="right-box d-flex flex-column">
                                                  {(!isShowCompany && !(personId && personId.length > 0)) && (
                                                       <p>
                                                            <i>The target account hasn't matched to any people or companies yet.</i>
                                                       </p>
                                                  )}
                                                  <p>Target Account ID : {targetAccountInfo.targetAccountId}</p>
                                                  <div className="d-flex justify-content-between">
                                                       <p>Identified Date: {targetAccountInfo.identifiedDate}</p>
                                                  </div>
                                                  <p>Domain : {targetAccountInfo.domain}</p>

                                             </div>
                                        </div>
                                   </div>
                                   {(isShowCompany || (personId && personId.length > 0)) && (
                                        <>
                                             <div className="list-count-data">
                                                  <div className="card box-count-data">
                                                       <h4>Lifetime Sessions</h4>
                                                       {targetAccountInfo.countSession > 0 ? (
                                                            <p
                                                                 className="item-name link-data"
                                                                 onClick={() => handleLinkToOtherReport('sessionCount', linkedData)}
                                                            >
                                                                 {formatNumber(targetAccountInfo.countSession)}
                                                            </p>
                                                       ) : (
                                                            <p className="item-name">{targetAccountInfo.countSession}</p>
                                                       )}
                                                  </div>
                                                  <div className="card box-count-data">
                                                       <h4>Lifetime Engaged Sessions</h4>
                                                       <p className="item-name">{formatNumber(targetAccountInfo.countEngaged)}</p>
                                                  </div>
                                                  <div className="card box-count-data">
                                                       <h4>Lifetime Users</h4>
                                                       {targetAccountInfo.countUser > 0 ? (
                                                            <p className="item-name link-data" onClick={() => handleLinkToOtherReport('userCount', linkedData)}>
                                                                 {formatNumber(targetAccountInfo.countUser)}
                                                            </p>
                                                       ) : (
                                                            <p className="item-name">{targetAccountInfo.countUser}</p>
                                                       )}
                                                  </div>
                                                  <div className="card box-count-data">
                                                       <h4>Lifetime People</h4>
                                                       {targetAccountInfo.countPeople > 0 ? (
                                                            <p
                                                                 className="item-name link-data"
                                                                 onClick={() => handleLinkToOtherReport('peopleCount', linkedData)}
                                                            >
                                                                 {formatNumber(targetAccountInfo.countPeople)}
                                                            </p>
                                                       ) : (
                                                            <p className="item-name">{targetAccountInfo.countPeople}</p>
                                                       )}
                                                  </div>
                                                  {
                                                       <div className="card box-count-data">
                                                            <h4>Lifetime Company</h4>
                                                            {
                                                                 isShowCompany ? (
                                                                      <p
                                                                           className="item-name link-data"
                                                                           onClick={() => handleLinkToOtherReport('revealedCompany', linkedData)}
                                                                      >
                                                                           1
                                                                      </p>
                                                                 ) : (
                                                                      <p className="item-name">0</p>
                                                                 )

                                                            }

                                                       </div>

                                                  }

                                                  <div className="card box-count-data">
                                                       <h4>Lifetime Conversions</h4>
                                                       <p className="item-name">{formatNumber(targetAccountInfo.countConversions)}</p>
                                                  </div>
                                                  <div className="card box-count-data">
                                                       <h4>Lifetime Page Views</h4>
                                                       <p className="item-name">{formatNumber(targetAccountInfo.countPageView)}</p>
                                                  </div>
                                                  <div className="card box-count-data">
                                                       <h4>Session Duration</h4>
                                                       <p className="item-name">{convertReportDuration(targetAccountInfo.sessionDuration)}</p>
                                                  </div>
                                                  <div className="card box-count-data">
                                                       <h4>Avg Session Duration</h4>
                                                       <p className="item-name">{convertReportDuration(targetAccountInfo.avgSessionDuration)}</p>
                                                  </div>
                                                  <div className="card box-count-data">
                                                       <h4>Lifetime Engagement Score</h4>
                                                       <p className="item-name">{formatNumber(Number(targetAccountInfo.engagementScore))}</p>
                                                  </div>
                                             </div>
                                             <div className="page">
                                                  <div className="page-path-name">
                                                       <p className="title">Source (first):</p>
                                                       <div className="description">
                                                            <UserSourceDetails data={targetAccountInfo.sourceFirst} />
                                                       </div>
                                                  </div>
                                                  <div className="page-path-name">
                                                       <p className="title">Source (last):</p>
                                                       <div className="description">
                                                            <UserSourceDetails data={targetAccountInfo.sourceLast} />
                                                       </div>
                                                  </div>
                                                  <div className="page-path-name">
                                                       <p className="title">Source (path):</p>
                                                       <UserSourcePathDetails data={targetAccountInfo.sourcePath} />
                                                  </div>
                                             </div>
                                        </>
                                   )}

                                   {showCompanyData && (

                                        <div className="accordion accordion-profile">
                                             {/* Company Description */}
                                             <Accordion
                                                  accordionType={ACCORDION_TYPES.COMPANY_DESCRIPTION}
                                                  title="Company Description"
                                                  accordionShow={accordionShow}
                                                  setAccordionShow={setAccordionShow}
                                             >
                                                  <RowDetails label="Short Description" value={targetAccountInfo.descriptionShort} />
                                                  <RowDetails label="Description" value={targetAccountInfo.description} />
                                             </Accordion>

                                             {/* Company Size */}
                                             <Accordion
                                                  accordionType={ACCORDION_TYPES.COMPANY_SIZE}
                                                  title="Company Size"
                                                  accordionShow={accordionShow}
                                                  setAccordionShow={setAccordionShow}
                                             >
                                                  <RowDetails label="Monthly Visitors" value={targetAccountInfo.monthlyVisitors} icon="icon-analytics-v2" />
                                                  <RowDetails label="Revenue" value={targetAccountInfo.revenue} icon="icon-sack-dollar" />
                                                  <RowDetails label="Stock Exchange" value={targetAccountInfo.stockExchange} icon="icon-landmark" />
                                                  <RowDetails label="Stock Symbol" value={targetAccountInfo.stockSymbol} icon="icon-landmark-alt" />
                                                  <RowDetails label="Phone Number" value={targetAccountInfo.phone} icon="icon-phone" />
                                                  <RowDetails label="Total Employees" value={targetAccountInfo.employeeCountRange} icon="icon-users" />
                                                  <RowDetails label="Year Founded" value={targetAccountInfo.yearFounded} icon="icon-glass-cheers" />
                                                  <RowDetails label="Alexa Rank" value={targetAccountInfo.alexaRange} icon="icon-alexa" />
                                             </Accordion>

                                             {/* Company Industry Details */}
                                             <Accordion
                                                  accordionType={ACCORDION_TYPES.COMPANY_INDUSTRY_DETAILS}
                                                  title="Industry Details"
                                                  accordionShow={accordionShow}
                                                  setAccordionShow={setAccordionShow}
                                             >
                                                  <RowDetails label="Organization Type" value={targetAccountInfo.businessType} />
                                                  <RowDetails label="Primary Industry" value={targetAccountInfo.primaryIndustry} />
                                                  <RowDetails label="Products & Services Tags">
                                                       <BaseTags items={targetAccountInfo.productsServicesRows} />
                                                  </RowDetails>

                                                  <Accordion
                                                       accordionType={ACCORDION_TYPES.COMPANY_NAICS_DETAILS}
                                                       title="NAICS Details"
                                                       className="mt-3 mb-0"
                                                       accordionShow={accordionChildShow}
                                                       setAccordionShow={setAccordionChildShow}
                                                  >
                                                       <RowDetails label="NAICS Sector" value={targetAccountInfo.naicsSector} />
                                                       <RowDetails label="NAICS Subsector" value={targetAccountInfo.naicsSubsector} />
                                                       <RowDetails label="NAICS Industry Group" value={targetAccountInfo.naicsIndustryGroup} />
                                                       <RowDetails label="NAICS Industry Description" value={targetAccountInfo.naicsDescription} />
                                                       <RowDetails label="NAICS Code" value={targetAccountInfo.naicsCode} />
                                                  </Accordion>
                                             </Accordion>

                                             {/* Company Location */}
                                             <Accordion
                                                  accordionType={ACCORDION_TYPES.COMPANY_LOCATION}
                                                  title="Company Location"
                                                  accordionShow={accordionShow}
                                                  setAccordionShow={setAccordionShow}
                                             >
                                                  <RowDetails label="Address" value={targetAccountInfo.address} icon="icon-location" />
                                                  <RowDetails label="City" value={targetAccountInfo.city} icon="icon-hotel" />
                                                  <RowDetails label="County" value={targetAccountInfo.county} icon="icon-map-signs" />
                                                  <RowDetails
                                                       label="Country"
                                                       value={targetAccountInfo.country}
                                                       icon="icon-globe-stand"
                                                       countryCode={targetAccountInfo.countryShort}
                                                  />
                                                  <RowDetails
                                                       label="Country Short"
                                                       value={targetAccountInfo.countryShort}
                                                       icon="icon-globe-stand"
                                                  />
                                                  <RowDetails
                                                       label="State"
                                                       value={targetAccountInfo.state && targetAccountInfo.state.name}
                                                       icon="icon-map-marked-alt"
                                                  />
                                                  <RowDetails
                                                       label="Continent"
                                                       value={targetAccountInfo.continent && targetAccountInfo.continent.name}
                                                       icon="icon-globe"
                                                  />
                                                  <RowDetails label="Region" value={targetAccountInfo.region} icon="icon-region" />
                                                  <RowDetails label="Region Short" value={targetAccountInfo.regionShort} icon="icon-region" />
                                                  <RowDetails label="Postal" value={targetAccountInfo.postal} icon="icon-postal" />
                                                  <RowDetails label="Latitude" value={targetAccountInfo.latitude} icon="icon-latitude" />
                                                  <RowDetails label="Longitude" value={targetAccountInfo.longitude} icon="icon-longitude" />
                                                  <RowDetails label="TimeZone Id" value={targetAccountInfo.timezoneRegionID} icon="icon-timezone" />
                                                  <RowDetails label="TimeZone Name" value={targetAccountInfo.timezoneName} icon="icon-timezone" />
                                                  <RowDetails label="Utc Offset" value={targetAccountInfo.utcOffset} icon="icon-timezone-utc" />
                                                  <RowDetails label="Dst Offset" value={targetAccountInfo.dstOffset} icon="icon-dst" />
                                                  {targetAccountInfo.latitude && targetAccountInfo.longitude && (
                                                       <div className="small-google-map mt-4">
                                                            <iframe
                                                                 title="small google map"
                                                                 src={`https://www.google.com/maps/embed/v1/place?key=${keyMap}&q=${Number(
                                                                      targetAccountInfo.latitude
                                                                 )},${Number(targetAccountInfo.longitude)}&language=en&region=US`}
                                                                 height="450px"
                                                                 width="100%"
                                                            ></iframe>
                                                       </div>
                                                  )}
                                             </Accordion>

                                             {/* Company Other Data */}

                                             {isShowCompany && (
                                                  <Accordion Accordion
                                                       accordionType={ACCORDION_TYPES.COMPANY_OTHER_DATA}
                                                       title="Other Data"
                                                       accordionShow={accordionShow}
                                                       setAccordionShow={setAccordionShow}
                                                  >
                                                       <RowDetails label="Confidence" value={targetAccountInfo.confidence} />
                                                       <RowDetails label="Trade Name" value={targetAccountInfo.tradeName} />
                                                       <RowDetails label="Is ISP" value={targetAccountInfo.isISP} />
                                                       <RowDetails label="Is Wifi" value={targetAccountInfo.isWifi} />
                                                       <RowDetails label="Is Mobile" value={targetAccountInfo.isMobile} />
                                                       <RowDetails label="CreatedAt" value={formatDate(targetAccountInfo.createdAt, DEFAULT_DATE_FORMAT)} />
                                                       <RowDetails label="UpdatedAt" value={formatDate(targetAccountInfo.updatedAt, DEFAULT_DATE_FORMAT)} />
                                                  </Accordion>
                                             )}
                                        </div>
                                   )}

                              </>
                         )}
                    </div>
               )}

               {
                    (isShowCompany || (personId && personId.length > 0)) && (
                         <>
                              <div className={`accordion ${isShowCompany ? '' : 'accordion-profile'}`}>
                                   {isLoading ? (
                                        <>
                                             <Accordion
                                                  accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_COMPANY}
                                                  title={`CompanyDetail Details (0)`}
                                                  accordionShow={accordionShow}
                                                  setAccordionShow={setAccordionShow}
                                             ></Accordion>
                                             <Accordion
                                                  accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_PEOPLE}
                                                  title={`People Details (0)`}
                                                  accordionShow={accordionShow}
                                                  setAccordionShow={setAccordionShow}
                                             ></Accordion>
                                             <Accordion
                                                  accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER}
                                                  title={`User Details (0)`}
                                                  accordionShow={accordionShow}
                                                  setAccordionShow={setAccordionShow}
                                             ></Accordion>
                                             <Accordion
                                                  accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION}
                                                  title={`Session Details (0)`}
                                                  accordionShow={accordionShow}
                                                  setAccordionShow={setAccordionShow}
                                             ></Accordion>
                                        </>
                                   ) : (
                                        <>
                                             {targetAccountInfo && targetAccountInfo.companyId && (
                                                  <Accordion
                                                       accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_COMPANY}
                                                       title={`Company Details (1)`}
                                                       accordionShow={accordionShow}
                                                       setAccordionShow={handleSetAccordion}
                                                  >
                                                       {(accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_COMPANY || accordionFirstLoad.includes(ACCORDION_TYPES.RECORD_PROFILE_TABLE_COMPANY)) && (
                                                            <EventExplorer
                                                                 title={''}
                                                                 reportName={REPORT_NAMES.COMPANY_DETAILS}
                                                                 recordProfile={true}
                                                                 filterRecord={filterCompany}
                                                            />
                                                       )}
                                                  </Accordion>
                                             )}
                                             {targetAccountInfo && targetAccountInfo.countPeople > 0 && (
                                                  <Accordion
                                                       accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_PEOPLE}
                                                       title={`People Details (${targetAccountInfo.countPeople})`}
                                                       accordionShow={accordionShow}
                                                       setAccordionShow={handleSetAccordion}
                                                  >
                                                       {(accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_PEOPLE || accordionFirstLoad.includes(ACCORDION_TYPES.RECORD_PROFILE_TABLE_PEOPLE)) && (
                                                            <EventExplorer
                                                                 title={''}
                                                                 reportName={REPORT_NAMES.PERSON_DETAILS_PROFILE}
                                                                 recordProfile={true}
                                                                 filterRecord={filterPeople}
                                                            />
                                                       )}
                                                  </Accordion>
                                             )}
                                             {targetAccountInfo && targetAccountInfo.countUser > 0 && (
                                                  <Accordion
                                                       accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER}
                                                       title={`User Details (${targetAccountInfo.countUser})`}
                                                       accordionShow={accordionShow}
                                                       setAccordionShow={handleSetAccordion}
                                                  >
                                                       {(accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER || accordionFirstLoad.includes(ACCORDION_TYPES.RECORD_PROFILE_TABLE_USER)) && (
                                                            <EventExplorer title={''} reportName={REPORT_NAMES.USER_DETAILS} recordProfile={true} filterRecord={filterUser} />
                                                       )}
                                                  </Accordion>
                                             )}
                                             {targetAccountInfo && targetAccountInfo.countSession > 0 && (
                                                  <Accordion
                                                       accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION}
                                                       title={`Session Details (${targetAccountInfo.countSession})`}
                                                       accordionShow={accordionShow}
                                                       setAccordionShow={handleSetAccordion}
                                                  >
                                                       {(accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION || accordionFirstLoad.includes(ACCORDION_TYPES.RECORD_PROFILE_TABLE_SESSION)) && (
                                                            <EventExplorer title={''} reportName={REPORT_NAMES.SESSION_DETAILS} recordProfile={true} filterRecord={filterSession} />
                                                       )}
                                                  </Accordion>
                                             )}
                                        </>
                                   )}
                              </div>
                         </>
                    )
               }

          </div >
     );
};

export default RecordProfileTargetAccount;
