import findIndex from 'lodash/findIndex';
import React from 'react';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import { setShowCreateBrowserVariable, setShowCreateVariable } from '../../actions/subscriber';
import {
     BQ_JOB_STATUS,
     CARD_TYPES,
     COMPONENT_NAME,
     DEFAULT_DATE_FORMAT,
     DESTINATION_CODE,
     GOALS_PROPS,
     GTM_VARIABLE_IN_LL_TYPE,
     LISTENER_CODE,
     LISTENER_TYPE,
     LISTENER_TYPES,
     LOCAL_SETUP_INVITED_ACCOUNT,
     LOCAL_SHOW_SETUP_ACCOUNTS,
     LOWERCASE_CLICK_FEATURES,
     PURCHASE_ECOMMERCE_GA4_FEATURES,
     PURCHASE_ECOMMERCE_GA4_FEATURES_V2,
     SPECIAL_FEATURE_LIST,
     SUBSCRIBER_MY_ACCOUNT_PATH,
     SUBSCRIBER_PATH,
     TICKET_STATUSES,
} from '../../constants';
import { TARGET_ACCOUNT_GROUP_TYPE } from '../../components/cms/subscriber/goals/target-accounts/constants';
import { defaultSubscriberAnalyticsNav } from '../../containers/_nav/subscriber';
import { defaultSubscriberRoutes } from '../../routes';
import { getLocalItem, tryParseJSON } from '../../utils';

// const Listener = React.lazy(() => import('../../components/cms/subscriber/listeners/Listener'));
const Listener = React.lazy(() => import('../../components/cms/subscriber/listener-settings-v2'));
const VariableSettings = React.lazy(() => import('../../components/cms/subscriber/listener-settings/variable-settings/VariableSettings'));
const Features = React.lazy(() => import('../../components/cms/subscriber/listener-settings/features/Features'));
const Reporting = React.lazy(() => import('../../components/cms/subscriber/listener-settings/reporting/Reporting'));
const MonitoringNotificationDetailV2 = React.lazy(() => import('../../components/cms/subscriber/notifications/MonitoringNotificationDetailV2'));

const Destination = React.lazy(() => import('../../components/cms/subscriber/destination-settings'));
const GoalsLayout = React.lazy(() => import('../../components/cms/subscriber/goals/GoalsLayout'));
const DataActionRule = React.lazy(() => import('../../components/cms/subscriber/destination-settings/dataActions/DataActionRule'));

export const getEnabledListeners = (listeners) => {
     return listeners.filter((listener) => listener.configStatus);
};

export const getListenerRoutes = (enabledListeners, specificFeatures, secondId) => {
     let routes = [];

     enabledListeners.forEach((enabledListener) => {
          const { name, alias } = enabledListener;
          const listenerSlug = `/${secondId}/listeners/${alias}`;

          routes.push(
               // CSidebarNavDropdown must have exact: true, linkDisabled: Link on breadcrumb becomes to a normal text
               {
                    path: listenerSlug,
                    name: name,
                    component: Listener,
                    exact: true,
                    // linkDisabled: true
               },
               {
                    path: `${listenerSlug}/features`,
                    name: 'Features',
                    component: Features,
               },
               {
                    path: `${listenerSlug}/variable-settings`,
                    name: 'Variable Settings',
                    component: VariableSettings,
               }
          );

          if (specificFeatures && specificFeatures.length > 0) {
               let specificListenerFeatures = specificFeatures.filter((specificFeature) => specificFeature.listenerId === enabledListener.id);

               specificListenerFeatures.forEach((item) => {
                    let strToCompare = item.code.toLowerCase();

                    if (strToCompare.includes('reporting')) {
                         routes.push({
                              path: `${listenerSlug}/${SUBSCRIBER_PATH.REPORTING}`,
                              name: 'Reporting',
                              component: Reporting,
                         });
                    }
               });
          }
     });
     return routes;
};

export const getDestinationRoutes = (enabledDestinations, secondId) => {
     let routes = [];

     enabledDestinations.forEach((destination) => {
          const { name, alias } = destination;
          const destinationSlug = `/${secondId}/destinations/${alias}`;

          routes.push(
               // CSidebarNavDropdown must have exact: true, linkDisabled: Link on breadcrumb becomes to a normal text
               {
                    path: destinationSlug,
                    name: name,
                    component: Destination,
                    exact: true,
               },
               {
                    path: `${destinationSlug}/${SUBSCRIBER_PATH.DATA_ACTIONS}`,
                    name: 'Data Actions',
                    component: Destination,
                    exact: true,
                    notShow: true,
               },
               {
                    path: `${destinationSlug}/${SUBSCRIBER_PATH.DATA_ACTIONS}/create`,
                    name: 'Create',
                    component: DataActionRule,
               },
               {
                    path: `${destinationSlug}/${SUBSCRIBER_PATH.DATA_ACTIONS}/:id`,
                    name: 'Edit',
                    component: DataActionRule,
               }
          );
     });

     return routes;
}

export const getAccountGoalsRoute = (accountGoals, secondId) => {
     let routes = [];

     accountGoals.forEach((goal) => {
          if (goal.status || goal.comingSoon) {
               const { name, alias } = goal;
               const goalSlug = `/${secondId}/goals/${alias}`;
     
               routes.push(
                    {
                         path: goalSlug,
                         name: name,
                         component: GoalsLayout,
                         props: GOALS_PROPS[name],
                    }
               );
          }
     });

     return routes;
}

export const getMonitoringNotificationRoutes = (monitoringNotifications) => {
     let routes = [];

     monitoringNotifications.forEach((notification) => {
          const { id, title } = notification;
          routes.push({
               path: `${SUBSCRIBER_PATH.MONITORING_NOTIFICATIONS}/${id}`,
               name: title,
               component: MonitoringNotificationDetailV2,
          });
     });

     return routes;
};

export const getListenerNav = (activeListeners, specificFeatures, activeFeatures, activeListener, handleClickNavMenu) => {
     const listenerNav = [];
     activeListeners.forEach((enabledListener) => {
          const { name, alias, code, id, tintedIcon } = enabledListener;
          const listenerSlug = `/${alias}`;
          let navChildren = [];
          let flag = false;

          activeFeatures.some((activeFeature) => {
               if (id === activeFeature.listenerId) {
                    flag = true;
                    return true;
               }
               return false;
          });

          if (!flag) {
               navChildren.push({
                    _tag: 'FeatureSubNav',
                    to: `${listenerSlug}/features`,
               });
          }

          navChildren.push(
               {
                    _tag: 'CSidebarNavItem',
                    exact: false,
                    name: 'Features',
                    to: `${listenerSlug}/features`,
               },
               {
                    _tag: 'CSidebarNavItem',
                    exact: false,
                    name: 'Event Settings',
                    to: `${listenerSlug}/event-settings`,
               },
               {
                    _tag: 'CSidebarNavItem',
                    exact: false,
                    name: 'Variable Settings',
                    to: `${listenerSlug}/variable-settings`,
               }
          );

          if (specificFeatures && specificFeatures.length > 0) {
               let specificListenerFeatures = specificFeatures.filter((specificFeature) => specificFeature.listenerId === enabledListener.id);
               specificListenerFeatures.forEach((item) => {
                    let strToCompare = item.code.toLowerCase();

                    if (strToCompare === 'usersource') {
                         navChildren.push({
                              _tag: 'CSidebarNavItem',
                              exact: false,
                              name: 'User Source Rules',
                              to: `${listenerSlug}/${SUBSCRIBER_PATH.USER_SOURCE_RULES}`,
                         });
                    } else if (strToCompare.includes('myvalues') || strToCompare.includes('customvalues')) {
                         navChildren.push({
                              _tag: 'CSidebarNavItem',
                              exact: false,
                              name: 'Custom Values',
                              to: `${listenerSlug}/${SUBSCRIBER_PATH.CUSTOM_VARIABLE_RULES}`,
                         });
                    } else if (strToCompare.includes('storage')) {
                         navChildren.push({
                              _tag: 'CSidebarNavItem',
                              exact: false,
                              name: 'Browser Storage',
                              to: `${listenerSlug}/${SUBSCRIBER_PATH.BROWSER_STORAGE}`,
                         });
                    } else if (strToCompare.includes('monitoring')) {
                         navChildren.push({
                              _tag: 'CSidebarNavItem',
                              exact: false,
                              name: 'Monitoring',
                              to: `${listenerSlug}/monitoring`,
                         });
                    } else if (strToCompare.includes('reporting')) {
                         navChildren.push({
                              _tag: 'CSidebarNavItem',
                              exact: false,
                              name: 'Reporting',
                              to: `${listenerSlug}/reporting`,
                         });
                    } else if (strToCompare.includes('populate')) {
                         navChildren.push({
                              _tag: 'CSidebarNavItem',
                              exact: false,
                              name: 'Populate Fields',
                              to: `${listenerSlug}/${SUBSCRIBER_PATH.POPULATE_FIELD}`,
                         });
                    } else if (strToCompare.includes('selector')) {
                         navChildren.push({
                              _tag: 'CSidebarNavItem',
                              exact: false,
                              name: 'Selector Rules',
                              to: `${listenerSlug}/${SUBSCRIBER_PATH.SELECTOR_RULES}`,
                         });
                    } else if (LOWERCASE_CLICK_FEATURES.includes(strToCompare)) {
                         navChildren.push({
                              _tag: 'CSidebarNavItem',
                              exact: false,
                              name: 'Click Settings',
                              to: `${listenerSlug}/${SUBSCRIBER_PATH.CLICK_SETTINGS}`,
                         });
                    }
               });
          }

          const navItem = {
               _tag: 'CSidebarNavDropdown',
               name: name,
               route: listenerSlug,
               icon: getNameLogo(code).logoTinted ? getNameLogo(code).logoTinted : tintedIcon ? { src: tintedIcon, className: 'sidebar-nav-icon' } : 'cil-layers',
               _children: navChildren,
          };
          if (activeListener) {
               if (activeListener.name === name) {
                    navItem.show = true;
               } else {
                    navItem.show = false;
                    navItem.onClick = handleClickNavMenu;
               }
          }

          listenerNav.push(navItem);
     });

     return listenerNav;
};

export const getSimpleListenerNav = (props) => {
     const {
          elementJustActivated,
          activeListeners,
          menuItemActive,
          // menuItemActiveListener,
          activeDropdown,
          setActiveDropdown,
          reportId,
          // setMenuItemActive
          externalData,
     } = props;

     const { secondId } = reportId

     const navItems = LISTENER_TYPES.sort((a, b) => a.localeCompare(b)).map((category) => {
          const navChildren = [{ category: category }];
          const activeListenersFilter = activeListeners.filter((listener) => listener.type === category);

          activeListenersFilter.sort((a, b) => a.name.toString().toLowerCase() < b.name.toString().toLowerCase() ? -1 : 1).forEach(enabledListener => {
               const { name, alias, tintedIcon, id, code } = enabledListener;
               const listenerSlug = `/${secondId}/listeners/${alias}`;
               const navItem = {
                    _tag: 'CSidebarNavItem',
                    name: name,
                    route: listenerSlug,
                    icon: getNameLogo(code).logoTinted ? getNameLogo(code).logoTinted : tintedIcon ? { src: tintedIcon, className: 'sidebar-nav-icon' } : 'cil-layers',
                    to: listenerSlug,
                    exact: false,
                    show: true,
                    className: elementJustActivated && elementJustActivated.id === id ? 'highlight' : '',
               }

                    navChildren.push(navItem);
               });

          return navChildren;
     });

     const priorityItems = ['Users', 'Content', 'Clicks', 'Forms', 'Chats', 'eCommerce', 'Video', 'Scheduling', 'Native Data'];
     const updateListenerNav = navItems.sort((a, b) => {
          const indexA = priorityItems.indexOf(a[0].category);
          const indexB = priorityItems.indexOf(b[0].category);

          if (indexA !== -1 && indexB !== -1) {
               return indexA - indexB;
          } else if (indexA !== -1) {
               return -1;
          } else if (indexB !== -1) {
               return 1;
          }

          return a[0].category.localeCompare(b[0].category);
     });

     const externalChildren = externalData
          ? externalData
                 .filter((source) => source.accountExternalData && source.accountExternalData.status)
                 .map((source) => {
                      const sourceSlug = `/${secondId}/external-data/${source.alias}`;
                      return {
                           _tag: 'CSidebarNavItem',
                           exact: false,
                           icon: 'cil-layers',
                           name: source.name,
                           to: sourceSlug,
                      };
                 })
          : [];

     const customDataNav = [
          {
               _tag: 'CNavItemGroups',
               name: 'Listeners',
               data: updateListenerNav,
               show: menuItemActive === 'Listeners',
               showButton: true,
               componentName: COMPONENT_NAME.LISTENER_LIBRARY,
               nameButton: 'MANAGE LISTENERS',
               noData: 'No Enabled Listeners',
               showTooltip: true,
               className: 'nav-items',
          },
          {
               _tag: 'CSidebarNavDropdownCustom',
               name: 'Custom Web Data',
               _children: [
                    {
                         _tag: 'CSidebarNavItem',
                         name: 'Data Layer Values',
                         icon: 'cil-layers',
                         to: SUBSCRIBER_PATH.CUSTOM_DATA_LAYER_VARIABLES.replace(':secondId', secondId),
                         exact: false,
                    },
                    {
                         _tag: 'CSidebarNavItem',
                         name: 'Browser Storage',
                         icon: 'iconHubspotTinted',
                         to: SUBSCRIBER_PATH.CUSTOM_BROWSER_STORAGE_VARIABLES.replace(':secondId', secondId),
                         exact: false,
                    },
               ],
               show: menuItemActive === 'Custom Web Data',
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Reveal',
               to: SUBSCRIBER_PATH.INSIGHTS_REVEAL.replace(':secondId', secondId)
          },
          {
               _tag: 'CSidebarNavDropdownCustom',
               name: 'External Data',
               nameButton: 'Manage Sources',
               showButton: true,
               componentName: COMPONENT_NAME.EXTERNAL_DATA,
               _children: externalChildren,
               // _children: [
               //      {
               //           _tag: 'CSidebarNavItem',
               //           name: 'Google Ads',
               //           icon: 'cil-layers',
               // to: SUBSCRIBER_PATH.EXTERNAL_DATA_GOOGLE_ADS,
               //           exact: false,
               //      },
               //      {
               //           _tag: 'CSidebarNavItem',
               //           name: 'Salesforce',
               //           icon: 'cil-layers',
               //           to: SUBSCRIBER_PATH.EXTERNAL_DATA_SALESFORCE,
               //           exact: false,
               //      },
               //      {
               //           _tag: 'CSidebarNavItem',
               //           name: 'Facebook Ads',
               //           icon: 'cil-layers',
               //           to: SUBSCRIBER_PATH.EXTERNAL_DATA_FACEBOOK_ADS,
               //           exact: false,
               //      },
               //      {
               //           _tag: 'CSidebarNavItem',
               //           name: 'Microsoft Ads',
               //           icon: 'cil-layers',
               //           to: SUBSCRIBER_PATH.EXTERNAL_DATA_MICROSOFT_ADS,
               //           exact: false,
               //      },
               //      {
               //           _tag: 'CSidebarNavItem',
               //           name: 'TikTok',
               //           icon: 'cil-layers',
               //           to: SUBSCRIBER_PATH.EXTERNAL_DATA_TIK_TOK,
               //           exact: false,
               //      },
               //      {
               //           _tag: 'CSidebarNavItem',
               //           name: 'FromFile',
               //           icon: 'cil-layers',
               //           to: SUBSCRIBER_PATH.EXTERNAL_DATA_FROM_FILE,
               //           exact: false,
               //      },
               // ],
               show: menuItemActive === 'External Data',
          },
     ];

     const customNavItems = [...customDataNav].map((item) => {
          if (item['_tag'] === 'CNavItemGroups' || item['_tag'] === 'CSidebarNavDropdownCustom') {
               const handleToggle = () => {
                    if (item.name === activeDropdown) {
                         setActiveDropdown('');
                    } else {
                         setActiveDropdown(item.name);
                    }
               };

               return { ...item, show: [activeDropdown].includes(item.name), toggleCustom: () => handleToggle() };
          } else {
               return item;
          }
     });

     return customNavItems;
};

export const getCustomReport = (item, customReports, ReportId) => {
     if (!customReports) return;

     if (item.name === 'Custom Reports' && customReports.length === 0) {
          item._children = [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Manage Reports',
                    icon: 'cil-settings',
                    to: SUBSCRIBER_PATH.INSIGHTS_CUSTOM_REPORTS.replace(':secondId', ReportId.secondId),
               },
          ];
     }

     if (item.name === 'Custom Reports' && customReports.length > 0) {
          if (customReports.length > 0) {
               item._children = [
                    {
                         _tag: 'CSidebarNavDropdownCustom',
                         name: 'Manage Reports',
                         icon: 'cil-settings',
                         className: 'item-dropdown-toggle',
                         show: ReportId ? true : false,
                         path: SUBSCRIBER_PATH.INSIGHTS_CUSTOM_REPORTS.replace(':secondId', ReportId.secondId).replace(':id', ReportId.id),
                         type: 'dropdown',
                    },
               ];
          }

          const childrenArray = customReports
               .filter((report) => report.status)
               .map((report) => {
                    return {
                         _tag: 'CSidebarNavItem',
                         name: report.name,
                         to: SUBSCRIBER_PATH.INSIGHTS_CUSTOM_REPORT_DETAIL.replace(':secondId', ReportId.secondId).replace(':id', report.id),
                         exact: false,
                    };
               });

          item._children[0]._children = [...childrenArray];
     }
};

export const getSimpleAnalyticNav = (props) => {
     const { activeDropdown, setActiveDropdown, customReports, reportId, activeAccount } = props;

     const navItems = [...defaultSubscriberAnalyticsNav]
          .map((item) => {
               const { secondId } = reportId;

               const handleToggle = () => {
                    if (item.name === activeDropdown) {
                         setActiveDropdown('');
                    } else {
                         setActiveDropdown(item.name);
                    }
               };

               if (item['_tag'] === 'CSidebarNavItem') {
                    return {
                         ...item,
                         to: item.to ? item.to.replace(':secondId', secondId) : item.to,
                    }
               }

               if (item['_tag'] === 'CSidebarNavDropdownCustom') {
                    getCustomReport(item, customReports, reportId);

                    return {
                         ...item,
                         to: item.to ? item.to.replace(':secondId', secondId) : item.to,
                         _children: item._children.map(_child => ({
                              ..._child,
                              to: _child.to ? _child.to.replace(':secondId', secondId) : _child.to
                         })),
                         show: [activeDropdown].includes(item.name),
                         toggleCustom: () => handleToggle()
                    }
               } else {
                    return item;
               }
          }).filter(item => {
               if (item['_tag'] === 'CSidebarNavItem' && item.name === 'Saved Reports') {
                    return activeAccount.savedReport;
               }
               return true; 
          })

     return navItems;
};

export const getActiveDestinationNav = ({ activeDestinations, showChild = false, className = '', elementJustActivated, handleSetFlexibleModal, showTour, reportId }) => {
     let navItem = [];
     const navItemDefault = {
          _tag: 'CSidebarNavItem',
          name: 'Add Destination',
          icon: 'icon-plus',
          className: 'custom-btn-add',
          onClick: () => handleSetFlexibleModal(COMPONENT_NAME.DESTINATION_LIBRARY),
     };

     const { secondId } = reportId

     // Sort name by descending (a -> z)
     activeDestinations.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).forEach(activeDestination => {
          const { id, name, alias, tintedIcon, createdAccountId } = activeDestination;
          const destinationSlug = `/${secondId}/destinations/${alias}`;

          const navItemActive = {
               _tag: 'CSidebarNavItem',
               name: name,
               route: destinationSlug,
               icon: tintedIcon ? { src: tintedIcon, className: 'sidebar-nav-icon' } : getDestinationLogo(name).logoTinted,
               to: `${destinationSlug}`,
               exact: false,
               className: `custom-des-nav destination-nav${elementJustActivated && elementJustActivated.id === id ? ' highlight' : ''} ${showTour ? ' hide-icon-arrow' : ''}`,
          };

          if (createdAccountId) {
               navItemActive.badge = {
                    color: 'info',
                    text: 'custom',
               };
          }
          navItem.push(navItemActive);
     });

     if (activeDestinations.length > 0) {
          return [navItemDefault, ...navItem];
     }
     return [];
};

export const getActiveGoals = ({ accountGoals, handleSetFlexibleModal, reportId }) => {
     if (!accountGoals || !accountGoals.length ) {
          return []
     }

     const navItemDefault = {
          _tag: 'CSidebarNavItem',
          name: 'Manage Goal Types',
          icon: 'icon-plus',
          className: 'custom-btn-add',
          onClick: () => handleSetFlexibleModal(COMPONENT_NAME.GOAL_MANAGE_TYPES),
     }

     const navItems = []
     accountGoals.forEach((item) => {
          if (item.status || item.comingSoon) {
               const navItemActive = {
                    _tag: 'CSidebarNavItem',
                    name: item.name,
                    icon: item.icon,
               }
               const { secondId } = reportId;
               navItemActive.to = `/${secondId}/goals/${item.alias}`;
     
               navItems.push(navItemActive) 
          }
     })

     if (accountGoals.length > 0) {
          return [navItemDefault, ...navItems];
     }

     return []
}

export const getActiveMonitoring = ({ defaultSubscriberMonitoringNav, menuItemActive, monitoringUnreadCount, reportId }) => {
     return [...defaultSubscriberMonitoringNav].map((item) => {
          const newItem = { ...item };

          newItem.show = item.name === menuItemActive ? true : null;

          if (item.name === 'Monitoring Notifications') {
               newItem.badge = monitoringUnreadCount > 0
                    ? { color: 'info', text: monitoringUnreadCount }
                    : {};
          }

          newItem.to = item.to ? item.to.replace(':secondId', reportId.secondId) : item.to

          if (item._children) {
               newItem._children = item._children.map(_child => ({
                    ..._child,
                    to: _child.to ? _child.to.replace(':secondId', reportId.secondId) : _child.to,
               }));
          }

          return newItem;
     });
}


export const findAccount = (accountId, accounts) => {
     return accounts.find((account) => {
          return account.id === accountId || account.secondId === accountId;
     })
}

export const findAccountIndex = (accounts, accountId) => {
     return findIndex(accounts, { id: accountId });
};

export const getStripeCardImage = (stripeCardType) => {
     for (const type in CARD_TYPES) {
          const cardType = CARD_TYPES[type];
          if (typeof cardType.NAME !== 'undefined' && stripeCardType === cardType.NAME) {
               return <img src={cardType.IMAGE_SRC} alt={cardType.NAME} />;
          }
     }
     return <></>;
};

export const replaceText = (stringToReplace, arr) => {
     let stringAfterReplacing = stringToReplace;

     arr.forEach((item) => {
          stringAfterReplacing = stringAfterReplacing.replace(item.searchvalue, item.newValue);
     });

     return stringAfterReplacing;
};

export const autoGenerateNdigits = () => {
     let max = 9999;
     let min = 1000;
     return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const useActiveAccountIndex = () => {
     const accounts = useSelector((state) => state.subscriber.accounts);
     const activeAccountId = useSelector((state) => state.subscriber.activeAccount.id);
     let activeAccountIndex = -1;

     accounts.some((account, index) => {
          if (account.id === activeAccountId) {
               activeAccountIndex = index;
               return true;
          }

          return false;
     });

     return activeAccountIndex;
};

export const getListVariables = (dlVars) => {
     let variableData = [];
     if (dlVars && dlVars.length > 0) {
          dlVars.forEach((variable) => {
               variable.isDisabled = variable.friendlyName !== '';
               variableData.push(variable);

               if (variable.childs) {
                    variableData.push(...getListVariables(variable.childs));
               }
          });
     }
     return variableData;
};

export const setPackageToAccount = (account, packageToSet, isOwner) => {
     let newAccount = {
          ...account,
          packageAdobeIntegration: packageToSet.adobeIntegration,
          packageAmountSavedOnAnnual: packageToSet.amountSavedOnAnnual,
          packageDescription: packageToSet.description,
          packageEventReporting: packageToSet.eventReporting,
          packageFriendlyName: packageToSet.friendlyName,
          packageGtagIntegration: packageToSet.gtagIntegration,
          packageId: packageToSet.id,
          packageLowEventEmailNotification: packageToSet.lowEventEmailNotification,
          packageLowEventInterfaceNotification: packageToSet.lowEventInterfaceNotification,
          packageLowEventInterfaceRecommendation: packageToSet.lowEventInterfaceRecommendation,
          packageLowEventWarningThreshold: packageToSet.lowEventWarningThreshold,
          packageName: packageToSet.internalName,
          packageNumberOfCusDatalayerVariables: packageToSet.numberOfCusDatalayerVariables,
          packageNumberOfCusBrowserVariables: packageToSet.numberOfCusBrowserVariables,
          packageNumberOfCusVariablesRules: packageToSet.numberOfCusVariablesRules,
          packageNumberOfCusBrowserRules: packageToSet.numberOfCusBrowserRules,
          packageNumberOfDomains: packageToSet.numberOfDomains,
          packageNumberOfEventMonitor: packageToSet.numberOfEventMonitor,
          packageNumberOfEventsPerMonth: packageToSet.numberOfEventsPerMonth,
          packageNumberOfListeners: packageToSet.numberOfListeners,
          packageNumberOfPremium: packageToSet.numberOfPremium,
          packageNumberOfUsers: packageToSet.numberOfUsers,
          packagePercentSavedOnAnnual: packageToSet.percentSavedOnAnnual,
          packagePricePerMonth: packageToSet.pricePerMonth,
          packagePricePerYear: packageToSet.pricePerYear,
          packageIncrementalCostOfEvents: packageToSet.incrementalCostOfEvents,
          packageShortDescription: packageToSet.shortDescription,
          packageStatus: packageToSet.status,
          isFree: packageToSet.pricePerMonth === 0,
          packageNumberOfEventMonitoring: packageToSet.numberOfEventMonitoring,
          packageEventPopulateFields: packageToSet.eventPopulateFields,
          packageNumberOfVisitors: packageToSet.numberOfVisitors,
          packageNumberOfSessions: packageToSet.numberOfSessions,
          packageVersion: packageToSet.version,
     };

     if (typeof isOwner !== 'undefined') {
          newAccount.isOwner = isOwner;
     }

     return newAccount;
};

export const handleLoginPopup = (userId) => {
     const localShowSetupAccounts = getLocalItem(LOCAL_SHOW_SETUP_ACCOUNTS);

     if (localShowSetupAccounts && localShowSetupAccounts.length > 0) {
          if (localShowSetupAccounts && localShowSetupAccounts.length > 0) {
               let existedIndex = findIndex(localShowSetupAccounts, { userId });

               if (existedIndex !== -1) {
                    localStorage.setItem(LOCAL_SETUP_INVITED_ACCOUNT, localShowSetupAccounts[existedIndex].isInvited);

                    let newLocalShowSetupAccounts = [...localShowSetupAccounts];
                    newLocalShowSetupAccounts.splice(existedIndex, 1);
                    localStorage.setItem(LOCAL_SHOW_SETUP_ACCOUNTS, JSON.stringify(newLocalShowSetupAccounts));
               }
          }
     }
};

// Get new routes when enabling or disabling a feature
export const getNewFeatureRoutes = ({ path, currentRoutes, featureStatus, activeListener }) => {
     let newRoutes = [...currentRoutes];
     const { alias } = activeListener;
     const listenerSlug = `/${alias}`;

     switch (path) {
          case SUBSCRIBER_PATH.REPORTING:
               if (featureStatus) {
                    // Enable
                    newRoutes.push({ path: `${listenerSlug}/${SUBSCRIBER_PATH.REPORTING}`, name: 'Reporting', component: Reporting });
               } else {
                    // Disable
                    newRoutes = newRoutes.filter((route) => !route.path.includes(`${listenerSlug}/${SUBSCRIBER_PATH.REPORTING}`));
               }
               break;
          default:
     }

     return newRoutes;
};

export const subscriberPusher = {
     inviteManagerUser: (state, data) => {
          const { activeAccount, owner, managers } = state;
          let newActiveAccount = { ...activeAccount };
          newActiveAccount.numberOfUsers += 1;

          if (data.userId !== data.currentUserId) {
               if (owner) {
                    let newManagers = [...managers, data.user];

                    return {
                         activeAccount: newActiveAccount,
                         managers: newManagers,
                    };
               }
          }

          return { activeAccount: newActiveAccount };
     },
     crawlForm: (state, data) => {
          const domains = state.domains;
          const activeAccount = state.activeAccount;
          const { isDone, domain } = data;
          let returnValue = {};

          if (isDone) {
               const domainsRes = typeof activeAccount.domains === 'string' ? JSON.parse(activeAccount.domains) : activeAccount.domains;
               let currentDomain = domain.replace(/^https:\/\//, '').replace(/\/$/, '');

               for (let index = 0; index < domainsRes.length; index++) {
                    const domainAccount = domainsRes[index];
                    if (domainAccount.name === currentDomain) {
                         domainsRes[index].isCrawling = false;
                    }
               }

               const initialDomains = domains.initialDomains;

               for (let index = 0; index < initialDomains.length; index++) {
                    const domainAccount = initialDomains[index];
                    if (domainAccount.name === currentDomain) {
                         initialDomains[index].isCrawling = false;
                    }
               }

               returnValue = {
                    domains: { ...domains, initialDomains },
                    activeAccount: { ...activeAccount, domains: JSON.stringify(domainsRes) }
               };
          }
          return returnValue;
     },
     removeManagerUser: (state, data) => {
          const { activeAccount, owner, managers } = state;
          let newActiveAccount = cloneDeep(activeAccount);
          newActiveAccount.numberOfUsers = parseInt(data.numberOfUsers);

          if (owner) {
               let newManagers = [...managers];
               newManagers = newManagers.filter((mamager) => mamager.userId !== data.userId);

               return {
                    activeAccount: newActiveAccount,
                    managers: newManagers,
               };
          }

          return { activeAccount: newActiveAccount };
     },
     createNewUserManager: (state, user) => {
          const { owner, managers } = state;

          // If user navigates to /users once
          if (owner) {
               const managerIndex = findIndex(managers, { email: user.email });

               if (managerIndex !== -1) {
                    let newManagers = [...managers];
                    newManagers[managerIndex] = { ...newManagers[managerIndex], ...user };

                    return { managers: newManagers };
               }
          }

          return {};
     },
     changeListenerStatus: (state, data) => {
          const { activeAccount, accountListeners, activeFeatures, customFormTargetRules, monitoringNotifications } = state;
          const { isPremium, listener, numberOfPremium, numberOfRule } = data;
          const listenerIndex = findIndex(accountListeners, { id: listener.listenerId });
          let specificFeatures = activeFeatures.filter((feature) => {
               let strToCompare = feature.code.toLowerCase();

               return SPECIAL_FEATURE_LIST.some((specialItem) => strToCompare.includes(specialItem));
          });
          let newSpecificFeatures = null;
          let newActiveFeatures = [...activeFeatures];
          if (listenerIndex !== -1) {
               let newAccountListeners = [...accountListeners];
               newAccountListeners[listenerIndex].configStatus = listener.status;
               let newActiveAccount = { ...activeAccount };

               if (listener.status) {
                    // Enable
                    newActiveAccount.numberOfListeners += 1;
                    if (isPremium) {
                         newActiveAccount.numberOfPremium += 1;
                    }
               } else {
                    // Disable
                    newActiveAccount.numberOfListeners -= 1;
                    newActiveAccount.numberOfPremium = numberOfPremium;
                    newActiveAccount.numberOfCusVariablesRules = numberOfRule;

                    newActiveFeatures = activeFeatures.filter((feature) => feature.listenerId !== listener.listenerId);

                    // Remove custom values and monitoring from specificFeatures
                    newSpecificFeatures = specificFeatures.filter((item) => item.listenerId !== listener.listenerId);
               }

               const enabledListeners = getEnabledListeners(newAccountListeners);
               const listenerRoutes = getListenerRoutes(enabledListeners, newSpecificFeatures || specificFeatures);
               const monitoringNotificationRoutes = getMonitoringNotificationRoutes(monitoringNotifications);
               const newRoutes = [...defaultSubscriberRoutes, ...listenerRoutes, ...monitoringNotificationRoutes];

               return {
                    activeAccount: newActiveAccount,
                    routes: newRoutes,
                    accountListeners: newAccountListeners,
                    activeListeners: enabledListeners,
                    activeFeatures: newActiveFeatures,
                    customFormTargetRules: listener.status && listener.code === 'customForms' ? null : customFormTargetRules,
               };
          }

          return {};
     },
     changeFeatureStatus: (state, data) => {
          const { activeAccount, features, activeListeners, activeFeatures, routes, nativeBrowserStorage } = state;
          const { isPremium, listenerId, feature } = data;
          const activeListener = activeListeners.find((listener) => listener.id === listenerId);
          let { listenerEvents, videoProgresses } = state;
          let strToCompare = feature.code.toLowerCase();
          let newRoutes, newSelectorOptions;
          const featureClick = {
               id: feature.featureId,
               code: feature.code,
               listenerId: feature.listenerId,
               featureSupport: feature.featureSupport,
               status: feature.status,
               type: feature.type,
               featureContent: feature.featureContent,
          };
          let newData = {
               activeAccount: { ...activeAccount },
               newFeatures: [...features],
               newActiveFeatures: [...activeFeatures],
          };

          if (activeListener) {
               const eventSettingPathName = `${activeListener.alias}`;
               const featureIndex = findIndex(features, { id: featureClick.id });
               if (window.location.pathname.includes(eventSettingPathName)) {
                    if (featureIndex !== -1) {
                         newData.newFeatures[featureIndex].configStatus = featureClick.status;
                         newData.newFeatures[featureIndex].featureSupport = featureClick.featureSupport;
                         if (isPremium) {
                              if (featureClick.status) {
                                   newData.activeAccount.numberOfPremium += 1;
                              } else {
                                   newData.activeAccount.numberOfPremium -= 1;
                              }
                         }
                    }
               }
               if (window.location.pathname.includes(eventSettingPathName)) {
                    let { initialEvents, featureSupports } = listenerEvents;
                    if (data.feature.code.indexOf('EventReporting') > -1 || data.feature.code.indexOf('SendAfter') > -1) {
                         if (data.feature.status === false) {
                              featureSupports.forEach((item) => {
                                   item.features = item.features.filter((feature) => feature.code !== data.feature.code);
                              });
                         } else {
                              featureSupports.forEach((item, index) => {
                                   if (initialEvents[index].status === true) {
                                        if (data.feature.code.indexOf('SendAfter') > -1) {
                                             if (item.featureCode.indexOf('Submitted') > -1) {
                                                  item.features.push({
                                                       code: data.feature.code,
                                                       id: data.feature.featureId,
                                                       name: 'Push Events After Redirect',
                                                       status: false,
                                                       type: 'support',
                                                  });
                                             }
                                        } else if (data.feature.code.indexOf('EventReporting') > -1) {
                                             item.features.push({
                                                  code: data.feature.code,
                                                  id: data.feature.featureId,
                                                  name: 'Event Reporting',
                                                  status: true,
                                                  type: 'reporting',
                                             });
                                        }
                                   }
                              });
                         }
                    } else {
                         let pos = initialEvents.map((item) => item.featureId).indexOf(data.feature.featureId);
                         if (pos > -1) {
                              let editedPos = listenerEvents.editedEvents.map((item) => item.id).indexOf(initialEvents[pos].id);
                              if (editedPos > -1) {
                                   initialEvents[pos].friendlyName = listenerEvents.editedEvents[editedPos].friendlyName;
                                   if (listenerEvents.editedEvents[editedPos].friendlyName !== '') {
                                        listenerEvents.eventsStatus[pos] = true;
                                   }
                                   listenerEvents.editedEvents.splice(editedPos, 1);
                              }
                              initialEvents[pos] = {
                                   ...initialEvents[pos],
                                   status: data.feature.status,
                                   updated_at: data.feature.updated_at,
                                   show: !initialEvents[pos].show,
                                   accountFeatureId: feature.accountFeatureId,
                              };
                              featureSupports[pos].features = JSON.parse(data.feature.featureSupport).sort((a, b) => a.name - b.name);
                         }
                    }
                    // Video type
                    if (data.feature.code.indexOf('Progress') > -1 && data.feature.status === true) {
                         let videoProgressFeatureSupports = JSON.parse(data.feature.featureSupport);
                         videoProgressFeatureSupports.forEach((feature) => {
                              if (typeof feature.video !== 'undefined') {
                                   videoProgresses = {
                                        listenerId: data.listenerId,
                                        editedThresholds: [],
                                        addedThresholds: [],
                                        initialThresholds: feature.video.map((item, index) => {
                                             return { id: `addedThreshold${index + 1}`, value: item };
                                        }),
                                        fieldStatus: feature.video.map((_) => true),
                                   };
                              }
                         });
                    }

                    listenerEvents = {
                         ...listenerEvents,
                         initialEvents,
                         featureSupports,
                    };
               }

               listenerEvents.initialEvents = [...listenerEvents.initialEvents].map((event) => {
                    if (event.code === feature.code) {
                         event = { ...event, ...feature, show: feature.status };
                    }
                    return event;
               });
          }

          if (featureClick.status) {
               newData.newActiveFeatures.push(featureClick);
          } else {
               activeFeatures.some((feature, index) => {
                    if (feature.id === featureClick.id) {
                         newData.newActiveFeatures.splice(index, 1);
                         return true;
                    }

                    return false;
               });
          }

          if (isPremium) {
               if (featureClick.status) {
                    newData.activeAccount.numberOfPremium += 1;
               } else {
                    newData.activeAccount.numberOfPremium -= 1;
               }
          }

          if (strToCompare === 'usersource') {
               newRoutes = getNewFeatureRoutes({
                    path: SUBSCRIBER_PATH.USER_SOURCE_RULES,
                    currentRoutes: routes,
                    featureStatus: featureClick.status,
                    activeListener,
               });
          } else if (strToCompare.includes('myvalues') || strToCompare.includes('customvalues')) {
               newRoutes = getNewFeatureRoutes({
                    path: SUBSCRIBER_PATH.CUSTOM_VARIABLE_RULES,
                    currentRoutes: routes,
                    featureStatus: featureClick.status,
                    activeListener,
               });
          } else if (strToCompare.includes('browserstorage')) {
               newRoutes = getNewFeatureRoutes({
                    path: SUBSCRIBER_PATH.BROWSER_STORAGE,
                    currentRoutes: routes,
                    featureStatus: featureClick.status,
                    activeListener,
               });
          } else if (strToCompare.includes('monitoring')) {
               newRoutes = getNewFeatureRoutes({
                    path: SUBSCRIBER_PATH.MONITORING,
                    currentRoutes: routes,
                    featureStatus: featureClick.status,
                    activeListener,
               });
          } else if (strToCompare.includes('reporting')) {
               newRoutes = getNewFeatureRoutes({
                    path: SUBSCRIBER_PATH.REPORTING,
                    currentRoutes: routes,
                    featureStatus: featureClick.status,
                    activeListener,
               });
          } else if (strToCompare.includes('populate')) {
               newRoutes = getNewFeatureRoutes({
                    path: SUBSCRIBER_PATH.POPULATE_FIELD,
                    currentRoutes: routes,
                    featureStatus: featureClick.status,
                    activeListener,
               });
          } else if (strToCompare.includes('selector')) {
               newRoutes = getNewFeatureRoutes({
                    path: SUBSCRIBER_PATH.SELECTOR_RULES,
                    currentRoutes: routes,
                    featureStatus: featureClick.status,
                    activeListener,
               });

               // Disable Selector feature -> Remove all selectors from My Selector Values
               // -> Reset selector options in redux to make it fetch again
               newSelectorOptions = {
                    listenerId: '',
                    variables: [],
               };
          } else if (LOWERCASE_CLICK_FEATURES.includes(strToCompare)) {
               newRoutes = getNewFeatureRoutes({
                    path: SUBSCRIBER_PATH.CLICK_SETTINGS,
                    currentRoutes: routes,
                    featureStatus: featureClick.status,
                    activeListener,
               });
          }

          let returnObj = {
               activeAccount: newData.activeAccount,
               features: newData.newFeatures,
               activeFeatures: newData.newActiveFeatures,
               routes: newRoutes || routes,
               listenerEvents,
               videoProgresses,
          };

          if (newSelectorOptions) {
               returnObj.selectorOptions = newSelectorOptions;
          }

          // If disabling one of 3 native browser features
          if (
               !featureClick.status &&
               Array.isArray(nativeBrowserStorage.initialEvents) &&
               nativeBrowserStorage.initialEvents.length > 0 &&
               ['nativeSessionStorage', 'nativeLocalStorage', 'nativeCookie'].includes(feature.code)
          ) {
               let newInitialEvents;

               switch (feature.code) {
                    case 'nativeSessionStorage':
                         newInitialEvents = nativeBrowserStorage.initialEvents.filter((el) => el.type !== 'session storage');
                         break;
                    case 'nativeLocalStorage':
                         newInitialEvents = nativeBrowserStorage.initialEvents.filter((el) => el.type !== 'local storage');
                         break;
                    case 'nativeCookie':
                         newInitialEvents = nativeBrowserStorage.initialEvents.filter((el) => el.type !== 'cookie');
                         break;
                    default:
               }

               if (newInitialEvents.length === 0) {
                    returnObj.nativeBrowserStorage = {
                         listenerId: nativeBrowserStorage.activeListenerId,
                         initialEvents: [{ id: 'newEvent1', name: '', type: '', format: '', valueChange: true }],
                         addedEvents: [{ id: 'newEvent1', name: '', type: '', format: '', valueChange: true }],
                         editedEvents: [],
                         eventsStatus: [false],
                    };
               } else {
                    returnObj.nativeBrowserStorage = {
                         ...nativeBrowserStorage,
                         initialEvents: newInitialEvents,
                         eventsStatus: newInitialEvents.map((el) => (el.nativeId ? true : false)),
                    };
               }
          }

          return returnObj;
     },
     changeChildFeatureStatus: (state, data) => {
          const { activeListeners } = state;
          const { listenerId } = data;
          const activeListener = activeListeners.find((listener) => listener.id === listenerId);
          let { listenerEvents } = state;
          if (activeListener) {
               const eventSettingPathName = `${activeListener.alias}/${SUBSCRIBER_PATH.EVENT_SETTINGS}`;
               if (window.location.pathname.includes(eventSettingPathName)) {
                    let { initialEvents, featureSupports } = listenerEvents;
                    if (data.feature.code.indexOf('EventReporting') > -1 || data.feature.code.indexOf('SendAfter') > -1) {
                         if (data.feature.status === false) {
                              featureSupports.forEach((item) => {
                                   item.features = item.features.filter((feature) => feature.code !== data.feature.code);
                              });
                         } else {
                              featureSupports.forEach((item, index) => {
                                   if (initialEvents[index].status === true) {
                                        if (data.feature.code.indexOf('SendAfter') > -1) {
                                             if (item.featureCode.indexOf('Submitted') > -1) {
                                                  item.features.push({
                                                       code: data.feature.code,
                                                       id: data.feature.featureId,
                                                       name: 'Push Events After Redirect',
                                                       status: false,
                                                       type: 'support',
                                                  });
                                             }
                                        } else if (data.feature.code.indexOf('EventReporting') > -1) {
                                             item.features.push({
                                                  code: data.feature.code,
                                                  id: data.feature.featureId,
                                                  name: 'Event Reporting',
                                                  status: true,
                                                  type: 'reporting',
                                             });
                                        }
                                   }
                              });
                         }
                    } else {
                         let pos = initialEvents.map((item) => item.featureId).indexOf(data.feature.featureId);
                         if (pos > -1) {
                              let editedPos = listenerEvents.editedEvents.map((item) => item.id).indexOf(initialEvents[pos].id);
                              if (editedPos > -1) {
                                   initialEvents[pos].friendlyName = listenerEvents.editedEvents[editedPos].friendlyName;
                                   if (listenerEvents.editedEvents[editedPos].friendlyName !== '') {
                                        listenerEvents.eventsStatus[pos] = true;
                                   }
                                   listenerEvents.editedEvents.splice(editedPos, 1);
                              }
                              initialEvents[pos] = {
                                   ...initialEvents[pos],
                                   status: data.feature.status,
                                   updated_at: data.feature.updated_at,
                                   show: !initialEvents[pos].show,
                              };
                              featureSupports[pos].features = JSON.parse(data.feature.featureSupport).sort((a, b) => a.name - b.name);
                         }
                    }

                    listenerEvents = {
                         ...listenerEvents,
                         initialEvents,
                         featureSupports,
                    };
               }
          }

          return { listenerEvents };
     },
     addRule: (state, data) => {
          const { activeAccount, activeListeners, customVariableRules, browserRules, monitoringRules, userSourceRules, clickRules } = state;
          const { rule, listenerId } = data;
          const activeListener = activeListeners.find((listener) => listener.id === listenerId);
          let newActiveAccount = { ...activeAccount };

          switch (rule.group) {
               case 'rule': // Custom values rule
                    newActiveAccount.numberOfCusVariablesRules += 1;
                    break;
               case 'browser':
                    newActiveAccount.numberOfCusBrowserRules += 1;
                    break;
               case 'monitoring':
                    newActiveAccount.numberOfEventMonitor += 1;
                    break;
               // case 'populate':
               //      newActiveAccount.numberOfEventPopulate += 1;
               //      break;
               default:
          }

          let returnValues = { activeAccount: newActiveAccount };

          if (activeListener) {
               const handleReturnValues = ({ slug, ruleName, rules, shouldUnshiftArray = false }) => {
                    let rulePathName = '';
                    let newRules = [];
                    rulePathName = `${activeListener.alias}/${slug}`;
                    if (window.location.pathname.includes(rulePathName)) {
                         newRules = [...rules];

                         if (shouldUnshiftArray) {
                              newRules.unshift(rule);
                         } else {
                              newRules.push(rule);
                         }

                         returnValues[ruleName] = newRules;
                    }
               };

               switch (rule.group) {
                    case 'rule': // Custom values rule
                         handleReturnValues({
                              slug: SUBSCRIBER_PATH.CUSTOM_VARIABLE_RULES,
                              ruleName: 'customVariableRules',
                              rules: customVariableRules,
                         });
                         break;
                    case 'browser':
                         handleReturnValues({ slug: SUBSCRIBER_PATH.BROWSER_STORAGE, ruleName: 'browserRules', rules: browserRules });
                         break;
                    case 'monitoring':
                         handleReturnValues({ slug: SUBSCRIBER_PATH.MONITORING, ruleName: 'monitoringRules', rules: monitoringRules });
                         break;
                    case 'userSource':
                         handleReturnValues({
                              slug: SUBSCRIBER_PATH.USER_SOURCE_RULES,
                              ruleName: 'userSourceRules',
                              rules: userSourceRules,
                              shouldUnshiftArray: true,
                         });
                         break;
                    case 'click':
                         handleReturnValues({
                              slug: SUBSCRIBER_PATH.CLICK_SETTINGS,
                              ruleName: 'clickRules',
                              rules: clickRules,
                              shouldUnshiftArray: true,
                         });
                         break;
                    default:
               }
          }

          return returnValues;
     },
     consentRulesCreated: (state, data) => {
          const { consentRules } = state;
          const { rule: ruleChanged, action } = data;

          let newRules = { ...consentRules };

          switch (action) {
               case 'added':
                    newRules.push(ruleChanged);

                    break;
               case 'updateStatus':
                    newRules.some((rule) => {
                         if (rule.id === ruleChanged.id) {
                              rule.status = ruleChanged.status;
                              return true;
                         }
                         return false;
                    });

                    break;
               case 'updateRuleDefault':
                    newRules.some((rule) => {
                         if (rule.id === ruleChanged.key) {
                              rule.id = ruleChanged.id;
                              return true;
                         }
                         return false;
                    });

                    break;
               case 'updateRule':
                    newRules.some((rule) => {
                         if (rule.id === ruleChanged.id) {
                              rule.name = ruleChanged.name;
                              rule.description = ruleChanged.description;

                              return true;
                         }
                         return false;
                    });

                    break;
               case 'deleteRule':
                    const newRulesCustom = newRules.filter((rule) => rule.id !== ruleChanged.id);
                    newRules = { ...newRules, rulesCustom: newRulesCustom };
                    break;
               case 'resetRule':
                    newRules = ruleChanged;
                    break;
               case 'orderRule':
                    newRules = ruleChanged;
                    break;
               default:
                    break;
          }

          return { consentRules: newRules };
     },
     updateRule: (state, data) => {
          // const { activeListeners, customVariableRules } = state;
          // const { rule: ruleChanged } = data;
          // const activeListener = activeListeners.find(listener => listener.id === ruleChanged.listenerId);

          // if (activeListener) {
          //      const rulePathName = `${activeListener.alias}/custom-variable-rules/`;
          //      if (window.location.pathname.includes(rulePathName)) {
          //           let newRules = customVariableRules.filter(rule => rule.id !== ruleChanged.id);
          //           newRules.unshift(ruleChanged);

          //           return { customVariableRules: newRules };
          //      }
          // }

          return {};
     },
     updateRuleStatus: (state, data) => {
          const { activeListeners, customVariableRules, browserRules, monitoringRules, userSourceRules, clickRules } = state;
          const { rule: ruleChanged } = data;
          const activeListener = activeListeners.find((listener) => listener.id === ruleChanged.listenerId);
          let returnValues = {};

          if (activeListener) {
               const handleReturnValues = ({ slug, ruleName, rules }) => {
                    const rulePathName = `${activeListener.alias}/${slug}`;
                    if (window.location.pathname.includes(rulePathName)) {
                         let newRules = [...rules];
                         newRules.forEach((rule) => {
                              if (rule.id === ruleChanged.id) {
                                   rule.status = ruleChanged.status;
                              }
                         });

                         returnValues[ruleName] = newRules;
                    }
               };

               switch (ruleChanged.group) {
                    case 'rule': // Custom values rule
                         handleReturnValues({
                              slug: SUBSCRIBER_PATH.CUSTOM_VARIABLE_RULES,
                              ruleName: 'customVariableRules',
                              rules: customVariableRules,
                         });
                         break;
                    case 'browser':
                         handleReturnValues({ slug: SUBSCRIBER_PATH.BROWSER_STORAGE, ruleName: 'browserRules', rules: browserRules });
                         break;
                    case 'monitoring':
                         handleReturnValues({ slug: SUBSCRIBER_PATH.MONITORING, ruleName: 'monitoringRules', rules: monitoringRules });
                         break;
                    case 'userSource':
                         handleReturnValues({ slug: SUBSCRIBER_PATH.USER_SOURCE_RULES, ruleName: 'userSourceRules', rules: userSourceRules });
                         break;
                    case 'click':
                         handleReturnValues({ slug: SUBSCRIBER_PATH.CLICK_SETTINGS, ruleName: 'clickRules', rules: clickRules });
                         break;
                    default:
               }
          }

          return returnValues;
     },
     removeRule: (state, data) => {
          const { activeAccount, activeListeners, customVariableRules, browserRules, monitoringRules, userSourceRules, clickRules } = state;
          const { ruleId, ruleGroup, listenerId } = data;
          const activeListener = activeListeners.find((listener) => listener.id === listenerId);
          let newActiveAccount = { ...activeAccount };

          switch (ruleGroup) {
               case 'rule': // Custom values rule
                    newActiveAccount.numberOfCusVariablesRules -= 1;
                    break;
               case 'browser':
                    newActiveAccount.numberOfCusBrowserRules -= 1;
                    break;
               case 'monitoring':
                    newActiveAccount.numberOfEventMonitor -= 1;
                    break;
               // case 'populate':
               //      newActiveAccount.numberOfEventPopulate -= 1;
               //      break;
               default:
          }

          let returnValues = { activeAccount: newActiveAccount };

          if (activeListener) {
               let rulesPathName = '';
               let rulePathName = '';
               let newRules = [];
               let ruleIndex = -1;

               const handleReturnValues = ({ slug, ruleName, rules }) => {
                    rulesPathName = `/${activeListener.alias}/${slug}`;
                    rulePathName = `${rulesPathName}/${ruleId}`;

                    if (window.location.pathname.includes(rulePathName)) {
                         let redirectRules = { status: true, to: rulesPathName };
                         toast.error('This rule has just been removed. You will be redirected to the list.');
                         returnValues.redirectRules = redirectRules;
                    }

                    if (window.location.pathname.includes(rulesPathName)) {
                         ruleIndex = findIndex(rules, { id: ruleId });

                         if (ruleIndex > -1) {
                              newRules = [...rules];
                              newRules.splice(ruleIndex, 1);
                              returnValues[ruleName] = newRules;
                         }
                    }
               };

               switch (ruleGroup) {
                    case 'rule': // Custom values rule
                         handleReturnValues({
                              slug: SUBSCRIBER_PATH.CUSTOM_VARIABLE_RULES,
                              ruleName: 'customVariableRules',
                              rules: customVariableRules,
                         });
                         break;
                    case 'browser':
                         handleReturnValues({ slug: SUBSCRIBER_PATH.BROWSER_STORAGE, ruleName: 'browserRules', rules: browserRules });
                         break;
                    case 'monitoring':
                         handleReturnValues({ slug: SUBSCRIBER_PATH.MONITORING, ruleName: 'monitoringRules', rules: monitoringRules });
                         break;
                    case 'userSource':
                         handleReturnValues({ slug: SUBSCRIBER_PATH.USER_SOURCE_RULES, ruleName: 'userSourceRules', rules: userSourceRules });
                         break;
                    case 'click':
                         handleReturnValues({ slug: SUBSCRIBER_PATH.CLICK_SETTINGS, ruleName: 'clickRules', rules: clickRules });
                         break;
                    default:
               }
          }

          return returnValues;
     },
     reorderRule: (state, data) => {
          const { activeListeners, customVariableRules, browserRules, editingRuleReload, activeAccount, userSourceRules, clickRules } = state;
          const { rulesOrder, group, listenerId } = data;
          const activeListener = activeListeners.find((listener) => listener.id === listenerId);
          let newActiveAccount = { ...activeAccount };
          let returnValues = { activeAccount: newActiveAccount };

          if (activeListener) {
               const handleReturnValues = ({ slug, ruleName, initialRuleName, rules }) => {
                    let rulePathName = `/${activeListener.alias}/${slug}`;
                    let newRules = [];
                    let ruleIdsAfterOrdering = [];

                    if (window.location.pathname.includes(rulePathName)) {
                         ruleIdsAfterOrdering = rulesOrder.map((rule) => rule.id);
                         newRules = [...rules];

                         newRules.forEach((rule) => {
                              let pos = ruleIdsAfterOrdering.indexOf(rule.id);
                              if (pos > -1) {
                                   rule.ruleOrder = rulesOrder[pos].ruleOrder;
                              }
                         });
                         newRules = newRules.sort((a, b) => (a.ruleOrder > b.ruleOrder ? 1 : -1));

                         returnValues[ruleName] = newRules;
                         returnValues[initialRuleName] = newRules;
                         returnValues.editingRuleReload = !editingRuleReload;
                    }
               };

               switch (group) {
                    case 'rule': // Custom values rule
                         handleReturnValues({
                              slug: SUBSCRIBER_PATH.CUSTOM_VARIABLE_RULES,
                              ruleName: 'customVariableRules',
                              initialRuleName: 'initialCustomVariableRules',
                              rules: customVariableRules,
                         });
                         break;
                    case 'browser':
                         handleReturnValues({
                              slug: SUBSCRIBER_PATH.BROWSER_STORAGE,
                              ruleName: 'browserRules',
                              initialRuleName: 'initialBrowserRules',
                              rules: browserRules,
                         });
                         break;
                    case 'userSource':
                         handleReturnValues({
                              slug: SUBSCRIBER_PATH.USER_SOURCE_RULES,
                              ruleName: 'userSourceRules',
                              initialRuleName: 'initialUserSourceRules',
                              rules: userSourceRules,
                         });
                         break;
                    case 'click':
                         handleReturnValues({
                              slug: SUBSCRIBER_PATH.CLICK_SETTINGS,
                              ruleName: 'clickRules',
                              initialRuleName: 'initialClickRules',
                              rules: clickRules,
                         });
                         break;
                    default:
               }
          }

          return returnValues;
     },
     changeAccountName: (accounts, account) => {
          const activeAccountIndex = findAccountIndex(accounts, account.id);
          let newAccounts = [...accounts];
          newAccounts[activeAccountIndex].name = account.name;
          return { accounts: newAccounts, activeAccount: { ...newAccounts[activeAccountIndex] } };
     },
     changeAccountTimestamps: (accounts, account) => {
          const activeAccountIndex = findAccountIndex(accounts, account.id);
          let newAccounts = [...accounts];
          newAccounts[activeAccountIndex].accountTimestamps = account.accountTimestamps;
          return { accounts: newAccounts, activeAccount: { ...newAccounts[activeAccountIndex] } };
     },
     upgradeOrDowngradeAccount: (accounts, data) => {
          // const { account, packageData } = data;
          // const activeAccountIndex = findAccountIndex(accounts, account.id);
          // let newAccounts = [...accounts];
          // const newAccount = setPackageToAccount(account, packageData, newAccounts[activeAccountIndex].isOwner);
          // newAccounts[activeAccountIndex] = { ...newAccount };

          // return { accounts: newAccounts, activeAccount: newAccount };

          window.location.reload();
     },
     saveCardToChange: (accounts, data) => {
          const { account, packageData } = data;
          const activeAccountIndex = findAccountIndex(accounts, account.id);
          let newAccounts = [...accounts];
          const newAccount = setPackageToAccount(account, packageData, newAccounts[activeAccountIndex].isOwner);
          newAccounts[activeAccountIndex] = { ...newAccount };

          return { accounts: newAccounts, activeAccount: newAccount };
     },
     invoiceFinalization: () => {
          const newAccountPastDue = {
               status: false,
               message: null,
          };
          return { accountPastDue: newAccountPastDue };
     },
     changeAccountEvents: (state, data) => {
          const { eventOptions, listenerEvents } = state;
          const { listEventAccountUpdated, wooCheckoutStep } = data;

          if (listEventAccountUpdated.length > 0) {
               let newEventOptions = { ...eventOptions },
                    newListenerEvents = { ...listenerEvents };

               if (listEventAccountUpdated[0].listenerId === eventOptions.listenerId) {
                    if (eventOptions.events.length === 0) {
                         newEventOptions.events = [...listEventAccountUpdated];
                    } else {
                         listEventAccountUpdated.forEach((eventAccountUpdated) => {
                              eventOptions.events.some((event, eventIndex) => {
                                   if (eventAccountUpdated.id === event.id) {
                                        if (eventAccountUpdated.status) {
                                             newEventOptions.events[eventIndex] = { ...event, ...eventAccountUpdated };
                                        } else {
                                             newEventOptions.events.splice(eventIndex, 1);
                                        }
                                        return true;
                                   } else {
                                        if (eventIndex === newEventOptions.events.length - 1 && eventAccountUpdated.status) {
                                             newEventOptions.events.push(eventAccountUpdated);
                                        }
                                   }
                                   return false;
                              });
                         });
                    }
               }

               if (listEventAccountUpdated[0].listenerId === listenerEvents.listenerId) {
                    if (listenerEvents.initialEvents.length === 0) {
                         newListenerEvents.initialEvents = [...listEventAccountUpdated];
                    } else {
                         listEventAccountUpdated.forEach((eventAccountUpdated) => {
                              newListenerEvents.initialEvents.some((event, eventIndex) => {
                                   if (eventAccountUpdated.id === event.id) {
                                        newListenerEvents.initialEvents[eventIndex] = { ...event, ...eventAccountUpdated };
                                        return true;
                                   }
                                   return false;
                              });
                         });

                         newListenerEvents.eventsStatus = newListenerEvents.initialEvents.map((item) => [item.customEventName, item.friendlyName]);
                         newListenerEvents.editedEvents = [];
                    }
               }

               if (typeof wooCheckoutStep !== 'undefined' && wooCheckoutStep.length > 0) {
                    newListenerEvents.initialEvents.forEach((event, eventIndex) => {
                         wooCheckoutStep.some((woo, wooIndex) => {
                              if (event.accountFeatureId === woo.accountFeatureId) {
                                   newListenerEvents.initialEvents[eventIndex].featureSupport = woo.featureSupport;
                                   return true;
                              }
                              return false;
                         });
                    });
               }

               return {
                    eventOptions: newEventOptions,
                    listenerEvents: newListenerEvents,
               };
          }

          return {};
     },
     changeAccountVariables: (state, data) => {
          const { variableOptions, listenerVariables, eventTableOfListeners, globalReporting } = state;
          const { listenerId } = data;
          const globalReportingPathName = `${SUBSCRIBER_PATH.GLOBAL_REPORTING}`;
          const returnObj = {};

          // If automatic variables of this listener has been changed
          if (listenerId === variableOptions.listenerId) {
               // Set listenerId of variableOptions to empty, so when a user navigates through steps of a rule,
               // it will fetch automatic variables for this listener again
               const { updatedVariables } = data;
               if (Array.isArray(updatedVariables)) {
                    let enabledVariables = updatedVariables.filter((variable) => variable.status === true && variable.hasChild === false);
                    let disabledVariables = updatedVariables.filter((variable) => variable.status === false && variable.hasChild === false);
                    let newVariableOptions = { ...variableOptions };
                    if (enabledVariables.length > 0) {
                         enabledVariables.forEach((enabledVariable) => {
                              let pos = newVariableOptions.variables.map((variable) => variable.id).indexOf(enabledVariable.id);
                              if (pos > -1) {
                                   newVariableOptions.variables[pos] = enabledVariable;
                              } else {
                                   newVariableOptions.variables.push(enabledVariable);
                              }
                         });
                         // newVariableOptions.variables = [...newVariableOptions.variables, ...enabledVariables];
                    }
                    if (disabledVariables.length > 0) {
                         disabledVariables.forEach((disabledVariable) => {
                              newVariableOptions.variables = newVariableOptions.variables.filter((variable) => variable.id !== disabledVariable.id);
                         });
                    }

                    returnObj.variableOptions = newVariableOptions;
               }
          }

          if (
               listenerId === listenerVariables.listenerId ||
               listenerId === variableOptions.listenerId ||
               window.location.pathname.includes(SUBSCRIBER_PATH.GOALS_WEB_TRIGGERS) ||
               window.location.pathname.includes(SUBSCRIBER_PATH.GOALS_WEB_CONVERSIONS)
          ) {
               // Set automaticVariableReload = true, so the user is in variable settings,
               // it will reload and fetch automatic variables again
               returnObj.automaticVariableReload = true;
          }

          if (window.location.pathname.includes(globalReportingPathName)) {
               if (typeof eventTableOfListeners.automaticVariables[listenerId] !== 'undefined') {
                    let newGlobalReporting = globalReporting;
                    newGlobalReporting.listenerNeedReloaded = listenerId;
                    returnObj.globalReporting = newGlobalReporting;
               }
          }

          // Hide automatic variable warning of rules of a listener
          returnObj.automaticVariableWarning = { listenerId, show: false };

          return returnObj;
     },
     createInvoices: (state, data) => {
          const { invoices } = state;
          let newInvoices = [...invoices];
          if (data.invoices.length > 0) {
               data.invoices.forEach((invoice) => {
                    const invoiceExists = invoices.find((invoiceCheck) => invoiceCheck.id === invoice.id);
                    if (!invoiceExists) {
                         newInvoices.unshift(invoice);
                    }
               });
          }
          return { invoices: newInvoices };
     },
     updateInvoice: (state, data) => {
          const { invoices } = state;
          let newInvoices = [];
          if (data.invoice) {
               const pusherInvoice = data.invoice;
               invoices.map((invoice) => {
                    if (invoice.id === pusherInvoice.id) {
                         if (
                              !['Extra Events', 'Extra Users', 'Extra Visitors'].includes(pusherInvoice.type) ||
                              pusherInvoice.amount > 0 ||
                              pusherInvoice.status === 'Upcoming' ||
                              (typeof pusherInvoice.costItems !== 'undefined' &&
                                   pusherInvoice.costItems.filter((costItem) => costItem.cost !== 0).length)
                         ) {
                              newInvoices.push({ ...invoice, ...pusherInvoice });
                         }
                    } else {
                         newInvoices.push(invoice);
                    }
                    return true;
               });
               return { invoices: newInvoices };
          } else {
               return { invoices };
          }
     },
     updateAccount: (state, data) => {
          const accountUpdated = data.account;
          const { accounts, activeAccount } = state;

          let newListAccounts = [];
          const activeAccountUpdated = { ...activeAccount, ...accountUpdated };

          accounts.map((account) => {
               if (account.id === accountUpdated.id) {
                    newListAccounts.push({ ...account, ...accountUpdated });
               } else {
                    newListAccounts.push(account);
               }
               return true;
          });

          return { accounts: newListAccounts, activeAccount: activeAccountUpdated };
     },
     updateFeatureSupportChild: (state, data) => {
          const { listenerEvents, events } = state;
          // For Listener Event Settings
          let newListenerEvents = cloneDeep(listenerEvents);

          if (data.listenerId === newListenerEvents.listenerId) {
               let pos = newListenerEvents.featureSupports.map((feature) => feature.featureId).indexOf(data.featureId);
               let newFeatureSupports = JSON.parse(data.featureSupport);

               if (pos > -1) {
                    newListenerEvents.initialEvents[pos].featureSupport = data.featureSupport;
                    newListenerEvents.featureSupports[pos].features = newFeatureSupports;
               }

               if (newListenerEvents.editedEvents.length > 0) {
                    pos = newListenerEvents.editedEvents.map((event) => event.featureId).indexOf(data.featureId);
                    if (pos > -1) {
                         newListenerEvents.editedEvents[pos].featureSupport = data.featureSupport;
                    }
               }
          }

          // For updating Event Reporting
          // const dataFeatureSupport = JSON.parse(data.featureSupport);
          // let newEvents = { ...events };
          // if (data.listenerId === newEvents.listenerId) {
          //      const featureReporting = dataFeatureSupport.find(feature => feature.name === 'Event Reporting');
          //      if (featureReporting) {
          //           if (newEvents.listEvents.find(event => event.value === data.featureId)) {
          //                if (!featureReporting.status) {
          //                     newEvents.listEvents = newEvents.listEvents.filter(event => event.value !== data.featureId);
          //                }
          //           } else {
          //                if (featureReporting.status) {
          //                     newEvents.listEvents.push({ value: data.featureId, label: data.featureCode });
          //                }
          //           }
          //      }
          // }
          // END EVENT REPORTING
          return { listenerEvents: newListenerEvents, events: events };
     },
     updateFeatureSupportVideoProgresses: (state, data) => {
          const { videoProgresses } = state;
          if (videoProgresses.listenerId === data.listenerId) {
               let { initialThresholds, editedThresholds, fieldStatus } = cloneDeep(videoProgresses);
               if (data.removeIndex > -1) {
                    let removeItem = initialThresholds.splice(data.removeIndex, 1);
                    editedThresholds = editedThresholds.filter((threshold) => threshold.id !== removeItem.id);
                    fieldStatus.splice(data.removeIndex, 1);

                    if (initialThresholds.length > 0) {
                         return {
                              videoProgresses: {
                                   ...videoProgresses,
                                   initialThresholds,
                                   editedThresholds,
                                   fieldStatus,
                              },
                         };
                    } else {
                         return {
                              videoProgresses: {
                                   ...videoProgresses,
                                   initialThresholds: [{ id: 'newThreshold1', value: '' }],
                                   addedThreshold: [{ id: 'newThreshold1', value: '' }],
                                   editedThresholds: [],
                                   fieldStatus: [false],
                              },
                         };
                    }
               }
          }
     },
     resetFeatureSupport: (state, data) => {
          const { feature, configSupport } = data;

          if (configSupport.length > 0) {
               if (feature.code.includes('Engagement')) {
                    configSupport.forEach((featureConfig) => {
                         if (typeof featureConfig.video !== 'undefined') {
                              return { videoEngagements: featureConfig.video };
                         }
                    });
               } else if (feature.code.includes('Progress')) {
                    const { videoProgresses } = state;
                    let newVideoProgresses = { ...videoProgresses };

                    configSupport.forEach((featureConfig) => {
                         if (typeof featureConfig.video !== 'undefined') {
                              let newInitialThresholds = [];
                              let newFieldStatus = [];

                              featureConfig.video.forEach((threshold, index) => {
                                   newInitialThresholds.push({ id: `addedThreshold${index + 1}`, value: threshold });
                                   newFieldStatus.push(true);
                              });
                              newVideoProgresses.initialThresholds = newInitialThresholds;
                              newVideoProgresses.fieldStatus = newFieldStatus;
                         }
                    });
                    return { videoProgresses: newVideoProgresses };
               } else if (feature.code === 'userSource') {
                    const { acrossDomains } = state;
                    let newAcrossDomains = { ...acrossDomains };

                    configSupport.forEach((featureConfig) => {
                         if (typeof featureConfig.acrossDomains !== 'undefined') {
                              let newInitialDomains = [];
                              let newFieldStatus = [];

                              featureConfig.acrossDomains.forEach((domain, index) => {
                                   newInitialDomains.push({ id: `addedDomain${index + 1}`, value: domain });
                                   newFieldStatus.push(true);
                              });
                              newAcrossDomains.initialDomains = newInitialDomains;
                              newAcrossDomains.fieldStatus = newFieldStatus;
                         }
                    });
                    return { acrossDomains: newAcrossDomains };
               }
          }
          return {};
     },
     increaseNumberOfEvents: (state) => {
          const { activeAccount, accountPastDue } = state;
          let newActiveAccount = { ...activeAccount };
          let newAccountPastDue = { ...accountPastDue };
          let showBlockAccountPopup = false;
          newActiveAccount.numberOfEventsPerMonth += 1;
          if (
               newActiveAccount.packageVersion === 1 &&
               newActiveAccount.packagePricePerMonth === 0 &&
               newActiveAccount.numberOfEventsPerMonth >= newActiveAccount.packageNumberOfEventsPerMonth
          ) {
               newActiveAccount.isBlocked = true;
               newAccountPastDue.status = true;
               newAccountPastDue.message =
                    'Your account is paused because you are over your event limit. Please upgrade in order to turn your data layer activity back on.';
               newAccountPastDue.title = 'Over Event Limit';
               newAccountPastDue.buttonText = 'Upgrade Now';
               newAccountPastDue.function = 'upgradeAccount';
               showBlockAccountPopup = true;
          }
          return { activeAccount: newActiveAccount, accountPastDue: newAccountPastDue, showBlockAccountPopup };
     },
     resetNumberOfEvents: (activeAccount) => {
          let newActiveAccount = { ...activeAccount };
          newActiveAccount.numberOfEventsPerMonth = 0;
          newActiveAccount.numberOfVisitorsPerMonth = 0;
          newActiveAccount.numberOfSessionsPerMonth = 0;
          newActiveAccount.isBillingSessionReset = 0;
          return newActiveAccount;
     },
     changeEventChart: (state, data) => {
          let { activeAccount, eventChart, showEventReportingWarning } = state;
          let { chartData, listenerId, keysConcept, selected, filter } = eventChart;
          let { charts } = chartData;
          const { timeZone } = activeAccount;

          const increaseChartEventBy1 = ({ today = '', isCustomHourly, increaseTheLastElement }) => {
               keysConcept.forEach((key, keyIndex) => {
                    if (key.value === 'all' || key.value === data.featureId) {
                         let chart = charts[keyIndex];
                         let chartLength = chart.length;
                         showEventReportingWarning = false; // Hide event warning inside reporting of the matching listener

                         // If we are increasing event by 1 for chart which is selecting a date range and filter by "hourly"
                         if (isCustomHourly) {
                              // today <= chart[chartLength - 1].date, it means the last 24 elements of this "chart" variable
                              // is 24 hours of today
                              if (today <= chart[chartLength - 1].date) {
                                   // Find that date of chart in only 24 elements(24 hours) of today to save time from finding it
                                   for (let i = chartLength - 1; i >= chartLength - 24; i--) {
                                        if (chart[i].date === today) {
                                             // Found and increase the number of events of that date by 1
                                             chart[i].number = parseInt(chart[i].number) + 1;
                                             break;
                                        }
                                   }
                              }
                         } else if (today === chart[chartLength - 1].date || increaseTheLastElement) {
                              // Increase the last element of chart by 1
                              chart[chartLength - 1].number = parseInt(chart[chartLength - 1].number) + 1;
                         }
                    }
               });
          };

          const doUpdate = () => {
               switch (selected) {
                    case 'nthDay':
                         let thisDay = timeZone ? dayjs().tz(timeZone).format('ddd, MMM DD, YYYY') : dayjs().format('ddd, MMM DD, YYYY');
                         increaseChartEventBy1({ today: thisDay });
                         break;
                    case 'nthWeek':
                         let thisDayOfWeek = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY') : dayjs().format('MMM D, YYYY');
                         let startOfThisWeek = timeZone
                              ? dayjs().tz(timeZone).startOf('week').format('MMM D, YYYY')
                              : dayjs().startOf('week').format('MMM D, YYYY');

                         if (
                              filter === 'custom' &&
                              charts[0] &&
                              charts[0][0] &&
                              charts[0][0].date &&
                              typeof charts[0][0].date === 'string' &&
                              charts[0][0].date.endsWith(thisDayOfWeek)
                         ) {
                              // This only happens when filtering choose date range from someday to today and weekly option
                              increaseChartEventBy1({ increaseTheLastElement: true });
                         } else {
                              increaseChartEventBy1({ today: `${startOfThisWeek} - ${thisDayOfWeek}` });
                         }
                         break;
                    case 'nthMonth':
                         let thisDayOfMonth = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY') : dayjs().format('MMM D, YYYY');
                         let startOfThisMonth = timeZone
                              ? dayjs().tz(timeZone).startOf('month').format('MMM D, YYYY')
                              : dayjs().startOf('month').format('MMM D, YYYY');

                         if (
                              filter === 'custom' &&
                              charts[0] &&
                              charts[0][0] &&
                              charts[0][0].date &&
                              typeof charts[0][0].date === 'string' &&
                              charts[0][0].date.endsWith(thisDayOfMonth)
                         ) {
                              // This only happens when filtering choose date range from someday to today and monthly option
                              increaseChartEventBy1({ increaseTheLastElement: true });
                         } else {
                              increaseChartEventBy1({ today: `${startOfThisMonth} - ${thisDayOfMonth}` });
                         }

                         break;
                    case 'nthHour':
                         let thisTime = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY HH:00') : dayjs().format('MMM D, YYYY HH:00');
                         increaseChartEventBy1({ today: thisTime, isCustomHourly: filter === 'custom' });
                         break;
                    default:
                         break;
               }
          };

          if (chartData.charts.length > 0) {
               if (listenerId !== '') {
                    if (listenerId === data.listenerId) {
                         doUpdate();
                    }
               } else {
                    doUpdate();
               }
          }

          const dataReturn = {
               eventChart: { ...eventChart, chartData },
               showEventReportingWarning,
          };

          return dataReturn;
     },
     changeNumberOfVisitorChart: (state, data) => {
          let { activeAccount, eventChart, eventTable, eventTableOfListeners, activeListeners } = state;
          let { chartData, keysConcept, selected, filter } = eventChart;
          let { visitors, visitorsStart } = chartData;
          let increamentTable = false;
          const { timeZone } = activeAccount;
          const keyConceptValue = keysConcept.map((key) => key.value).join('');

          const { listenerId, featureId, visitorId, incrementCountListener, incrementCountFeature, incrementAllEvents, isVisitorStart } = data;

          const increaseChartEventBy1 = ({ today = '', isCustomHourly, increaseTheLastElement }) => {
               let chart = isVisitorStart ? visitorsStart : visitors;
               let chartLength = chart.length;

               // If we are increasing event by 1 for chart which is selecting a date range and filter by "hourly"
               if (isCustomHourly) {
                    // today <= chart[chartLength - 1].date, it means the last 24 elements of this "chart" variable
                    // is 24 hours of today
                    if (today <= chart[chartLength - 1].date) {
                         // Find that date of chart in only 24 elements(24 hours) of today to save time from finding it
                         for (let i = chartLength - 1; i >= chartLength - 24; i--) {
                              if (chart[i].date === today) {
                                   // Found and increase the number of events of that date by 1
                                   chart[i].number = parseInt(chart[i].number) + 1;
                                   break;
                              }
                         }
                    }
               } else if (today === chart[chartLength - 1].date || increaseTheLastElement) {
                    // Increase the last element of chart by 1
                    chart[chartLength - 1].number = parseInt(chart[chartLength - 1].number) + 1;
               }
          };

          const doUpdate = () => {
               switch (selected) {
                    case 'nthDay':
                         let thisDay = timeZone ? dayjs().tz(timeZone).format('ddd, MMM DD, YYYY') : dayjs().format('ddd, MMM DD, YYYY');

                         if (
                              (keyConceptValue.includes('all') && incrementAllEvents && incrementAllEvents.daily) ||
                              (eventChart.listenerId && keyConceptValue.includes('all') && incrementCountListener && incrementCountListener.daily) ||
                              (keyConceptValue === featureId && incrementCountFeature && incrementCountFeature.daily)
                         ) {
                              increaseChartEventBy1({ today: thisDay });
                         }
                         if (featureId && incrementCountFeature && incrementCountFeature.daily) {
                              thisDay = dayjs().tz(timeZone).format(DEFAULT_DATE_FORMAT);
                              if (
                                   (eventTable.startDay <= thisDay && thisDay <= eventTable.endDay) ||
                                   (eventTableOfListeners.startDay <= thisDay && thisDay <= eventTableOfListeners.endDay)
                              ) {
                                   increamentTable = true;
                              }
                         }
                         break;
                    case 'nthWeek':
                         if (
                              (keyConceptValue.includes('all') && incrementAllEvents && incrementAllEvents.weekly) ||
                              (eventChart.listenerId && keyConceptValue.includes('all') && incrementCountListener && incrementCountListener.weekly) ||
                              (keyConceptValue === featureId && incrementCountFeature && incrementCountFeature.weekly)
                         ) {
                              let thisDayOfWeek = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY') : dayjs().format('MMM D, YYYY');
                              let startOfThisWeek = timeZone
                                   ? dayjs().tz(timeZone).startOf('week').format('MMM D, YYYY')
                                   : dayjs().startOf('week').format('MMM D, YYYY');

                              if (
                                   filter === 'custom' &&
                                   typeof visitors[visitors.length - 1].date === 'string' &&
                                   visitors[visitors.length - 1].date.endsWith(thisDayOfWeek)
                              ) {
                                   // This only happens when filtering choose date range from someday to today and weekly option
                                   increaseChartEventBy1({ increaseTheLastElement: true });
                              } else {
                                   increaseChartEventBy1({ today: `${startOfThisWeek} - ${thisDayOfWeek}` });
                              }
                         }
                         break;
                    case 'nthMonth':
                         if (
                              (keyConceptValue.includes('all') && incrementAllEvents && incrementAllEvents.monthly) ||
                              (eventChart.listenerId &&
                                   keyConceptValue.includes('all') &&
                                   incrementCountListener &&
                                   incrementCountListener.monthly) ||
                              (keyConceptValue === featureId && incrementCountFeature && incrementCountFeature.monthly)
                         ) {
                              let thisDayOfMonth = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY') : dayjs().format('MMM D, YYYY');
                              let startOfThisMonth = timeZone
                                   ? dayjs().tz(timeZone).startOf('month').format('MMM D, YYYY')
                                   : dayjs().startOf('month').format('MMM D, YYYY');

                              if (
                                   filter === 'custom' &&
                                   typeof visitors[visitors.length - 1].date === 'string' &&
                                   visitors[visitors.length - 1].date.endsWith(thisDayOfMonth)
                              ) {
                                   // This only happens when filtering choose date range from someday to today and monthly option
                                   increaseChartEventBy1({ increaseTheLastElement: true });
                              } else {
                                   increaseChartEventBy1({ today: `${startOfThisMonth} - ${thisDayOfMonth}` });
                              }
                         }
                         break;
                    case 'nthHour':
                         if (
                              (keyConceptValue.includes('all') && incrementAllEvents && incrementAllEvents.hourly) ||
                              (eventChart.listenerId && keyConceptValue.includes('all') && incrementCountListener && incrementCountListener.hourly) ||
                              (keyConceptValue === featureId && incrementCountFeature && incrementCountFeature.hourly)
                         ) {
                              let thisTime = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY HH:00') : dayjs().format('MMM D, YYYY HH:00');

                              increaseChartEventBy1({ today: thisTime, isCustomHourly: filter === 'custom' });
                         }
                         break;
                    default:
                         break;
               }
          };

          const doUpdateVisitorsStart = () => {
               switch (selected) {
                    case 'nthDay':
                         if (isVisitorStart) {
                              let thisDay = timeZone ? dayjs().tz(timeZone).format('ddd, MMM DD, YYYY') : dayjs().format('ddd, MMM DD, YYYY');

                              increaseChartEventBy1({ today: thisDay });
                         }
                         break;
                    case 'nthWeek':
                         if (isVisitorStart) {
                              let thisDayOfWeek = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY') : dayjs().format('MMM D, YYYY');
                              let startOfThisWeek = timeZone
                                   ? dayjs().tz(timeZone).startOf('week').format('MMM D, YYYY')
                                   : dayjs().startOf('week').format('MMM D, YYYY');

                              if (
                                   filter === 'custom' &&
                                   visitors[visitors.length - 1].date &&
                                   typeof visitors[visitors.length - 1].date === 'string' &&
                                   visitors[visitors.length - 1].date.endsWith(thisDayOfWeek)
                              ) {
                                   // This only happens when filtering choose date range from someday to today and weekly option
                                   increaseChartEventBy1({ increaseTheLastElement: true });
                              } else {
                                   increaseChartEventBy1({ today: `${startOfThisWeek} - ${thisDayOfWeek}` });
                              }
                         }
                         break;
                    case 'nthMonth':
                         if (isVisitorStart) {
                              let thisDayOfMonth = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY') : dayjs().format('MMM D, YYYY');
                              let startOfThisMonth = timeZone
                                   ? dayjs().tz(timeZone).startOf('month').format('MMM D, YYYY')
                                   : dayjs().startOf('month').format('MMM D, YYYY');

                              if (
                                   filter === 'custom' &&
                                   typeof visitors[visitors.length - 1].date === 'string' &&
                                   visitors[visitors.length - 1].date.endsWith(thisDayOfMonth)
                              ) {
                                   // This only happens when filtering choose date range from someday to today and monthly option
                                   increaseChartEventBy1({ increaseTheLastElement: true });
                              } else {
                                   increaseChartEventBy1({ today: `${startOfThisMonth} - ${thisDayOfMonth}` });
                              }
                         }
                         break;
                    case 'nthHour':
                         if (isVisitorStart) {
                              let thisTime = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY HH:00') : dayjs().format('MMM D, YYYY HH:00');

                              increaseChartEventBy1({ today: thisTime, isCustomHourly: filter === 'custom' });
                         }
                         break;
                    default:
                         break;
               }
          };

          const increaseEventTableVisitors = () => {
               const activeListener = activeListeners.find((listener) => listener.id === data.listenerId);
               let listenerReportingPathName = null;

               if (activeListener) {
                    listenerReportingPathName = `${activeListener.alias}/${SUBSCRIBER_PATH.REPORTING}`;
               }

               if (window.location.pathname.includes(listenerReportingPathName)) {
                    let newEventTable = { ...eventTable };
                    let eventOfFeatureCount = newEventTable.countData[data.featureId];

                    if (eventOfFeatureCount) {
                         if (eventOfFeatureCount.numberOfVisitors) {
                              eventOfFeatureCount.numberOfVisitors += 1;
                         } else {
                              eventOfFeatureCount.numberOfVisitors = 1;
                         }
                    }

                    // Check if new visitor not existed -> increase numberOfVisitors +1
                    if (visitorId) {
                         const existed = newEventTable.visitorsUnique.find((sid) => sid === visitorId);

                         if (!existed) {
                              newEventTable.countData.total.numberOfVisitors += 1;
                              newEventTable.visitorsUnique.push(visitorId);
                         }
                    }

                    return newEventTable;
               }
               return null;
          };

          const increaseEventTableOfListenerVisitors = () => {
               const globalReportingPathName = `${SUBSCRIBER_PATH.GLOBAL_REPORTING}`;

               if (window.location.pathname.includes(globalReportingPathName)) {
                    let newEventTableOfListeners = { ...eventTableOfListeners };
                    let eventOfFeatureCount = newEventTableOfListeners.countData[data.featureId];

                    if (eventOfFeatureCount) {
                         if (eventOfFeatureCount.numberOfVisitors) {
                              eventOfFeatureCount.numberOfVisitors += 1;
                         } else {
                              eventOfFeatureCount.numberOfVisitors = 1;
                         }
                    }

                    // Check if new visitor not existed -> increase numberOfVisitors +1
                    if (visitorId) {
                         const existed = newEventTableOfListeners.visitorsUnique.find((sid) => sid === visitorId);

                         if (!existed) {
                              newEventTableOfListeners.countData.total.numberOfVisitors += 1;
                              newEventTableOfListeners.visitorsUnique.push(visitorId);
                         }
                    }

                    return newEventTableOfListeners;
               }
          };

          if (visitors.length > 0 || visitorsStart.length > 0) {
               if (eventChart.listenerId !== '') {
                    if (eventChart.listenerId === listenerId) {
                         doUpdate();
                    }
               } else {
                    doUpdate();
               }
               doUpdateVisitorsStart();
          }

          let dataReturn = {
               eventChart: { ...eventChart, chartData },
          };

          if (incrementAllEvents && incrementAllEvents.monthly) {
               let newActiveAccount = { ...activeAccount };

               dataReturn.activeAccount = newActiveAccount;
          }

          if (increamentTable) {
               const increaseEventTable = increaseEventTableVisitors();

               if (increaseEventTable) {
                    dataReturn.eventTable = increaseEventTable;
               }

               const increaseEventTableOfListener = increaseEventTableOfListenerVisitors();

               if (increaseEventTableOfListener) {
                    dataReturn.eventTableOfListeners = increaseEventTableOfListener;
               }
          }

          return dataReturn;
     },
     changeNumberOfSessionChart: (state, data) => {
          let { activeAccount, eventChart, eventTable, eventTableOfListeners, activeListeners } = state;
          let { chartData, keysConcept, selected, filter } = eventChart;
          let { sessions, sessionsStart } = chartData;
          let increamentTable = false;
          const { timeZone } = activeAccount;

          const { listenerId, featureId, sessionId, incrementCountListener, incrementCountFeature, incrementAllEvents, isSessionStart } = data;

          const keyConceptValue = keysConcept.map((key) => key.value).join('');

          const increaseChartEventBy1 = ({ today = '', isCustomHourly, increaseTheLastElement }) => {
               let chart = isSessionStart ? sessionsStart : sessions;
               let chartLength = chart.length;

               // If we are increasing event by 1 for chart which is selecting a date range and filter by "hourly"
               if (isCustomHourly) {
                    // today <= chart[chartLength - 1].date, it means the last 24 elements of this "chart" variable
                    // is 24 hours of today
                    if (today <= chart[chartLength - 1].date) {
                         // Find that date of chart in only 24 elements(24 hours) of today to save time from finding it
                         for (let i = chartLength - 1; i >= chartLength - 24; i--) {
                              if (chart[i].date === today) {
                                   // Found and increase the number of events of that date by 1
                                   chart[i].number = parseInt(chart[i].number) + 1;
                                   break;
                              }
                         }
                    }
               } else if (today === chart[chartLength - 1].date || increaseTheLastElement) {
                    // Increase the last element of chart by 1
                    chart[chartLength - 1].number = parseInt(chart[chartLength - 1].number) + 1;
               }
          };

          const doUpdate = () => {
               switch (selected) {
                    case 'nthDay':
                         let thisDay = timeZone ? dayjs().tz(timeZone).format('ddd, MMM DD, YYYY') : dayjs().format('ddd, MMM DD, YYYY');

                         if (
                              (keyConceptValue.includes('all') && incrementAllEvents && incrementAllEvents.daily) ||
                              (eventChart.listenerId && keyConceptValue.includes('all') && incrementCountListener && incrementCountListener.daily) ||
                              (keyConceptValue === featureId && incrementCountFeature && incrementCountFeature.daily)
                         ) {
                              increaseChartEventBy1({ today: thisDay });
                         }

                         if (featureId && incrementCountFeature && incrementCountFeature.daily) {
                              thisDay = dayjs().tz(timeZone).format(DEFAULT_DATE_FORMAT);
                              if (
                                   (eventTable.startDay <= thisDay && thisDay <= eventTable.endDay) ||
                                   (eventTableOfListeners.startDay <= thisDay && thisDay <= eventTableOfListeners.endDay)
                              ) {
                                   increamentTable = true;
                              }
                         }
                         break;
                    case 'nthWeek':
                         let thisDayOfWeek = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY') : dayjs().format('MMM D, YYYY');
                         let startOfThisWeek = timeZone
                              ? dayjs().tz(timeZone).startOf('week').format('MMM D, YYYY')
                              : dayjs().startOf('week').format('MMM D, YYYY');
                         if (
                              (keyConceptValue.includes('all') && incrementAllEvents && incrementAllEvents.weekly) ||
                              (eventChart.listenerId && keyConceptValue.includes('all') && incrementCountListener && incrementCountListener.weekly) ||
                              (keyConceptValue === featureId && incrementCountFeature && incrementCountFeature.weekly)
                         ) {
                              if (
                                   filter === 'custom' &&
                                   sessions[sessions.length - 1].date &&
                                   typeof sessions[sessions.length - 1].date === 'string' &&
                                   sessions[sessions.length - 1].date.endsWith(thisDayOfWeek)
                              ) {
                                   // This only happens when filtering choose date range from someday to today and weekly option
                                   increaseChartEventBy1({ increaseTheLastElement: true });
                              } else {
                                   increaseChartEventBy1({ today: `${startOfThisWeek} - ${thisDayOfWeek}` });
                              }
                         }
                         // if ((featureId && incrementCountFeature && incrementCountFeature.weekly)) {
                         //      if (filter === 'custom' &&
                         //           sessions[sessions.length - 1].date &&
                         //           typeof sessions[sessions.length - 1].date === 'string' && sessions[sessions.length - 1].date.endsWith(thisDayOfWeek)
                         //      ) {
                         //           // This only happens when filtering choose date range from someday to today and weekly option
                         //           increamentTable = true;
                         //      } else {
                         //           increamentTable = true;
                         //      }
                         // }
                         break;
                    case 'nthMonth':
                         if (
                              (keyConceptValue.includes('all') && incrementAllEvents && incrementAllEvents.monthly) ||
                              (eventChart.listenerId &&
                                   keyConceptValue.includes('all') &&
                                   incrementCountListener &&
                                   incrementCountListener.monthly) ||
                              (keyConceptValue === featureId && incrementCountFeature && incrementCountFeature.monthly)
                         ) {
                              let thisDayOfMonth = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY') : dayjs().format('MMM D, YYYY');
                              let startOfThisMonth = timeZone
                                   ? dayjs().tz(timeZone).startOf('month').format('MMM D, YYYY')
                                   : dayjs().startOf('month').format('MMM D, YYYY');

                              if (
                                   filter === 'custom' &&
                                   typeof sessions[sessions.length - 1].date === 'string' &&
                                   sessions[sessions.length - 1].date.endsWith(thisDayOfMonth)
                              ) {
                                   // This only happens when filtering choose date range from someday to today and monthly option
                                   increaseChartEventBy1({ increaseTheLastElement: true });
                              } else {
                                   increaseChartEventBy1({ today: `${startOfThisMonth} - ${thisDayOfMonth}` });
                              }
                         }
                         break;
                    case 'nthHour':
                         if (
                              (keyConceptValue.includes('all') && incrementAllEvents && incrementAllEvents.hourly) ||
                              (eventChart.listenerId && keyConceptValue.includes('all') && incrementCountListener && incrementCountListener.hourly) ||
                              (keyConceptValue === featureId && incrementCountFeature && incrementCountFeature.hourly)
                         ) {
                              let thisTime = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY HH:00') : dayjs().format('MMM D, YYYY HH:00');

                              increaseChartEventBy1({ today: thisTime, isCustomHourly: filter === 'custom' });
                         }
                         break;
                    default:
                         break;
               }
          };

          const doUpdateSessionStart = () => {
               switch (selected) {
                    case 'nthDay':
                         if (isSessionStart) {
                              let thisDay = timeZone ? dayjs().tz(timeZone).format('ddd, MMM DD, YYYY') : dayjs().format('ddd, MMM DD, YYYY');

                              increaseChartEventBy1({ today: thisDay });
                         }
                         break;
                    case 'nthWeek':
                         if (isSessionStart) {
                              let thisDayOfWeek = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY') : dayjs().format('MMM D, YYYY');
                              let startOfThisWeek = timeZone
                                   ? dayjs().tz(timeZone).startOf('week').format('MMM D, YYYY')
                                   : dayjs().startOf('week').format('MMM D, YYYY');

                              if (
                                   filter === 'custom' &&
                                   sessions[sessions.length - 1].date &&
                                   typeof sessions[sessions.length - 1].date === 'string' &&
                                   sessions[sessions.length - 1].date.endsWith(thisDayOfWeek)
                              ) {
                                   // This only happens when filtering choose date range from someday to today and weekly option
                                   increaseChartEventBy1({ increaseTheLastElement: true });
                              } else {
                                   increaseChartEventBy1({ today: `${startOfThisWeek} - ${thisDayOfWeek}` });
                              }
                         }
                         break;
                    case 'nthMonth':
                         if (isSessionStart) {
                              let thisDayOfMonth = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY') : dayjs().format('MMM D, YYYY');
                              let startOfThisMonth = timeZone
                                   ? dayjs().tz(timeZone).startOf('month').format('MMM D, YYYY')
                                   : dayjs().startOf('month').format('MMM D, YYYY');

                              if (
                                   filter === 'custom' &&
                                   typeof sessions[sessions.length - 1].date === 'string' &&
                                   sessions[sessions.length - 1].date.endsWith(thisDayOfMonth)
                              ) {
                                   // This only happens when filtering choose date range from someday to today and monthly option
                                   increaseChartEventBy1({ increaseTheLastElement: true });
                              } else {
                                   increaseChartEventBy1({ today: `${startOfThisMonth} - ${thisDayOfMonth}` });
                              }
                         }
                         break;
                    case 'nthHour':
                         if (isSessionStart) {
                              let thisTime = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY HH:00') : dayjs().format('MMM D, YYYY HH:00');

                              increaseChartEventBy1({ today: thisTime, isCustomHourly: filter === 'custom' });
                         }
                         break;
                    default:
                         break;
               }
          };

          if (sessions.length > 0 || sessionsStart.length > 0) {
               if (eventChart.listenerId !== '') {
                    if (eventChart.listenerId === listenerId) {
                         doUpdate();
                    }
               } else {
                    doUpdate();
               }

               doUpdateSessionStart();
          }

          const increaseEventTableSession = () => {
               const activeListener = activeListeners.find((listener) => listener.id === data.listenerId);
               let listenerReportingPathName = null;

               if (activeListener) {
                    listenerReportingPathName = `${activeListener.alias}/${SUBSCRIBER_PATH.REPORTING}`;
               }

               if (window.location.pathname.includes(listenerReportingPathName)) {
                    let newEventTable = { ...eventTable };
                    let eventOfFeatureCount = newEventTable.countData[featureId];

                    if (eventOfFeatureCount) {
                         if (eventOfFeatureCount.numberOfSessions) {
                              eventOfFeatureCount.numberOfSessions += 1;
                         } else {
                              eventOfFeatureCount.numberOfSessions = 1;
                         }
                    }

                    // Check if new visitor not existed -> increase numberOfSessions +1
                    if (sessionId) {
                         const existed = newEventTable.sessionsUnique.find((sid) => sid === sessionId);

                         if (!existed) {
                              newEventTable.countData.total.numberOfSessions += 1;
                              newEventTable.sessionsUnique.push(sessionId);
                         }
                    }

                    return newEventTable;
               }
               return null;
          };

          const increaseEventTableOfListenerSession = () => {
               const globalReportingPathName = `${SUBSCRIBER_PATH.GLOBAL_REPORTING}`;

               if (window.location.pathname.includes(globalReportingPathName)) {
                    let newEventTableOfListeners = { ...eventTableOfListeners };
                    let eventOfFeatureCount = newEventTableOfListeners.countData[featureId];

                    if (eventOfFeatureCount) {
                         if (eventOfFeatureCount.numberOfSessions) {
                              eventOfFeatureCount.numberOfSessions += 1;
                         } else {
                              eventOfFeatureCount.numberOfSessions = 1;
                         }
                    }

                    // Check if new visitor not existed -> increase numberOfSessions +1
                    if (sessionId) {
                         const existed = newEventTableOfListeners.sessionsUnique.find((sid) => sid === sessionId);

                         if (!existed) {
                              newEventTableOfListeners.countData.total.numberOfSessions += 1;
                              newEventTableOfListeners.sessionsUnique.push(sessionId);
                         }
                    }

                    return newEventTableOfListeners;
               }

               return null;
          };

          let dataReturn = {
               eventChart: { ...eventChart, chartData },
          };

          if (incrementAllEvents && incrementAllEvents.daily) {
               let newActiveAccount = { ...activeAccount };

               // newActiveAccount.tempBillingSessionCount += 1;

               dataReturn.activeAccount = newActiveAccount;
          }

          if (increamentTable) {
               const increaseEventTable = increaseEventTableSession();

               if (increaseEventTable) {
                    dataReturn.eventTable = increaseEventTable;
               }

               const increaseEventTableOfListener = increaseEventTableOfListenerSession();

               if (increaseEventTableOfListener) {
                    dataReturn.eventTableOfListeners = increaseEventTableOfListener;
               }
          }

          return dataReturn;
     },
     setListRuleConditions: (state, data) => {
          const { listRuleConditions } = state;
          const { listenerId, ruleConditions } = data;
          let newListRuleConditions = listRuleConditions.filter((condition) => condition.listenerId !== listenerId);
          newListRuleConditions = [...newListRuleConditions, ...ruleConditions];
          return { listRuleConditions: newListRuleConditions };
     },
     updateListEvents: (state, data) => {
          const { events } = state;
          const { codes } = data;
          let options = [];
          codes.forEach((code) => {
               let option = { value: code.featureId, label: code.friendlyName || code.featureCode, listenerId: code.listenerId };
               option.reporting = false;
               if (typeof code.featureSupport === 'string') {
                    const featureSupport = JSON.parse(code.featureSupport);
                    featureSupport.forEach((feature) => {
                         if (feature.type === 'reporting' && feature.status === true) {
                              option.reporting = true;
                         }
                    });
               }
               options.push(option);
          });
          let newListEvents = { ...events };
          newListEvents.listEvents = options;
          return { events: newListEvents };
     },
     updateListDatalayerVariables: (state, data) => {
          const { listenerVariables } = state;
          const { initialVariables } = listenerVariables;
          const { datalayerVariables } = data;
          let newListenerVariables = listenerVariables;
          let newInitialVariables = initialVariables;
          let newDatalayerVariables = datalayerVariables;
          newInitialVariables.forEach((variable) => {
               if (datalayerVariables.filter((datalayerVariable) => datalayerVariable.code === variable.code).length > 0) {
                    datalayerVariables.forEach((datalayerVariable, indexDatalayerVariable) => {
                         if (datalayerVariable.code === variable.code) {
                              variable = datalayerVariable;
                              newDatalayerVariables.splice(indexDatalayerVariable, 1);
                         }
                    });
               }
          });
          newInitialVariables = [...newInitialVariables, ...newDatalayerVariables];
          newListenerVariables.initialVariables = newInitialVariables;
          return { listenerVariables: newListenerVariables };
     },
     updateUserManagerInfo: (state, data) => {
          const { managers } = state;
          let newManagers = [...managers];
          if (newManagers.length > 0) {
               newManagers.forEach((manager, index) => {
                    if (manager.email === data.email) {
                         newManagers[index] = { ...manager, ...data };
                    }
               });
          }
          return { managers: newManagers };
     },
     updateEventTable: (state, data) => {
          const { eventTable, eventTableOfListeners, activeListeners, listenerVariables } = state;
          const activeListener = activeListeners.find((listener) => listener.id === data.listenerId);
          const listenerReportingPathName = activeListener && `${activeListener.alias}/${SUBSCRIBER_PATH.REPORTING}`;
          const globalReportingPathName = `${SUBSCRIBER_PATH.GLOBAL_REPORTING}`;

          if (window.location.pathname.includes(listenerReportingPathName)) {
               if (eventTable.startDay <= data.eventDate && data.eventDate <= eventTable.endDay) {
                    let returnObj = {};
                    let newEventTable = { ...eventTable };
                    let eventOfFeatureCount = newEventTable.countData[data.featureId];

                    if (eventOfFeatureCount) {
                         // There's data for this feature
                         data.variableIds.forEach((variableId) => {
                              if (eventOfFeatureCount[variableId] && eventOfFeatureCount[variableId].number) {
                                   eventOfFeatureCount[variableId].number += 1;
                              } else {
                                   eventOfFeatureCount[variableId] = { number: 1 };
                              }
                         });

                         if (data.increaseFeatureCount) {
                              eventOfFeatureCount.number += 1;
                         }
                    } else {
                         // There's no data for this feature
                         newEventTable.countData[data.featureId] = {
                              number: 1,
                              numberOfSessions: 0,
                              numberOfVisitors: 0,
                         };

                         data.variableIds.forEach((variableId) => {
                              newEventTable.countData[data.featureId][variableId] = { number: 1 };
                         });
                    }

                    if (data.increaseFeatureCount) {
                         newEventTable.countData.total.number += 1;
                    }

                    returnObj.eventTable = newEventTable;

                    // Automatic Variables + 1 -> Automatic Variables > 1 -> There are automatic variable -> Fetch new automatic variables for this listener
                    if (
                         listenerVariables.listenerId === data.listenerId &&
                         (!listenerVariables.initialVariables || listenerVariables.initialVariables.length === 0)
                    ) {
                         returnObj.automaticVariableReload = true;
                    }

                    return returnObj;
               }
          }

          if (window.location.pathname.includes(globalReportingPathName)) {
               if (eventTableOfListeners.startDay <= data.eventDate && data.eventDate <= eventTableOfListeners.endDay) {
                    let newEventTableOfListeners = { ...eventTableOfListeners };
                    let eventOfFeatureCount = newEventTableOfListeners.countData[data.featureId];

                    if (eventOfFeatureCount) {
                         // There's data for this feature
                         data.variableIds.forEach((variableId) => {
                              if (eventOfFeatureCount[variableId] && eventOfFeatureCount[variableId].number) {
                                   eventOfFeatureCount[variableId].number += 1;
                              } else {
                                   eventOfFeatureCount[variableId] = { number: 1 };
                              }
                         });

                         if (data.increaseFeatureCount) {
                              eventOfFeatureCount.number += 1;
                         }
                    } else {
                         // There's no data for this feature
                         newEventTableOfListeners.countData[data.featureId] = {
                              number: 1,
                              numberOfSessions: 0,
                              numberOfVisitors: 0,
                         };

                         data.variableIds.forEach((variableId) => {
                              newEventTableOfListeners.countData[data.featureId][variableId] = { number: 1 };
                         });
                    }

                    if (data.increaseFeatureCount) {
                         newEventTableOfListeners.countData.total.number += 1;
                    }

                    return { eventTableOfListeners: newEventTableOfListeners };
               }
          }

          return {};
     },
     updateVariableValues: (state, data) => {
          const { eventTable, eventTableOfListeners, variableValueChart, activeAccount, activeListeners } = state;
          const activeListener = activeListeners.find((listener) => listener.id === data.listenerId);
          const listenerReportingPathName = `${activeListener.alias}/${SUBSCRIBER_PATH.REPORTING}`;
          const globalReportingPathName = `${SUBSCRIBER_PATH.GLOBAL_REPORTING}`;
          let { chartData, keysConcept, selected, filter } = variableValueChart;
          let { charts } = chartData;
          const { timeZone } = activeAccount;
          let returnObj = {};

          const getNewVariableReportingValues = (variableReportingValues = {}) => {
               let newVariableReportingValues = { ...variableReportingValues };

               if (!newVariableReportingValues[data.featureId]) {
                    // There's no data for this feature
                    newVariableReportingValues[data.featureId] = {};
               }

               // Define a new variable has the same reference, so when any props of
               // eventOfFeatureCount is changed => matched props of newVariableReportingValues[data.featureId] is also changed
               let eventOfFeatureCount = newVariableReportingValues[data.featureId];

               data.variableValues.forEach((variableValue) => {
                    let autoCustomSelectorId = variableValue.aId || variableValue.cId || variableValue.sId;
                    // Define a new variable has the same reference, so when any props of
                    // autoCustomSelectorValue is changed => matched props of eventOfFeatureCount[autoCustomSelectorId] is also changed
                    let autoCustomSelectorValue = eventOfFeatureCount[autoCustomSelectorId];

                    // aId: Automatic variable ID, cId: Custom variable ID
                    if (Array.isArray(autoCustomSelectorValue) && autoCustomSelectorValue.length > 0) {
                         let foundVariableValueIndex = findIndex(autoCustomSelectorValue, { variableValueId: variableValue.id });

                         if (foundVariableValueIndex > -1) {
                              // Found
                              // Increase number by 1
                              autoCustomSelectorValue[foundVariableValueIndex].number += 1;
                         } else {
                              // Not found
                              // Push new variable value to the array
                              autoCustomSelectorValue.push({
                                   automaticVariableId: variableValue.aId,
                                   customVariableId: variableValue.cId,
                                   selectorVariableId: variableValue.sId,
                                   featureId: data.featureId,
                                   number: 1,
                                   variableValue: variableValue.v,
                                   variableValueId: variableValue.id,
                              });
                         }
                    } else {
                         eventOfFeatureCount[autoCustomSelectorId] = [
                              {
                                   automaticVariableId: variableValue.aId,
                                   customVariableId: variableValue.cId,
                                   selectorVariableId: variableValue.sId,
                                   featureId: data.featureId,
                                   number: 1,
                                   variableValue: variableValue.v,
                                   variableValueId: variableValue.id,
                              },
                         ];
                    }
               });

               return newVariableReportingValues;
          };

          if (window.location.pathname.includes(listenerReportingPathName)) {
               if (eventTable.startDay <= data.eventDate && data.eventDate <= eventTable.endDay) {
                    let newVariableReportingValues = getNewVariableReportingValues(eventTable.variableReportingValues);

                    returnObj.eventTable = { ...eventTable, variableReportingValues: newVariableReportingValues };
               }
          } else if (window.location.pathname.includes(globalReportingPathName)) {
               if (eventTableOfListeners.startDay <= data.eventDate && data.eventDate <= eventTableOfListeners.endDay) {
                    let newVariableReportingValues = getNewVariableReportingValues(eventTableOfListeners.variableReportingValues);

                    returnObj.eventTableOfListeners = { ...eventTableOfListeners, variableReportingValues: newVariableReportingValues };
               }
          }

          // Change variableValueChart
          if (keysConcept.length > 0) {
               const increaseChartEventBy1 = ({ keyIndex, today = '', isCustomHourly, increaseTheLastElement }) => {
                    let chart = charts[keyIndex];
                    let chartLength = chart.length;

                    // If we are increasing event by 1 for chart which is selecting a date range and filter by "hourly"
                    if (isCustomHourly) {
                         // today <= chart[chartLength - 1].date, it means the last 24 elements of this "chart" variable
                         // is 24 hours of today
                         if (today <= chart[chartLength - 1].date) {
                              // Find that date of chart in only 24 elements(24 hours) of today to save time from finding it
                              for (let i = chartLength - 1; i >= chartLength - 24; i--) {
                                   if (chart[i].date === today) {
                                        // Found and increase the number of events of that date by 1
                                        chart[i].number = parseInt(chart[i].number) + 1;
                                        break;
                                   }
                              }
                         }
                    } else if (today === chart[chartLength - 1].date || increaseTheLastElement) {
                         // Increase the last element of chart by 1
                         chart[chartLength - 1].number = parseInt(chart[chartLength - 1].number) + 1;
                    }
               };

               const doUpdate = (keyIndex) => {
                    switch (selected) {
                         case 'nthDay':
                              let thisDay = timeZone ? dayjs().tz(timeZone).format('ddd, MMM DD, YYYY') : dayjs().format('ddd, MMM DD, YYYY');
                              increaseChartEventBy1({ keyIndex, today: thisDay });
                              break;
                         case 'nthWeek':
                              let thisDayOfWeek = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY') : dayjs().format('MMM D, YYYY');
                              let startOfThisWeek = timeZone
                                   ? dayjs().tz(timeZone).startOf('week').format('MMM D, YYYY')
                                   : dayjs().startOf('week').format('MMM D, YYYY');

                              if (
                                   filter === 'custom' &&
                                   charts[0] &&
                                   charts[0][0] &&
                                   charts[0][0].date &&
                                   typeof charts[0][0].date === 'string' &&
                                   charts[0][0].date.endsWith(thisDayOfWeek)
                              ) {
                                   // This only happens when filtering choose date range from someday to today and weekly option
                                   increaseChartEventBy1({ keyIndex, increaseTheLastElement: true });
                              } else {
                                   increaseChartEventBy1({ keyIndex, today: `${startOfThisWeek} - ${thisDayOfWeek}` });
                              }
                              break;
                         case 'nthMonth':
                              let thisDayOfMonth = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY') : dayjs().format('MMM D, YYYY');
                              let startOfThisMonth = timeZone
                                   ? dayjs().tz(timeZone).startOf('month').format('MMM D, YYYY')
                                   : dayjs().startOf('month').format('MMM D, YYYY');

                              if (
                                   filter === 'custom' &&
                                   charts[0] &&
                                   charts[0][0] &&
                                   charts[0][0].date &&
                                   typeof charts[0][0].date === 'string' &&
                                   charts[0][0].date.endsWith(thisDayOfMonth)
                              ) {
                                   // This only happens when filtering choose date range from someday to today and monthly option
                                   increaseChartEventBy1({ keyIndex, increaseTheLastElement: true });
                              } else {
                                   increaseChartEventBy1({ keyIndex, today: `${startOfThisMonth} - ${thisDayOfMonth}` });
                              }

                              break;
                         case 'nthHour':
                              let thisTime = timeZone ? dayjs().tz(timeZone).format('MMM D, YYYY HH:00') : dayjs().format('MMM D, YYYY HH:00');
                              increaseChartEventBy1({ keyIndex, today: thisTime, isCustomHourly: filter === 'custom' });
                              break;
                         default:
                              break;
                    }
               };

               let changed = false;

               keysConcept.forEach((kc, kcIndex) => {
                    let foundEl = data.variableValues.find((vv) => vv.id === kc);

                    if (foundEl) {
                         changed = true;
                         doUpdate(kcIndex);
                    }
               });

               if (changed) {
                    returnObj.variableValueChart = { ...variableValueChart, chartData };
               }
          }

          return returnObj;
     },
     updateSelectorVariables: (state, data) => {
          const { listenerSelectors, selectorOptions } = state;
          const { listenerId } = data;

          if (listenerId === listenerSelectors.listenerId || listenerId === selectorOptions.listenerId) {
               // Set automaticVariableReload = true, so the user is in variable settings,
               // it will reload and fetch automatic variables again
               return { selectorVariableReload: true };
          }

          return {};
     },
     updateSelectorValues: (state, data) => {
          if (state.selectorValues.listenerId === data.listenerId) {
               let featureSupport = tryParseJSON(data.selectorValues) || [];
               let newSelectorValues;

               if (featureSupport.length > 0) {
                    newSelectorValues = {
                         listenerId: data.listenerId,
                         initialSelectorValues: featureSupport.map((fs, index) => {
                              return { id: `addedSelector${index + 1}`, ...fs };
                         }),
                         addedSelectorValues: [],
                         editedSelectorValues: [],
                         selectorValuesStatus: featureSupport.map((_) => {
                              return { selector: true, variable: true };
                         }),
                    };
               } else {
                    newSelectorValues = {
                         listenerId: data.listenerId,
                         initialSelectorValues: [{ id: 'newSelector1', selector: '', variable: '' }],
                         addedSelectorValues: [{ id: 'newSelector1', selector: '', variable: '' }],
                         editedSelectorValues: [],
                         selectorValuesStatus: [{ selector: false, variable: false }],
                    };
               }

               return { selectorValues: newSelectorValues };
          }

          return {};
     },
     nativeBrowserStorageChange: (state, data) => {
          const { nativeBrowserAccountFeature } = data;
          const newPusherAccountFeatures = state.nativeBrowserAccountFeature.map((el) => {
               let foundEl = nativeBrowserAccountFeature.find((pusherAccountFeature) => pusherAccountFeature.id === el.accountFeatureId);

               if (foundEl) {
                    return { ...el, featureSupport: foundEl.featureSupport };
               }

               return el;
          });

          return { nativeBrowserAccountFeature: newPusherAccountFeatures };
     },
     addNewTicketComment: (state, data) => {
          const { comment, ticket, currentUserId } = data;
          const { userId, id } = ticket;
          let numberOfComment = state.numberOfCommentsTickets;

          numberOfComment = numberOfComment + 1;

          const pathName = SUBSCRIBER_MY_ACCOUNT_PATH.SUPPORT_TICKETS_WITH_PARAMS_ID.replace(':id', id);

          if (pathName === window.location.pathname) {
               if (state.ticket.id === id) {
                    let newComments = [...state.ticket.comments, { ...comment, cmtBy: 'support' }];

                    return { ticket: { ...state.ticket, comments: newComments } };
               }
          }

          if (currentUserId === userId) {
               let newTickets = [...state.myCreatedTickets],
                    newTicket = {};

               const flag = newTickets.some((ticket, index) => {
                    if (ticket.id === id) {
                         newTicket = ticket;
                         newTickets.splice(index, 1);
                         return true;
                    }
                    return false;
               });

               if (flag) {
                    newTicket.numCmtByCs = newTicket.numCmtByCs + 1;
                    newTicket.status = 'Response';
               } else {
                    newTicket = ticket;
                    newTicket.status = 'Response';
                    newTicket.numCmtByCs = ticket.numCmtByCs + 1;
               }

               newTickets.unshift(newTicket);
               return { numberOfCommentsTickets: numberOfComment, myCreatedTickets: newTickets };
          } else {
               let newOtherTickets = [...state.otherTickets],
                    newTicket = {};

               const flag = newOtherTickets.some((ticket, index) => {
                    if (ticket.id === id) {
                         newTicket = ticket;
                         newOtherTickets.splice(index, 1);
                         return true;
                    }
                    return false;
               });

               if (flag) {
                    newTicket.numCmtByCs += 1;
                    newTicket.status = 'Response';
               } else {
                    newTicket = ticket;
                    newTicket.status = 'Response';
                    newTicket.numCmtByCs = ticket.numCmtByCs + 1;
               }
               newOtherTickets.unshift(newTicket);
               return { numberOfCommentsTickets: numberOfComment, otherTickets: newOtherTickets };
          }
     },
     resetCmtNumberOfClient: (state, data) => {
          const { ticket } = data;

          let numberOfComment = state.numberOfCommentsTickets;

          let newTickets = [...state.myCreatedTickets],
               newOtherTickets = [...state.otherTickets];
          const flag = newTickets.some((_ticket) => {
               if (_ticket.id === ticket.id) {
                    _ticket.numCmtByCs = 0;
                    _ticket.status = 'Open';
                    return true;
               }
               return false;
          });

          if (flag) {
               return { numberOfCommentsTickets: numberOfComment - ticket.numCmtByCs, myCreatedTickets: newTickets };
          } else {
               const flag2 = newOtherTickets.some((_ticket) => {
                    if (_ticket.id === ticket.id) {
                         _ticket.numCmtByCs = 0;
                         _ticket.status = 'Open';
                         return true;
                    }
                    return false;
               });

               if (flag2) {
                    return { numberOfCommentsTickets: numberOfComment - ticket.numCmtByCs, otherTickets: newOtherTickets };
               }
          }
     },
     reloadOfficeHourCalendar: (state, data) => {
          const { reloadOfficeHourCalendar, officeHourCalendar } = state;

          if (officeHourCalendar.timeZone) {
               return {
                    reloadOfficeHourCalendar: !reloadOfficeHourCalendar,
                    officeHourCalendar: {
                         timeZone: '',
                         freeHoursOfDates: [],
                    },
               };
          }

          return {};
     },
     // updateSessionLength: (state, data) => {
     //      const { listenerEvents } = state;
     //      const { accountFeatureId, newFeatureSupport } = data;

     //      const sessionLengthIndex = listenerEvents.initialEvents.findIndex(el => el.accountFeatureId === accountFeatureId);

     //      if (sessionLengthIndex > -1) {
     //           let newListenerEvents = { ...listenerEvents };
     //           newListenerEvents.initialEvents[sessionLengthIndex].featureSupport = newFeatureSupport;

     //           return { listenerEvents: newListenerEvents };
     //      }

     //      return {};
     // },
     updatePreciseLocationConfigV2: (state, data) => {
          const { features } = state;

          const { newFeatureSupport } = data;

          const newFeatures = [...features];

          newFeatures.some((feature) => {
               if (feature.id === newFeatureSupport.id) {
                    feature.config = newFeatureSupport.config;
                    return true;
               }
               return false;
          });

          return { features: newFeatures };
     },
     updatePreciseLocationConfig: (state, data) => {
          const { listenerEvents } = state;

          const { newFeatureSupport } = data;

          let newListenerEvents = { ...listenerEvents };
          let dataUpdate = JSON.parse(newFeatureSupport);

          const geoLocationIndex = listenerEvents.featureSupports.findIndex((el) => el.featureCode === 'userGeolocation');

          newListenerEvents.featureSupports[geoLocationIndex].features = dataUpdate;

          return { listenerEvents: newListenerEvents };
     },
     updateListenerForRoutes: (state, data) => {
          const { listenerEvents } = state;
          const { newFeatureSupport } = data;

          let newListenerEvents = { ...listenerEvents };
          let dataUpdate = JSON.parse(newFeatureSupport);

          const listenerForRoutesIndex = listenerEvents.featureSupports.findIndex((el) => el.featureCode === 'pageView');

          newListenerEvents.featureSupports[listenerForRoutesIndex].features = dataUpdate;

          return { listenerEvents: newListenerEvents };
     },
     updatePersonalData: (state, data) => {
          return { initialPersonalData: data };
     },
     updateNotification: (state, data) => {
          const { monitoringNotifications } = state;
          let newNotifications = [...monitoringNotifications];

          if (newNotifications.filter((item) => item.id === data.id).length > 0) {
               newNotifications = newNotifications.map((item) => {
                    if (item.id === data.id) {
                         return data;
                    }
                    return item;
               });
          } else {
               newNotifications = [data, ...monitoringNotifications];
          }

          return { monitoringNotifications: newNotifications };
     },
     changeVisitorsAndSessionsPerMonth: (state, data) => {
          let newActiveAccount = { ...state.activeAccount };
          const { changeVisitor, changeSession } = data;

          if (changeVisitor) {
               newActiveAccount.numberOfVisitorsPerMonth += 1;
          }

          if (changeSession) {
               newActiveAccount.numberOfSessionsPerMonth += 1;
          }

          return { activeAccount: newActiveAccount };
     },
     increaseNumberOfFakeVisitorsBy1: (state) => {
          let newActiveAccount = { ...state.activeAccount };

          newActiveAccount.numberOfVisitorsPerMonth++;
          // newActiveAccount.tempNumberOfEvents -= 6;
          // newActiveAccount.tempNumberOfVisitors++;

          return { activeAccount: newActiveAccount };
     },
     updateAccountUser: (state, data) => {
          const accountUpdated = data.account;
          const { activeAccount } = state;
          const activeAccountUpdated = { ...activeAccount, ...accountUpdated };
          if (accountUpdated.userId === activeAccount.userId) {
               activeAccountUpdated.billingRight = accountUpdated.billingRight
               if (accountUpdated.isOwner) {
                    activeAccountUpdated.isOwner = accountUpdated.isOwner
               }
               return { activeAccount: activeAccountUpdated }
          }
     },
     updateSegment: (state, data) => {
          const { listSegment, listSegmentReport, listSegmentReload } = state;
          const { segmentId, status } = data;
          let newListSegment = [...listSegment];
          let newListSegmentReport = [...listSegmentReport];
          let newListSegmentReload = listSegmentReload;
          if (status === BQ_JOB_STATUS.DONE && listSegmentReport.find((item) => item.id === segmentId && item.apply)) {
               newListSegmentReload = !newListSegmentReload
          }
          if (Array.isArray(listSegment) && segmentId && status) {
               newListSegment = [...listSegment].map((item) => {
                    if (item.id === segmentId) {
                         item.queryStatus = status;
                    }
                    return item;
               });
          }
          if (Array.isArray(listSegmentReport) && segmentId && status) {
               newListSegmentReport = [...listSegmentReport].map((item) => {
                    if (item.id === segmentId) {
                         item.queryStatus = status;
                    }
                    return item;
               });
          }

          return { listSegment: newListSegment, listSegmentReport: newListSegmentReport, listSegmentReload: newListSegmentReload };
     },
};

export const getNameLogo = (code) => {
     let nameLogo = {
          logo: '',
          logoTinted: '',
     };

     switch (code) {
          case 'hubspotChat':
          case 'hubspotForms':
          case 'hubspotCTA':
               nameLogo.logo = 'icon-hubspot';
               nameLogo.logoTinted = 'icon-hubspotTinted';
               break;
          case 'gravityForms':
               nameLogo.logo = 'icon-gravityForm';
               nameLogo.logoTinted = 'icon-gravityFormTinted';
               break;
          case 'contactForm7':
               nameLogo.logo = 'icon-cf7';
               nameLogo.logoTinted = 'icon-cf7Tinted';
               break;
          case 'pardotForms':
               nameLogo.logo = 'icon-pardot';
               nameLogo.logoTinted = 'icon-pardotTinted';
               break;
          case 'marketoForms':
               nameLogo.logo = 'icon-marketoForm';
               nameLogo.logoTinted = 'icon-marketoFormTinted';
               break;
          case 'uberflip':
               nameLogo.logo = 'icon-uberflip';
               nameLogo.logoTinted = 'icon-uberflipTinted';
               break;
          case 'livechat':
               nameLogo.logo = 'icon-liveChat';
               nameLogo.logoTinted = 'icon-liveChatTinted';
               break;
          case 'vidyard':
               nameLogo.logo = 'icon-vidyard';
               nameLogo.logoTinted = 'icon-vidyardTinted';
               break;
          case 'drift':
               nameLogo.logo = 'icon-drift';
               nameLogo.logoTinted = 'icon-driftTinted';
               break;
          case 'vimeo':
               nameLogo.logo = 'icon-vimeo';
               nameLogo.logoTinted = 'icon-vimeoTinted';
               break;
          case 'mailchimp':
               nameLogo.logo = 'icon-mailChimpForm';
               nameLogo.logoTinted = 'icon-mailChimpFormTinted';
               break;
          case 'intercom':
               nameLogo.logo = 'icon-intercom';
               nameLogo.logoTinted = 'icon-intercomTinted';
               break;
          case 'drupalWebForm':
               nameLogo.logo = 'icon-drupal';
               nameLogo.logoTinted = 'icon-drupalTinted';
               break;
          case 'woocommerce':
               nameLogo.logo = 'icon-woocomerce';
               nameLogo.logoTinted = 'icon-woocomerceTinted';
               break;
          case 'unbounce':
               nameLogo.logo = 'icon-unbounce';
               nameLogo.logoTinted = 'icon-unbounceTinted';
               break;
          case 'angular':
               nameLogo.logo = 'icon-angular';
               nameLogo.logoTinted = 'icon-angularTinted';
               break;
          case 'reactJS':
               nameLogo.logo = 'icon-reactJS';
               nameLogo.logoTinted = 'icon-reactJSTinted';
               break;
          case 'wistia':
               nameLogo.logo = 'icon-wistia';
               nameLogo.logoTinted = 'icon-wistiaTinted';
               break;
          case 'acuityScheduling':
               nameLogo.logoTinted = 'icon-acuitySchedulingTinted';
               break;
          case 'activeCampaign':
          case 'activeCampaignChat':
               nameLogo.logoTinted = 'iconActiveCampaignTinted';
               break;
          case 'zoho':
               nameLogo.logoTinted = 'iconZohoTinted';
               break;
          case 'shopify':
               nameLogo.logoTinted = 'iconShopifyTinted';
               break;
          case 'fluentForms':
               nameLogo.logoTinted = 'iconFluentTinted';
               break;
          case 'customForms':
               nameLogo.logoTinted = 'iconCustomFormTinted';
               break;
          case 'userGeolocation':
               nameLogo.logoTinted = 'iconUserLocationTinted';
               break;
          case 'userWeather':
               nameLogo.logoTinted = 'iconUserWeatherTinted';
               break;
          case DESTINATION_CODE.LINKEDIN_ADS:
               nameLogo.logo = 'favicon';
               // nameLogo.logoTinted = 'cib-linkedin';
               nameLogo.logoTinted = 'icon-LinkedInTinted';
               break;
          case DESTINATION_CODE.MICROSOFT_ADS:
               nameLogo.logoTinted = 'icon-MicrosoftAdsTinted';
               break;
          case DESTINATION_CODE.FACEBOOK_ADS:
               nameLogo.logoTinted = 'cib-facebook';
               break;
          case DESTINATION_CODE.GOOGLE_ADS:
               nameLogo.logoTinted = 'icon-GoogleAdsTinted';
               break;
          case 'googleTagManager':
               nameLogo.logoTinted = 'icon-GTMTinted';
               break;
          case DESTINATION_CODE.GA4:
               nameLogo.logoTinted = 'icon-GoogleAnalyticsTinted';
               break;
          default:
               // nameLogo.logo = 'favicon';
               // nameLogo.logoTinted = 'cil-layers';
               break;
     }

     return nameLogo;
};

export const getListenerIconSrc = (code) => {
     switch (code) {
          case 'hubspotChat':
          case 'hubspotForms':
          case 'hubspotCTA':
          case 'hsMeetingScheduler':
               return {
                    iconColor: '/assets/icons/hubspot/icon-color.svg',
                    iconTinted: '/assets/icons/hubspot/icon-tinted.svg',
                    logo: '/assets/icons/hubspot/logo.svg',
               };
          case 'gravityForms':
               return {
                    iconColor: '/assets/icons/gravity-forms/icon-color.svg',
                    iconTinted: '/assets/icons/gravity-forms/icon-tinted.svg',
                    logo: '/assets/icons/gravity-forms/logo.svg',
               };
          case 'contactForm7':
               return {
                    iconColor: '/assets/icons/cf7/icon-color.svg',
                    iconTinted: '/assets/icons/cf7/icon-tinted.svg',
                    logo: '/assets/icons/cf7/logo.svg',
               };
          case 'pardotForms':
               return {
                    iconColor: '/assets/icons/pardot/icon-color.svg',
                    iconTinted: '/assets/icons/pardot/icon-tinted.svg',
                    logo: '/assets/icons/pardot/logo.svg',
               };
          case 'marketoForms':
               return {
                    iconColor: '/assets/icons/marketo/icon-color.svg',
                    iconTinted: '/assets/icons/marketo/icon-tinted.svg',
                    logo: '/assets/icons/marketo/logo.svg',
               };
          case 'uberflip':
               return {
                    iconColor: '/assets/icons/uberflip/icon-color.svg',
                    iconTinted: '/assets/icons/uberflip/icon-tinted.svg',
                    logo: '/assets/icons/uberflip/logo.svg',
               };
          case 'livechat':
               return {
                    iconColor: '/assets/icons/live-chat-inc/icon-color.svg',
                    iconTinted: '/assets/icons/live-chat-inc/icon-tinted.svg',
                    logo: '/assets/icons/live-chat-inc/logo.svg',
               };
          case 'vidyard':
               return {
                    iconColor: '/assets/icons/vidyard/icon-color.svg',
                    iconTinted: '/assets/icons/vidyard/icon-tinted.svg',
                    logo: '/assets/icons/vidyard/logo.svg',
               };
          case 'drift':
               return {
                    iconColor: '/assets/icons/drift/icon-color.svg',
                    iconTinted: '/assets/icons/drift/icon-tinted.svg',
                    logo: '/assets/icons/drift/logo.svg',
               };
          case 'vimeo':
               return {
                    iconColor: '/assets/icons/vimeo/icon-color.svg',
                    iconTinted: '/assets/icons/vimeo/icon-tinted.svg',
                    logo: '/assets/icons/vimeo/logo.svg',
               };
          case 'mailchimp':
               return {
                    iconColor: '/assets/icons/mailchimp/icon-color.svg',
                    iconTinted: '/assets/icons/mailchimp/icon-tinted.svg',
                    logo: '/assets/icons/mailchimp/logo.svg',
               };
          case 'intercom':
               return {
                    iconColor: '/assets/icons/intercom/icon-color.svg',
                    iconTinted: '/assets/icons/intercom/icon-tinted.svg',
                    logo: '/assets/icons/intercom/logo.svg',
               };
          case 'drupalWebForm':
               return {
                    iconColor: '/assets/icons/drupal/icon-color.svg',
                    iconTinted: '/assets/icons/drupal/icon-tinted.svg',
                    logo: '/assets/icons/drupal/logo.svg',
               };
          case 'woocommerce':
               return {
                    iconColor: '/assets/icons/woocommerce/icon-color.svg',
                    iconTinted: '/assets/icons/woocommerce/icon-tinted.svg',
                    logo: '/assets/icons/woocommerce/logo.svg',
               };
          case 'unbounce':
               return {
                    iconColor: '/assets/icons/unbouncejs/icon-color.svg',
                    iconTinted: '/assets/icons/unbouncejs/icon-tinted.svg',
                    logo: '/assets/icons/unbouncejs/logo.svg',
               };
          case 'reactJS':
               return {
                    iconColor: '/assets/icons/reactjs/icon-color.svg',
                    iconTinted: '/assets/icons/reactjs/icon-tinted.svg',
                    logo: '/assets/icons/reactjs/logo.svg',
               };
          case 'angular':
               return {
                    iconColor: '/assets/icons/angularjs/icon-color.svg',
                    iconTinted: '/assets/icons/angularjs/icon-tinted.svg',
                    logo: '/assets/icons/angularjs/logo.svg',
               };
          case 'wistia':
               return {
                    iconColor: '/assets/icons/wistia/icon-color.svg',
                    iconTinted: '/assets/icons/wistia/icon-tinted.svg',
                    logo: '/assets/icons/wistia/logo.svg',
               };
          default:
               return {
                    iconColor: '/assets/icons/listenlayer-favicon.svg',
                    iconTinted: '/assets/icons/listenlayer-favicon.svg',
                    logo: '/assets/icons/listenlayer-favicon.svg',
               };
     }
};

export const getListenerColorIcon = (listener) => {
     if (listener && listener.alias === 'microsoft-ads') {
          return '/assets/icons/ms-advertising-ads/icon-color.svg'
     }
     return listener.icon || getListenerIconSrc(listener.code).iconColor;
};

export const getListenerLogo = (listener) => {
     return listener.logo || getListenerIconSrc(listener.code).logo;
};

export const urlParams = (name) => {
     var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
     if (results == null) {
          return null;
     } else {
          return decodeURI(results[1]) || 0;
     }
};

export const convertStatusOfTickets = (tickets) => {
     return tickets.map((el) => ({ ...el, status: TICKET_STATUSES[parseInt(el.status)] }));
};

export const checkIfDefaultRule = (listenerCode, ruleName) => {
     return (
          (listenerCode === 'outboundClicks' && ruleName === 'Outbound Click URL Rule Set') ||
          (listenerCode === 'pdfClicks' && ruleName === 'PDF Click Rule Set') ||
          (listenerCode === 'internalClicks' && ruleName === 'Internal Click URL Rule Set')
     );
};

export const getDLVariableType = (variableName) => {
     const firstNameSplitByDot = variableName.split('.')[0];

     if (firstNameSplitByDot.includes('AutomaticValues') || firstNameSplitByDot.includes('SelectorValues')) {
          return GTM_VARIABLE_IN_LL_TYPE.SPECIFIC_TO_A_LISTENER;
     }

     if (firstNameSplitByDot === 'customValues') {
          return GTM_VARIABLE_IN_LL_TYPE.CUSTOM;
     }

     if (['userDetails', 'consentType', 'tracking', 'personValues'].includes(firstNameSplitByDot)) {
          return GTM_VARIABLE_IN_LL_TYPE.USER_PERSON;
     }

     if (['triggers', 'trigger', 'conversion'].includes(firstNameSplitByDot)) {
          return GTM_VARIABLE_IN_LL_TYPE.TRIGGER;
     }

     return GTM_VARIABLE_IN_LL_TYPE.EVENT;
};

export const getPreviewNoticeMessage = (listener) => {
     let message = '';

     if (listener && listener.type && listener.code && listener.name) {
          const { type, code, name } = listener;

          switch (type) {
               case LISTENER_TYPE.CHAT:
                    message = (
                         <p>
                              To test {name}, navigate your website and interact with your chat to generate data layer events. You can end the test at
                              any time. If you want to complete a full test, generate all of these events:
                         </p>
                    );
                    break;
               case LISTENER_TYPE.ECOMMERCE:
                    message = (
                         <p>
                              To test {name}, interact with your store to generate data layer events. You can end the test at any time. If you want to
                              complete a full test, generate all of these events:
                         </p>
                    );
                    break;
               case LISTENER_TYPE.FORMS:
                    message = (
                         <p>
                              To test {name}, navigate your website and interact with your forms to generate data layer events. You can end the test
                              at any time. If you want to complete a full test, generate all of these events:
                         </p>
                    );
                    break;
               case LISTENER_TYPE.CONTENT:
                    message = (
                         <p>
                              To test {name}, navigate single page application causing the URL to change. You can end the test at any time. If you
                              want to complete a full test, generate all of these events:
                         </p>
                    );
                    break;
               case LISTENER_TYPE.VIDEO:
                    message = (
                         <p>
                              To test {name}, navigate your website and interact with your embedded videos. You can end the test at any time. If you
                              want to complete a full test, generate all of these events:
                         </p>
                    );
                    break;
               case LISTENER_TYPE.USERS_AND_SOURCES:
                    message = (
                         <p>
                              To test {name}, visit your website using a new session. You can click reset to generate a new session. You can end the
                              test at any time. If you want to complete a full test, generate all of these events:
                         </p>
                    );
                    break;
               default:
                    message = (
                         <p>
                              To test {name}, navigate your website and fill out test submissions on multiple forms. Your goal is to interact with
                              these forms in order to generate data layer events. This notification will update once we've received enough test
                              events:
                         </p>
                    );
                    break;
          }

          if (code === LISTENER_CODE.HUBSPOT_CTA) {
               message = (
                    <p>
                         To test {name}, navigate your website and interact with your CTA Buttons to generate data layer events. You can end the test
                         at any time. If you want to complete a full test, generate all of these events:
                    </p>
               );
          }
          if (code === LISTENER_CODE.OUTBOUND_CLICKS) {
               message = (
                    <p>
                         To test {name}, navigate your website and click on outbound links to generate data layer events. You can end the test at any
                         time. If you want to complete a full test, generate all of these events:
                    </p>
               );
          }
          if (code === LISTENER_CODE.INTERNAL_CLICKS) {
               message = (
                    <p>
                         To test {name}, navigate your website and click on internal links to generate data layer events. You can end the test at any
                         time. If you want to complete a full test, generate all of these events:
                    </p>
               );
          }
          if (code === LISTENER_CODE.PDF_CLICKS) {
               message = (
                    <p>
                         To test {name}, navigate your website and click on document links to generate data layer events. You can end the test at any
                         time. If you want to complete a full test, generate all of these events:
                    </p>
               );
          }
          if (code === LISTENER_CODE.USER_SOURCE) {
               message = (
                    <p>
                         To test {name}, visit your website using a new session. You can click reset to generate a new session. To test assitional
                         User source events, change your source by adding a parameter to your URL (for example, add gclid=1234). You can end the test
                         at any time. If you want to complete a full test, generate all of these events 2 times each.
                    </p>
               );
          }
          if (code === LISTENER_CODE.NATIVE_BROWSER_STORAGE) {
               message = (
                    <>
                         <p>
                              To test {name}, take actions on your website to generate your targeted browser storage, and ListenLayer will push the
                              following events into the data layer when found: <code>nativeLocalStorage</code>, <code>nativeSessionStorage</code>,{' '}
                              <code>nativeCookieStorage</code>
                         </p>
                         <p>
                              You can end the test at any time. If you want to complete a full test, generate events for the following Native Browser
                              Storage Targets:
                         </p>
                    </>
               );
          }
          if (code === LISTENER_CODE.NATIVE_DATA_LAYERS) {
               message = (
                    <>
                         <p>
                              To test {name}, take actions on your website to generate your targeted native events, and ListenLayer will push the
                              following event into the data layer when found: <code>nativeDataLayer</code>
                         </p>
                         <p>
                              {' '}
                              You can end the test at any time. If you want to complete a full test, generate events for the following Native Data
                              Layer Targets:
                         </p>
                    </>
               );
          }
          if (code === LISTENER_CODE.PAGE_VIEW) {
               message = (
                    <p>
                         To test {name}, visit your website regardless of its loading or reloading to generate data layer event. You can end the test
                         at any time. If you want to complete a full test, generate all of these events:
                    </p>
               );
          }
          if (code === LISTENER_CODE.ACUITY_SCHEDULING) {
               message = (
                    <p>
                         To test {name}, navigate your website and interact with the scheduling app. You can end the test at any time. If you want to
                         complete a full test, generate all of these events:
                    </p>
               );
          }
          if (code === LISTENER_CODE.URL_PARAMETERS) {
               message = (
                    <p>
                         To test {name}, generate data layer events by adding querystrings to your web address below. You can end the test at any
                         time. If you want to complete a full test, generate all of these events 2 times each.
                    </p>
               );
          }
     }

     return message;
};

export const handleShowPopupCreateVariable = ({
     values,
     setFieldValue,
     customVariableIndex,
     setCustomVariableNext,
     dispatch,
     type,
     action,
     customData,
     handleHasChange,
}) => {
     const next = (variable) => {
          let newValues = [...values.customVariables];
          if (type === 'Simple Rule') {
               newValues[customVariableIndex] = {
                    ...newValues[customVariableIndex],
                    id: variable.id,
               };
          }
          if (type === 'LookUp Rule') {
               newValues = [
                    ...values.customVariables,
                    {
                         id: variable.id,
                         value: variable.name,
                         label: variable.name,
                    },
               ];
          }
          if (customData === 'BrowserStorage') {
               newValues[customVariableIndex] = {
                    ...newValues[customVariableIndex],
                    id: variable.id,
                    action: action,
                    key: '',
               };
          }
          setFieldValue('customVariables', newValues);
     };

     setCustomVariableNext(() => (variable) => next(variable));
     if (customData === 'BrowserStorage') {
          dispatch(setShowCreateBrowserVariable(true));
     } else {
          dispatch(setShowCreateVariable(true));
     }
};

export const getDefaultTriggerTooltip = (trigger) => {
     let tooltip = '';

     switch (trigger) {
          case 'Add Payment Info':
               tooltip = <p>A trigger based on the add_payment_info event from ecommerce Listeners.</p>;
               break;
          case 'Add Shipping Info':
               tooltip = <p>A trigger based on the add_shipping_info event from ecommerce Listeners.</p>;
               break;
          case 'View Product':
               tooltip = <p>A trigger based on the view_item event from ecommerce Listeners.</p>;
               break;
          case 'Select Product':
               tooltip = <p>A trigger based on the select_item event from ecommerce Listeners.</p>;
               break;
          case 'View Product List':
               tooltip = <p>A trigger based on the view_item_list event from ecommerce Listeners.</p>;
               break;
          case 'Remove from Cart':
               tooltip = <p>A trigger based on the remove_from_cart event from ecommerce Listeners.</p>;
               break;
          case 'Purchase':
               tooltip = <p>A trigger based on the purchase event from ecommerce Listeners.</p>;
               break;
          case 'Add to Cart':
               tooltip = <p>A trigger based on the add_to_cart event from ecommerce Listeners.</p>;
               break;
          case 'Begin Checkout':
               tooltip = <p>A trigger based on the begin_checkout event from ecommerce Listeners.</p>;
               break;
          case 'View Cart':
               tooltip = <p>A trigger based on the view_cart from ecommerce Listeners.</p>;
               break;
          default:
               break;
     }

     return tooltip;
};

export const getTagTypeTooltip = (type) => {
     switch (type) {
          case 'sever-side-trigger':
          case 'simple':
          case 'Simple Rule':
          case 'Local Storage':
          case TARGET_ACCOUNT_GROUP_TYPE.LIST_OF_ACCOUNTS:
               return 'info';
          case 'lookup':
          case 'Lookup Rule':
          case 'Lookup Rule Custom':
          case TARGET_ACCOUNT_GROUP_TYPE.DYNAMIC_LIST:
          case 'Session Storage':
          case BQ_JOB_STATUS.DONE:
               return 'success';
          case 'Cookie Storage':
          case 'primary':
               return 'primary';
          case 'secondary':
          case BQ_JOB_STATUS.IN_PROGRESS:
               return 'secondary';
          case BQ_JOB_STATUS.FAILED:
          case BQ_JOB_STATUS.CANCELED:
               return 'danger';
          default:
               return;
     }
};

export const getTagNameTooltip = (type) => {
     switch (type) {
          case 'simple':
          case 'Simple Rule':
               return 'Simple';
          case 'lookup':
          case 'Lookup Rule':
          case 'Lookup Rule Custom':
               return 'Lookup';
          default:
               return type;
     }
};

export const getTagNameTrigger = (type) => {
     switch (type) {
          case 'triggers':
          case 'simple':
          case 'Simple Rule':
          case 'lookup':
          case 'Lookup Rule':
          case 'Lookup Rule Custom':
               return 'website trigger';
          case 'sever-side-trigger':
               return 'sever-side-trigger';
          default:
               return 'sever-side-trigger';
     }
};

export const getDestinationLogo = (name) => {
     let nameLogo = {
          logo: '',
          logoTinted: '',
     };

     switch (name) {
          case 'LinkedIn Ads':
               nameLogo.logo = 'favicon';
               // nameLogo.logoTinted = 'cib-linkedin';
               nameLogo.logoTinted = 'icon-LinkedInTinted';
               break;
          case 'Microsoft Ads':
               nameLogo.logoTinted = 'icon-MicrosoftAdsTinted';
               break;
          case 'Facebook Ads':
               nameLogo.logoTinted = 'cib-facebook';
               break;
          case 'Google Ads':
               nameLogo.logoTinted = 'icon-GoogleAdsTinted';
               break;
          case 'Google Tag Manager':
               nameLogo.logoTinted = 'icon-GTMTinted';
               break;
          case 'Google Analytics 4':
               nameLogo.logoTinted = 'icon-GoogleAnalyticsTinted';
               break;
          default:
               nameLogo.logo = 'favicon';
               nameLogo.logoTinted = 'cil-layers';
               break;
     }

     return nameLogo;
};

export const getDestinationColorIcon = (destination) => {
     if (destination && destination.alias === 'microsoft-ads') {
          return '/assets/icons/ms-advertising-ads/icon-color.svg'
     }
     return destination.icon || '/assets/icons/listenlayer-favicon.svg';
};

export const getECommerceEventTooltip = (destinationCode, name) => {
     switch (destinationCode) {
          case DESTINATION_CODE.GOOGLE_ADS:
               switch (name) {
                    case 'View Product':
                         return 'Send a view_product event, along with product details, to Google Ads for remarketing purposes when a user views a product.';
                    case 'View Product List':
                         return 'Send a view_product_list event, along with product details, to Google Ads for remarketing purposes when a user views a list of products.';
                    case 'Purchase':
                         return 'Send a purchase event, along with transaction details, to Google Ads for remarketing purposes when a user complete a purchase on your website.  If you want to consider this a conversion inside Google Ads, selection the box and provide a Conversion Label.';
                    case 'Add to Cart':
                         return 'Send an add_to_cart event, along with product details, to Google Ads for remarketing purposes when a user adds a product to their cart.';
                    default:
                         break;
               }
               break;
          case DESTINATION_CODE.GA4:
               switch (name) {
                    case 'Add Payment Info':
                         return 'This sends an event to GA4 when a user adds payment information during checkout.';
                    case 'Add Shipping Info':
                         return 'This sends an event to GA4 when a user adds shipping information during checkout.';
                    case 'Purchase':
                         return 'This sends an event to GA4 when a transaction (purchase) is completed on your website. It includes unique parameters required by this event.';
                    case 'Add to Cart':
                         return 'This sends an event to GA4 when a user adds a product (item) to their shopping cart.';
                    case 'Begin Checkout':
                         return 'This sends an event to GA4 when a users begins to checkout, which usually occurs after viewing a cart.';
                    case 'Remove from Cart':
                         return 'This sends an event to GA4 when a user removes an item (product) from their cart.';
                    case 'Select Item':
                         return 'This sends an event to GA4 when a user select an item (product) on your website.';
                    case 'View Cart':
                         return 'This sends an event to GA4 when a user Views the cart.';
                    case 'View Item List':
                         return 'This sends an event to GA4 when a user views a list or group of items (products) on your website.';
                    case 'View Product':
                         return 'This sends an event to GA4 when a user views a product (items) on your website.';
                    default:
                         break;
               }
               break;
          case DESTINATION_CODE.FACEBOOK_ADS:
               switch (name) {
                    case 'Add Payment Info':
                         return 'Send an AddPaymentInfo standard event to Facebook based on the ListenLayer add_payment_info event from your eCommerce Listeners.';
                    case 'Add To Cart':
                         return 'Send an AddToCart standard event to Facebook based on the ListenLayer add_to_cart event from your eCommerce Listeners.';
                    case 'Customize Product':
                         return 'Send a CustomizeProduct standard event to Facebook based on a Trigger you define inside ListenLayer. The eCommerce Listeners don’t contain an automatic event for this action, so you’ll need to create and manage a trigger based on rules inside ListenLayer.';
                    case 'Begin Checkout':
                         return 'Send an InitiateCheckout standard event to Facebook based on the ListenLayer begin_checkout event from your eCommerce Listeners.';
                    case 'Purchase':
                         return 'Send a Purchase standard event to Facebook based on the ListenLayer purchase event from your eCommerce Listeners.';
                    case 'Subscribe':
                         return 'Send a Subscribe standard event to Facebook based on a Trigger you define inside ListenLayer. The eCommerce Listeners don’t contain an automatic event for this action, so you’ll need to create and manage a trigger based on rules inside ListenLayer.';
                    case 'View Cart':
                         return 'Send a custom event to Facebook called ViewCart based on the ListenLayer view_cart event from your eCommerce Listeners.';
                    case 'View Product':
                         return 'Send a custom event to Facebook called ViewProduct based on the ListenLayer View Product trigger.';
                    case 'Add Shipping Info':
                         return 'Send a custom event to Facebook called AddShippingInfo based on the ListenLayer add_shipping_info event from your eCommerce Listeners.';
                    case 'Remove From Cart':
                         return 'Send a custom event to Facebook called RemoveFromCart based on the ListenLayer remove_from_cart event from your eCommerce Listeners.';
                    default:
                         break;
               }
               break;
          case DESTINATION_CODE.MICROSOFT_ADS:
               switch (name) {
                    case 'Add to Cart':
                         return 'Send an Add to Cart event to Microsoft based on the ListenLayer add_to_cart event from your eCommerce Listeners.';
                    case 'Begin Checkout':
                         return 'Send an Begin Checkout event to Microsoft based on the ListenLayer begin_checkout event from your eCommerce Listeners.';
                    case 'Purchase':
                         return 'Send a Purchase event to Microsoft based on the ListenLayer purchase event from your eCommerce Listeners.';
                    case 'Shopping Cart Page':
                         return 'Send a View Cart event to Microsoft based on the ListenLayer view_cart event from your eCommerce Listeners.';
                    case 'View A Product Page':
                         return 'Send a View Product to Microsoft called view_product based on the ListenLayer View Product trigger.';
                    case 'View Catalog Page':
                         return 'This sends an event when a user views a list or group of items (products) on your website.';
                    default:
                         break;
               }
               break;
          default:
               break;
     }
};

export const checkHidePurchaseConversion = ({ activeFeatures, accountSync, nativePurchase }) => {
     const ecommerceEventCodes = [];
     const purchaseEcommerce = accountSync ? PURCHASE_ECOMMERCE_GA4_FEATURES : PURCHASE_ECOMMERCE_GA4_FEATURES_V2;

     activeFeatures.forEach((feature) => {
          purchaseEcommerce.forEach((featureCode) => {
               if (accountSync ? feature.oldCode === featureCode : feature.code === featureCode) {
                    ecommerceEventCodes.push(featureCode);
               }
          });
     });

     if (ecommerceEventCodes.length > 0 || nativePurchase) {
          return false;
     }

     return true;
};
